import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type ApiKey = {
  __typename?: 'APIKey';
  createdAt: Scalars['String']['output'];
  expiresAt?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
};

export type Analytics = {
  __typename?: 'Analytics';
  analyticsId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  dataset: Scalars['String']['output'];
  datasetId: Scalars['String']['output'];
  /** @deprecated Support for member ends at the 1.1.0 release, please upgrade your client or use createdBy instead. */
  member?: Maybe<Scalars['String']['output']>;
  /** @deprecated Support for range has ended, please upgrade your client. */
  range?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  result?: Maybe<Scalars['String']['output']>;
  /** @deprecated Support for returnImages has ended, please upgrade your client. */
  returnImages?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  workspaceId: Scalars['String']['output'];
};

export type AnalyticsLog = {
  __typename?: 'AnalyticsLog';
  analyticsId: Scalars['String']['output'];
  datasetId: Scalars['String']['output'];
  log?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  workspaceId: Scalars['String']['output'];
};

export type Annotation = {
  __typename?: 'Annotation';
  annotationId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  datasetId: Scalars['String']['output'];
  format: Scalars['String']['output'];
  map: Scalars['String']['output'];
  mapId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  workspaceId: Scalars['String']['output'];
};

export type AnnotationLog = {
  __typename?: 'AnnotationLog';
  annotationId: Scalars['String']['output'];
  datasetId: Scalars['String']['output'];
  log?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  workspaceId: Scalars['String']['output'];
};

export type AnnotationMap = {
  __typename?: 'AnnotationMap';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  mapId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organization: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type Banner = {
  __typename?: 'Banner';
  bannerColor?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  textColor?: Maybe<Scalars['String']['output']>;
};

export type Channel = {
  __typename?: 'Channel';
  channelId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  documentation?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  organization: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type ChannelDeployment = {
  __typename?: 'ChannelDeployment';
  channelId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  deploymentId: Scalars['String']['output'];
  status: ChannelDeploymentStatus;
  updatedAt: Scalars['String']['output'];
};

export type ChannelDeploymentStatus = {
  __typename?: 'ChannelDeploymentStatus';
  message: Scalars['String']['output'];
  state: Scalars['String']['output'];
  step: Scalars['String']['output'];
};

export type ChannelDocumentation = {
  __typename?: 'ChannelDocumentation';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  markdown: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ChannelDocumentationUpload = {
  __typename?: 'ChannelDocumentationUpload';
  fields?: Maybe<ChannelUploadFields>;
  key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ChannelOrganization = {
  __typename?: 'ChannelOrganization';
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
};

export type ChannelUploadFields = {
  __typename?: 'ChannelUploadFields';
  algorithm?: Maybe<Scalars['String']['output']>;
  bucket?: Maybe<Scalars['String']['output']>;
  credential?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  policy?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type Charge = {
  __typename?: 'Charge';
  billing_details?: Maybe<CustomerPaymentInformationBillingDetails>;
  id?: Maybe<Scalars['String']['output']>;
  payment_method_details?: Maybe<ChargePaymentMethodDetails>;
  status?: Maybe<Scalars['String']['output']>;
};

export type ChargePaymentMethodDetails = {
  __typename?: 'ChargePaymentMethodDetails';
  card?: Maybe<ChargePaymentMethodDetailsCard>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ChargePaymentMethodDetailsCard = {
  __typename?: 'ChargePaymentMethodDetailsCard';
  brand?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  exp_month?: Maybe<Scalars['String']['output']>;
  exp_year?: Maybe<Scalars['String']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
};

export type ContentCode = {
  __typename?: 'ContentCode';
  code: Scalars['String']['output'];
  contactUs: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  expiresAt?: Maybe<Scalars['String']['output']>;
  featured: Scalars['Boolean']['output'];
  link?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type CustomerInformation = {
  __typename?: 'CustomerInformation';
  address?: Maybe<CustomerInformationAddress>;
  email?: Maybe<Scalars['String']['output']>;
  invoice_settings?: Maybe<CustomerInformationInvoiceSettings>;
  name?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type CustomerInformationAddress = {
  __typename?: 'CustomerInformationAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type CustomerInformationInvoiceSettings = {
  __typename?: 'CustomerInformationInvoiceSettings';
  default_payment_method?: Maybe<Scalars['String']['output']>;
};

export type CustomerPaymentInformation = {
  __typename?: 'CustomerPaymentInformation';
  billing_details?: Maybe<CustomerPaymentInformationBillingDetails>;
  card?: Maybe<CustomerPaymentInformationCardDetails>;
  created?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<CustomerPaymentInformationMetadata>;
  object?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type CustomerPaymentInformationBillingDetails = {
  __typename?: 'CustomerPaymentInformationBillingDetails';
  address?: Maybe<CustomerPaymentInformationBillingDetailsAddress>;
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type CustomerPaymentInformationBillingDetailsAddress = {
  __typename?: 'CustomerPaymentInformationBillingDetailsAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type CustomerPaymentInformationCardDetails = {
  __typename?: 'CustomerPaymentInformationCardDetails';
  brand?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  exp_month?: Maybe<Scalars['Int']['output']>;
  exp_year?: Maybe<Scalars['Int']['output']>;
  fingerprint?: Maybe<Scalars['String']['output']>;
  funding?: Maybe<Scalars['String']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
};

export type CustomerPaymentInformationMetadata = {
  __typename?: 'CustomerPaymentInformationMetadata';
  organization?: Maybe<Scalars['String']['output']>;
};

export type Dataset = {
  __typename?: 'Dataset';
  channel?: Maybe<Scalars['String']['output']>;
  channelId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Support ends at the 1.1.0 release, please upgrade your client. */
  count?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  datasetId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Support ends at the 1.1.0 release, please upgrade your client or use datasetId instead. */
  datasetid?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Scalars['Int']['output']>;
  graph?: Maybe<Scalars['String']['output']>;
  graphId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  priority?: Maybe<Scalars['Int']['output']>;
  runs?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Support ends at the 1.1.0 release, please upgrade your client or use runs instead. */
  scenarios?: Maybe<Scalars['String']['output']>;
  seed?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Support ends at the 1.1.0 release, please upgrade your client. */
  source?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  /** @deprecated Support ends at the 1.1.0 release, please upgrade your client or use createdBy instead. */
  user?: Maybe<Scalars['String']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

export type DatasetJob = {
  __typename?: 'DatasetJob';
  averageRuntime?: Maybe<Scalars['String']['output']>;
  channel?: Maybe<Scalars['String']['output']>;
  channelId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  datasetId?: Maybe<Scalars['String']['output']>;
  estimatedEndAt?: Maybe<Scalars['String']['output']>;
  graph?: Maybe<Scalars['String']['output']>;
  graphId?: Maybe<Scalars['String']['output']>;
  instancesQueued?: Maybe<Scalars['Int']['output']>;
  instancesRunning?: Maybe<Scalars['Int']['output']>;
  instancesStarting?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  runs?: Maybe<Scalars['Int']['output']>;
  runsCancelled?: Maybe<Scalars['Int']['output']>;
  runsFailed?: Maybe<Scalars['Int']['output']>;
  runsQueued?: Maybe<Scalars['Int']['output']>;
  runsRunning?: Maybe<Scalars['Int']['output']>;
  runsStarting?: Maybe<Scalars['Int']['output']>;
  runsSuccess?: Maybe<Scalars['Int']['output']>;
  runsTimeout?: Maybe<Scalars['Int']['output']>;
  seed?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

export type DatasetLog = {
  __typename?: 'DatasetLog';
  channelId?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  datasetId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Support ends at the 1.1.0 release, please upgrade your client or use createdBy instead. */
  email?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  log?: Maybe<Scalars['String']['output']>;
  run?: Maybe<Scalars['Int']['output']>;
  runId?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

export type DatasetRun = {
  __typename?: 'DatasetRun';
  channelId?: Maybe<Scalars['String']['output']>;
  datasetId?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  run?: Maybe<Scalars['Int']['output']>;
  runId?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

export type DatasetUpload = {
  __typename?: 'DatasetUpload';
  fields?: Maybe<DatasetUploadFields>;
  key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type DatasetUploadFields = {
  __typename?: 'DatasetUploadFields';
  algorithm?: Maybe<Scalars['String']['output']>;
  bucket?: Maybe<Scalars['String']['output']>;
  credential?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  policy?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type EcrDeployment = {
  __typename?: 'ECRDeployment';
  deploymentId: Scalars['String']['output'];
  ecrEndpoint: Scalars['String']['output'];
  ecrPassword: Scalars['String']['output'];
};

export type Feedback = {
  __typename?: 'Feedback';
  feedbackId: Scalars['String']['output'];
  uploads?: Maybe<Array<Maybe<FeedbackUpload>>>;
};

export type FeedbackUpload = {
  __typename?: 'FeedbackUpload';
  fields?: Maybe<FeedbackUploadFields>;
  key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FeedbackUploadFields = {
  __typename?: 'FeedbackUploadFields';
  algorithm?: Maybe<Scalars['String']['output']>;
  bucket?: Maybe<Scalars['String']['output']>;
  credential?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  policy?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type GanData = {
  __typename?: 'GANData';
  fields?: Maybe<UploadFields>;
  modelId: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type GanDataset = {
  __typename?: 'GANDataset';
  /** @deprecated Support for channelId is ending at 1.1.0, please remove from your client. */
  channelId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  datasetId: Scalars['String']['output'];
  /** @deprecated Support for datasetid is ending at 1.1.0, please use datasetId. */
  datasetid?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Support for files is ending at 1.1.0, please remove from your client. */
  files?: Maybe<Scalars['String']['output']>;
  /** @deprecated Support for ganmodelId is ending at 1.1.0, please use modelId. */
  ganmodelId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Support for ganparent is ending at 1.1.0, please use parentId. */
  ganparent?: Maybe<Scalars['String']['output']>;
  model: Scalars['String']['output'];
  modelId: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parentId: Scalars['String']['output'];
  /** @deprecated Support for scenarios is ending at 1.1.0, please remove from your client. */
  scenarios?: Maybe<Scalars['String']['output']>;
  /** @deprecated Support for size is ending at 1.1.0, please remove from your client. */
  size?: Maybe<Scalars['String']['output']>;
  /** @deprecated Support for source is ending at 1.1.0, please remove from your client. */
  source?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy: Scalars['String']['output'];
  /** @deprecated Support for user is ending at 1.1.0, please use createdBy. */
  user?: Maybe<Scalars['String']['output']>;
  workspaceId: Scalars['String']['output'];
};

export type GanDatasetLog = {
  __typename?: 'GANDatasetLog';
  datasetId: Scalars['String']['output'];
  log?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  workspaceId: Scalars['String']['output'];
};

export type GanModel = {
  __typename?: 'GANModel';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  flags: Scalars['String']['output'];
  modelId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organization: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type GanOrganization = {
  __typename?: 'GANOrganization';
  organization: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
};

export type Graph = {
  __typename?: 'Graph';
  channel: Scalars['String']['output'];
  channelId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  graphId: Scalars['String']['output'];
  /** @deprecated Support ends at the 1.1.0 release, please upgrade your client or use graphId instead. */
  graphid?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  previewId?: Maybe<Scalars['String']['output']>;
  readOnly?: Maybe<Scalars['Boolean']['output']>;
  showPreview?: Maybe<Scalars['Boolean']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  /** @deprecated Support ends at the 1.1.0 release, please upgrade your client or use createdBy instead. */
  user?: Maybe<Scalars['String']['output']>;
  workspaceId: Scalars['String']['output'];
};

export type GraphUpdateReponse = {
  __typename?: 'GraphUpdateReponse';
  description?: Maybe<Scalars['String']['output']>;
  graph?: Maybe<Scalars['String']['output']>;
  graphId: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  showPreview?: Maybe<Scalars['Boolean']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  workspaceId: Scalars['String']['output'];
};

export type GraphValidationError = {
  __typename?: 'GraphValidationError';
  errorMessage?: Maybe<Scalars['String']['output']>;
  input?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Invitation = {
  __typename?: 'Invitation';
  createdAt?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  /** @deprecated Deprecated with 1.0.0 release, please upgrade your client. */
  invitationId?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  role?: Maybe<RoleType>;
  /** @deprecated Deprecated with 1.1.0 release, please upgrade your client or use createdAt instead. */
  timestamp?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  /** @deprecated Deprecated with 1.0.0 release, please upgrade your client. */
  workspaceId?: Maybe<Scalars['String']['output']>;
};

export type LlmChannelNodeTypes = {
  __typename?: 'LLMChannelNodeTypes';
  baseChannel: Scalars['String']['output'];
  nodeTypes?: Maybe<Array<Scalars['String']['output']>>;
};

export type LlmResponse = {
  __typename?: 'LLMResponse';
  baseChannel: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  nodeName: Scalars['String']['output'];
  nodeResponse?: Maybe<Scalars['String']['output']>;
  nodeType: Scalars['String']['output'];
  prompt: Scalars['String']['output'];
  promptId: Scalars['String']['output'];
  schemaResponse?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type LinkedApp = {
  __typename?: 'LinkedApp';
  appId: Scalars['String']['output'];
  clientId?: Maybe<Scalars['String']['output']>;
  clientSecret?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  linked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type ManagedChannel = {
  __typename?: 'ManagedChannel';
  channelId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  instanceType: Scalars['String']['output'];
  interfaceVersion?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  organizations?: Maybe<Array<Maybe<ChannelOrganization>>>;
  preview?: Maybe<Scalars['Boolean']['output']>;
  timeavg?: Maybe<Scalars['Int']['output']>;
  timeout?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  volumes: Array<Maybe<Scalars['String']['output']>>;
};

export type ManagedGan = {
  __typename?: 'ManagedGAN';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  flags: Scalars['String']['output'];
  modelId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organization: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  organizations?: Maybe<Array<Maybe<GanOrganization>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ManagedMap = {
  __typename?: 'ManagedMap';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  mapId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organization: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  organizations?: Maybe<Array<Maybe<MapOrganization>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ManagedVolume = {
  __typename?: 'ManagedVolume';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  organizations?: Maybe<Array<Maybe<VolumeOrganization>>>;
  permission?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  volumeId: Scalars['String']['output'];
};

export type MapData = {
  __typename?: 'MapData';
  fields?: Maybe<UploadFields>;
  key?: Maybe<Scalars['String']['output']>;
  mapId: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type MapOrganization = {
  __typename?: 'MapOrganization';
  organization: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
};

export type Member = {
  __typename?: 'Member';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organizationId?: Maybe<Scalars['String']['output']>;
  role: RoleType;
  userId: Scalars['String']['output'];
  /** @deprecated Deprecated with 1.0.0 release, please upgrade your client. */
  workspaceId?: Maybe<Scalars['String']['output']>;
};

export type MultipartInput = {
  eTag: Scalars['String']['input'];
  partNumber: Scalars['Int']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** @deprecated Support for addChannelOrganization has ended, please upgrade your client. */
  addChannelOrganization?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Support for addGANOrganization has ended, please upgrade your client. */
  addGANOrganization?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Support for addMapOrganization has ended, please upgrade your client. */
  addMapOrganization?: Maybe<Scalars['Boolean']['output']>;
  addMember?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Support for addVolumeOrganization has ended, please upgrade your client. */
  addVolumeOrganization?: Maybe<Scalars['Boolean']['output']>;
  attachPaymentMethodToCustomer: Scalars['String']['output'];
  clearDatasetJobs?: Maybe<Scalars['Boolean']['output']>;
  createAPIKey: Scalars['String']['output'];
  createAnalytics: Scalars['String']['output'];
  createAnnotation: Scalars['String']['output'];
  createDataset?: Maybe<Scalars['String']['output']>;
  createFeedback: Feedback;
  createGANDataset: Scalars['String']['output'];
  createGraph: Scalars['String']['output'];
  createLLMPrompt: Scalars['String']['output'];
  createLinkedApp?: Maybe<Scalars['Boolean']['output']>;
  createManagedChannel: Scalars['String']['output'];
  /** @deprecated Support for createManagedGAN has ended, please upgrade your client. */
  createManagedGAN: GanData;
  createManagedGANV2: UploadGanResponse;
  createManagedGANV2Finalizer?: Maybe<Scalars['Boolean']['output']>;
  createManagedMap: MapData;
  createManagedVolume: Scalars['String']['output'];
  createPaymentIntent: Scalars['String']['output'];
  createPreview: Scalars['String']['output'];
  createSubscriptionForUser: Scalars['String']['output'];
  createUMAP: Scalars['String']['output'];
  createWorkspace: Scalars['String']['output'];
  deleteAPIKey?: Maybe<Scalars['Boolean']['output']>;
  deleteAnalytics: Scalars['Boolean']['output'];
  deleteAnnotation: Scalars['String']['output'];
  deleteDataset?: Maybe<Scalars['Boolean']['output']>;
  deleteGANDataset?: Maybe<Scalars['Boolean']['output']>;
  deleteGraph?: Maybe<Scalars['Boolean']['output']>;
  deleteLLMPrompt: Scalars['Boolean']['output'];
  deleteLinkedApp?: Maybe<Scalars['Boolean']['output']>;
  deleteManagedChannel?: Maybe<Scalars['Boolean']['output']>;
  deleteManagedGAN?: Maybe<Scalars['Boolean']['output']>;
  deleteManagedMap: Scalars['Boolean']['output'];
  deleteManagedVolume?: Maybe<Scalars['Boolean']['output']>;
  deleteOrganization?: Maybe<Scalars['Boolean']['output']>;
  deletePreview?: Maybe<Scalars['Boolean']['output']>;
  deleteUMAP: Scalars['Boolean']['output'];
  deleteUser?: Maybe<Array<Maybe<OrganizationAdmin>>>;
  deleteVolumeData: Array<Maybe<Scalars['String']['output']>>;
  deleteWorkspace: Scalars['String']['output'];
  deployManagedChannel: EcrDeployment;
  downloadAnnotation: Scalars['String']['output'];
  downloadDataset?: Maybe<Scalars['String']['output']>;
  downloadGraph?: Maybe<Scalars['String']['output']>;
  downloadManagedGAN: Scalars['String']['output'];
  downloadManagedMap: Scalars['String']['output'];
  duplicateGraph?: Maybe<Scalars['String']['output']>;
  editDataset?: Maybe<Scalars['Boolean']['output']>;
  editDefaultGraph?: Maybe<Scalars['Boolean']['output']>;
  editGraph?: Maybe<Scalars['Boolean']['output']>;
  editManagedChannel?: Maybe<Scalars['Boolean']['output']>;
  editManagedGAN?: Maybe<Scalars['Boolean']['output']>;
  editManagedMap: Scalars['Boolean']['output'];
  editManagedVolume?: Maybe<Scalars['Boolean']['output']>;
  editMember?: Maybe<Scalars['Boolean']['output']>;
  editOrganization?: Maybe<Scalars['Boolean']['output']>;
  editUMAP: Scalars['Boolean']['output'];
  editVolumeData?: Maybe<Scalars['Boolean']['output']>;
  editWorkspace: Scalars['String']['output'];
  editWorkspaceThumbnail: Scalars['String']['output'];
  mountVolumes?: Maybe<VolumeCredentials>;
  pingPreview?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Support for putVolumeData has ended, please upgrade your client. */
  putVolumeData: Array<Maybe<VolumeData>>;
  putVolumeDataV2: UploadVolumeDataResponse;
  putVolumeDataV2Finalizer?: Maybe<Scalars['Boolean']['output']>;
  removeChannelOrganization?: Maybe<Scalars['Boolean']['output']>;
  removeGANOrganization?: Maybe<Scalars['Boolean']['output']>;
  removeMapOrganization?: Maybe<Scalars['Boolean']['output']>;
  removeMember?: Maybe<Scalars['Boolean']['output']>;
  removeVolumeOrganization?: Maybe<Scalars['Boolean']['output']>;
  saveDefaultPaymentMethod: Scalars['String']['output'];
  setChannelGraph?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Support for setOrganizationLimit has ended, please upgrade your client. */
  setOrganizationLimit?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Support for setPlatformLimit has ended, please upgrade your client. */
  setPlatformLimit?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Support for setWorkspaceLimit has ended, please upgrade your client. */
  setWorkspaceLimit?: Maybe<Scalars['Boolean']['output']>;
  signIn?: Maybe<UserCredentials>;
  signUp?: Maybe<Scalars['Boolean']['output']>;
  updateOrganizationPlan: Scalars['String']['output'];
  uploadChannelDocumentation?: Maybe<Array<Maybe<ChannelDocumentationUpload>>>;
  /** @deprecated Support for uploadDataset has ended, please upgrade your client. */
  uploadDataset?: Maybe<DatasetUpload>;
  uploadDatasetV2: UploadDatasetResponse;
  uploadDatasetV2Finalizer?: Maybe<Scalars['Boolean']['output']>;
  validateGraph?: Maybe<Array<Maybe<GraphValidationError>>>;
};


export type MutationAddChannelOrganizationArgs = {
  channelId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationAddGanOrganizationArgs = {
  modelId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationAddMapOrganizationArgs = {
  mapId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationAddMemberArgs = {
  email: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddVolumeOrganizationArgs = {
  organizationId: Scalars['String']['input'];
  permission: Scalars['String']['input'];
  volumeId: Scalars['String']['input'];
};


export type MutationAttachPaymentMethodToCustomerArgs = {
  organizationId: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
};


export type MutationClearDatasetJobsArgs = {
  datasetId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateApiKeyArgs = {
  expiresAt?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationCreateAnalyticsArgs = {
  datasetId: Scalars['String']['input'];
  type: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type MutationCreateAnnotationArgs = {
  datasetId: Scalars['String']['input'];
  format: Scalars['String']['input'];
  mapId?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type MutationCreateDatasetArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  graphId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  priority?: InputMaybe<Scalars['Int']['input']>;
  runs?: InputMaybe<Scalars['Int']['input']>;
  seed?: InputMaybe<Scalars['Int']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type MutationCreateFeedbackArgs = {
  description: Scalars['String']['input'];
  files: Array<InputMaybe<Scalars['String']['input']>>;
  summary: Scalars['String']['input'];
};


export type MutationCreateGanDatasetArgs = {
  datasetId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  modelId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type MutationCreateGraphArgs = {
  channelId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  graph: Scalars['String']['input'];
  name: Scalars['String']['input'];
  staged?: InputMaybe<Scalars['Boolean']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type MutationCreateLlmPromptArgs = {
  baseChannel: Scalars['String']['input'];
  nodeName: Scalars['String']['input'];
  nodeType: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
};


export type MutationCreateLinkedAppArgs = {
  appId: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationCreateManagedChannelArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  instance?: InputMaybe<Scalars['String']['input']>;
  interfaceVersion?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  timeout?: InputMaybe<Scalars['Int']['input']>;
  volumes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreateManagedGanArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  flags?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationCreateManagedGanv2Args = {
  description?: InputMaybe<Scalars['String']['input']>;
  flags?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  size: Scalars['Float']['input'];
};


export type MutationCreateManagedGanv2FinalizerArgs = {
  key: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  parts?: InputMaybe<Array<InputMaybe<MultipartInput>>>;
  uploadId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateManagedMapArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationCreateManagedVolumeArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  permission?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreatePaymentIntentArgs = {
  amount: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationCreatePreviewArgs = {
  graphId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type MutationCreateSubscriptionForUserArgs = {
  organizationId: Scalars['String']['input'];
  paymentId: Scalars['String']['input'];
  priceId: Scalars['String']['input'];
};


export type MutationCreateUmapArgs = {
  datasetIds: Array<InputMaybe<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  samples: Array<InputMaybe<Scalars['Int']['input']>>;
  seed?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type MutationCreateWorkspaceArgs = {
  channelIds: Array<InputMaybe<Scalars['String']['input']>>;
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  volumeIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationDeleteApiKeyArgs = {
  name: Scalars['String']['input'];
};


export type MutationDeleteAnalyticsArgs = {
  analyticsId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type MutationDeleteAnnotationArgs = {
  annotationId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type MutationDeleteDatasetArgs = {
  datasetId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type MutationDeleteGanDatasetArgs = {
  datasetId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type MutationDeleteGraphArgs = {
  graphId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type MutationDeleteLlmPromptArgs = {
  promptId: Scalars['String']['input'];
};


export type MutationDeleteLinkedAppArgs = {
  appId: Scalars['String']['input'];
};


export type MutationDeleteManagedChannelArgs = {
  channelId: Scalars['String']['input'];
};


export type MutationDeleteManagedGanArgs = {
  modelId: Scalars['String']['input'];
};


export type MutationDeleteManagedMapArgs = {
  mapId: Scalars['String']['input'];
};


export type MutationDeleteManagedVolumeArgs = {
  volumeId: Scalars['String']['input'];
};


export type MutationDeleteOrganizationArgs = {
  organizationId: Scalars['String']['input'];
};


export type MutationDeletePreviewArgs = {
  previewId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type MutationDeleteUmapArgs = {
  umapId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  isDeleting?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationDeleteVolumeDataArgs = {
  keys?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: Scalars['String']['input'];
};


export type MutationDeleteWorkspaceArgs = {
  workspaceId: Scalars['String']['input'];
};


export type MutationDeployManagedChannelArgs = {
  alias?: InputMaybe<Scalars['String']['input']>;
  channelId: Scalars['String']['input'];
  interface?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDownloadAnnotationArgs = {
  annotationId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type MutationDownloadDatasetArgs = {
  datasetId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type MutationDownloadGraphArgs = {
  graphId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type MutationDownloadManagedGanArgs = {
  modelId: Scalars['String']['input'];
};


export type MutationDownloadManagedMapArgs = {
  mapId: Scalars['String']['input'];
};


export type MutationDuplicateGraphArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  graphId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type MutationEditDatasetArgs = {
  datasetId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pause?: InputMaybe<Scalars['Boolean']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  showJob?: InputMaybe<Scalars['Boolean']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type MutationEditDefaultGraphArgs = {
  channelId: Scalars['String']['input'];
  graphId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type MutationEditGraphArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  graph?: InputMaybe<Scalars['String']['input']>;
  graphId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  showPreview?: InputMaybe<Scalars['Boolean']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type MutationEditManagedChannelArgs = {
  channelId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  instance?: InputMaybe<Scalars['String']['input']>;
  interfaceVersion?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timeout?: InputMaybe<Scalars['Int']['input']>;
  volumes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationEditManagedGanArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  flags?: InputMaybe<Scalars['String']['input']>;
  modelId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditManagedMapArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  mapId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditManagedVolumeArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permission?: InputMaybe<Scalars['String']['input']>;
  volumeId: Scalars['String']['input'];
};


export type MutationEditMemberArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditOrganizationArgs = {
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationEditUmapArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  umapId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type MutationEditVolumeDataArgs = {
  key: Scalars['String']['input'];
  source: Scalars['String']['input'];
  volumeId: Scalars['String']['input'];
};


export type MutationEditWorkspaceArgs = {
  channelIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  ganIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mapIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  volumeIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  workspaceId: Scalars['String']['input'];
};


export type MutationEditWorkspaceThumbnailArgs = {
  thumbnail: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type MutationMountVolumesArgs = {
  volumes: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationPingPreviewArgs = {
  channelId: Scalars['String']['input'];
};


export type MutationPutVolumeDataArgs = {
  keys: Array<InputMaybe<Scalars['String']['input']>>;
  volumeId: Scalars['String']['input'];
};


export type MutationPutVolumeDataV2Args = {
  key: Scalars['String']['input'];
  size: Scalars['Float']['input'];
  volumeId: Scalars['String']['input'];
};


export type MutationPutVolumeDataV2FinalizerArgs = {
  key: Scalars['String']['input'];
  parts?: InputMaybe<Array<InputMaybe<MultipartInput>>>;
  uploadId?: InputMaybe<Scalars['String']['input']>;
  volumeId: Scalars['String']['input'];
};


export type MutationRemoveChannelOrganizationArgs = {
  channelId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationRemoveGanOrganizationArgs = {
  modelId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationRemoveMapOrganizationArgs = {
  mapId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationRemoveMemberArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRemoveVolumeOrganizationArgs = {
  organizationId: Scalars['String']['input'];
  volumeId: Scalars['String']['input'];
};


export type MutationSaveDefaultPaymentMethodArgs = {
  organizationId: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
};


export type MutationSetChannelGraphArgs = {
  channelId: Scalars['String']['input'];
  graphId?: InputMaybe<Scalars['String']['input']>;
  stagedGraphId?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type MutationSetOrganizationLimitArgs = {
  limit: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
  setting: Scalars['String']['input'];
};


export type MutationSetPlatformLimitArgs = {
  limit: Scalars['Int']['input'];
  setting: Scalars['String']['input'];
  tier: Scalars['String']['input'];
};


export type MutationSetWorkspaceLimitArgs = {
  limit: Scalars['Int']['input'];
  setting: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type MutationSignInArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationSignUpArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdateOrganizationPlanArgs = {
  organizationId: Scalars['String']['input'];
  tier: Scalars['String']['input'];
};


export type MutationUploadChannelDocumentationArgs = {
  channelId: Scalars['String']['input'];
  keys: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationUploadDatasetArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type MutationUploadDatasetV2Args = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  size: Scalars['Float']['input'];
  workspaceId: Scalars['String']['input'];
};


export type MutationUploadDatasetV2FinalizerArgs = {
  key: Scalars['String']['input'];
  parts?: InputMaybe<Array<InputMaybe<MultipartInput>>>;
  uploadId?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type MutationValidateGraphArgs = {
  graphId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};

export type OldUmap = {
  __typename?: 'OldUMAP';
  datasets?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  results?: Maybe<OldUmapResult>;
  samples?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  seed?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type OldUmapDatasets = {
  __typename?: 'OldUMAPDatasets';
  datasetId?: Maybe<Scalars['String']['output']>;
  datasetName?: Maybe<Scalars['String']['output']>;
  points?: Maybe<OldUmapPoints>;
};

export type OldUmapPoints = {
  __typename?: 'OldUMAPPoints';
  image?: Maybe<Scalars['String']['output']>;
  x?: Maybe<Scalars['String']['output']>;
  y?: Maybe<Scalars['String']['output']>;
  z?: Maybe<Scalars['String']['output']>;
};

export type OldUmapResult = {
  __typename?: 'OldUMAPResult';
  datasets?: Maybe<OldUmapDatasets>;
  featureSize?: Maybe<Scalars['String']['output']>;
  featuresize?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type Organization = {
  __typename?: 'Organization';
  expirationDate?: Maybe<Scalars['String']['output']>;
  expired?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  plan?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field has been deprecated for release 1.0.0, please upgrade your client or use plan instead.  */
  tier?: Maybe<Scalars['String']['output']>;
};

export type OrganizationAdmin = {
  __typename?: 'OrganizationAdmin';
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
};

export type OrganizationSetting = {
  __typename?: 'OrganizationSetting';
  limit: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  setting: Scalars['String']['output'];
  units: Scalars['String']['output'];
  usage: Scalars['Float']['output'];
};

export type OrganizationUsage = {
  __typename?: 'OrganizationUsage';
  channel?: Maybe<Scalars['String']['output']>;
  instanceType?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['String']['output']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  totalItems: Scalars['Int']['output'];
};

export type PlatformPlan = {
  __typename?: 'PlatformPlan';
  editable: Scalars['Boolean']['output'];
  limit: Scalars['String']['output'];
  organization: Scalars['Boolean']['output'];
  plan: Scalars['String']['output'];
  setting: Scalars['String']['output'];
  threshold: Scalars['Boolean']['output'];
  track: Scalars['Boolean']['output'];
  units: Scalars['String']['output'];
  visible: Scalars['Boolean']['output'];
  workspace: Scalars['Boolean']['output'];
};

export type PlatformSetting = {
  __typename?: 'PlatformSetting';
  setting?: Maybe<Scalars['String']['output']>;
  tier?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Preview = {
  __typename?: 'Preview';
  channelId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  graphId: Scalars['String']['output'];
  previewId: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  workspaceId: Scalars['String']['output'];
};

export type PreviewLog = {
  __typename?: 'PreviewLog';
  channelId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  graphId: Scalars['String']['output'];
  log?: Maybe<Scalars['String']['output']>;
  previewId: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  workspaceId: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  getAPIKeyContext: ApiKey;
  getAPIKeyData: ApiKey;
  getAPIKeys: Array<Maybe<ApiKey>>;
  getAllUserCharges?: Maybe<Array<Maybe<Charge>>>;
  getAnalytics: Array<Maybe<Analytics>>;
  getAnalyticsLog: AnalyticsLog;
  getAnalyticsTypes: Array<Maybe<Scalars['String']['output']>>;
  getAnnotationFormats?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  getAnnotationLog: AnnotationLog;
  getAnnotationMaps: Array<Maybe<AnnotationMap>>;
  getAnnotations: Array<Maybe<Annotation>>;
  getBanner: Banner;
  getBillingHistory?: Maybe<Array<Maybe<StripePaymentIntentInfo>>>;
  getChannelDeployment?: Maybe<ChannelDeployment>;
  getChannelDocumentation?: Maybe<Array<Maybe<ChannelDocumentation>>>;
  getChannelSchema: Scalars['String']['output'];
  getChannels?: Maybe<Array<Maybe<Channel>>>;
  getContentCodes?: Maybe<Array<Maybe<ContentCode>>>;
  getCurrentUser?: Maybe<User>;
  getCustomerInformation?: Maybe<CustomerInformation>;
  getCustomerPaymentMethods?: Maybe<Array<Maybe<CustomerPaymentInformation>>>;
  getDatasetAnnotation?: Maybe<RunData>;
  getDatasetDirectories?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  getDatasetFiles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  getDatasetImage?: Maybe<RunData>;
  getDatasetJobs?: Maybe<Array<Maybe<DatasetJob>>>;
  getDatasetLog?: Maybe<DatasetLog>;
  getDatasetMask?: Maybe<RunData>;
  getDatasetMetadata?: Maybe<RunData>;
  getDatasetRuns?: Maybe<Array<Maybe<DatasetRun>>>;
  getDatasetThumbnails: Array<Maybe<Scalars['String']['output']>>;
  getDatasets?: Maybe<Array<Maybe<Dataset>>>;
  getDefaultGraph: Scalars['String']['output'];
  /** @deprecated Support for getGANDataset has ended, please upgrade your client. */
  getGANDataset?: Maybe<GanDataset>;
  getGANDatasetLog: GanDatasetLog;
  getGANDatasets: Array<Maybe<GanDataset>>;
  getGANModels: Array<Maybe<GanModel>>;
  getGraphs?: Maybe<Array<Maybe<Graph>>>;
  getInvitations?: Maybe<Array<Maybe<Invitation>>>;
  getLLMBaseChannels: Array<Maybe<Scalars['String']['output']>>;
  getLLMChannelNodeTypes: Array<Maybe<LlmChannelNodeTypes>>;
  getLLMResponse: LlmResponse;
  getLinkedApps?: Maybe<Array<Maybe<LinkedApp>>>;
  getManagedChannels: Array<Maybe<ManagedChannel>>;
  getManagedGANs: Array<Maybe<ManagedGan>>;
  getManagedMaps: Array<Maybe<ManagedMap>>;
  getManagedVolumes: Array<Maybe<ManagedVolume>>;
  getMembers?: Maybe<Array<Maybe<Member>>>;
  /** @deprecated Support for getOrganizationLimits has ended, please upgrade your client. */
  getOrganizationLimits?: Maybe<Array<Maybe<OrganizationSetting>>>;
  getOrganizationSettings?: Maybe<Array<Maybe<OrganizationSetting>>>;
  /** @deprecated Support for getOrganizationUsage has ended, please upgrade your client. */
  getOrganizationUsage?: Maybe<Array<Maybe<OrganizationUsage>>>;
  getOrganizations: Array<Maybe<Organization>>;
  /** @deprecated Support for getPlatformLimits has ended, please upgrade your client. */
  getPlatformLimits?: Maybe<Array<Maybe<PlatformSetting>>>;
  getPlatformSettings: Array<Maybe<PlatformSetting>>;
  getPreview?: Maybe<Preview>;
  getPreviewLog?: Maybe<PreviewLog>;
  getStripeProductsList?: Maybe<Array<Maybe<StripeProduct>>>;
  /** @deprecated Support for getUMAP has ended, please upgrade your client. */
  getUMAP?: Maybe<Array<Maybe<OldUmap>>>;
  getUMAPLog?: Maybe<UmapLog>;
  getUMAPPoint: UmapImages;
  getUMAPs: Array<Maybe<Umap>>;
  getVolumeData: GetVolumeData;
  getVolumes?: Maybe<Array<Maybe<Volume>>>;
  /** @deprecated Support for getWorkspaceLimits has ended, please upgrade your client. */
  getWorkspaceLimits?: Maybe<Array<Maybe<WorkspaceSetting>>>;
  getWorkspaceSettings?: Maybe<Array<Maybe<WorkspaceSetting>>>;
  getWorkspaces?: Maybe<Array<Maybe<Workspace>>>;
  helloAnalytics: Scalars['String']['output'];
  helloAnnotations: Scalars['String']['output'];
  helloCore: Scalars['String']['output'];
  helloGAN: Scalars['String']['output'];
  helloLLM: Scalars['String']['output'];
  helloUMAP: Scalars['String']['output'];
  validateContentCode?: Maybe<Scalars['Boolean']['output']>;
};


export type QueryGetApiKeyContextArgs = {
  apiKey: Scalars['String']['input'];
};


export type QueryGetApiKeyDataArgs = {
  name: Scalars['String']['input'];
};


export type QueryGetAllUserChargesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  organizationId: Scalars['String']['input'];
};


export type QueryGetAnalyticsArgs = {
  analyticsId?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  datasetId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type QueryGetAnalyticsLogArgs = {
  analyticsId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type QueryGetAnnotationLogArgs = {
  annotationId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type QueryGetAnnotationMapsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mapId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAnnotationsArgs = {
  annotationId?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  datasetId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type QueryGetBillingHistoryArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetChannelDeploymentArgs = {
  deploymentId: Scalars['String']['input'];
};


export type QueryGetChannelDocumentationArgs = {
  channelId: Scalars['String']['input'];
};


export type QueryGetChannelSchemaArgs = {
  channelId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetChannelsArgs = {
  channelId?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetCustomerInformationArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetCustomerPaymentMethodsArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetDatasetAnnotationArgs = {
  datasetId: Scalars['String']['input'];
  run: Scalars['Int']['input'];
  workspaceId: Scalars['String']['input'];
};


export type QueryGetDatasetDirectoriesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  datasetId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type QueryGetDatasetFilesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  datasetId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type QueryGetDatasetImageArgs = {
  datasetId: Scalars['String']['input'];
  run: Scalars['Int']['input'];
  workspaceId: Scalars['String']['input'];
};


export type QueryGetDatasetJobsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  datasetId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetDatasetLogArgs = {
  datasetId: Scalars['String']['input'];
  runId?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type QueryGetDatasetMaskArgs = {
  datasetId: Scalars['String']['input'];
  run: Scalars['Int']['input'];
  workspaceId: Scalars['String']['input'];
};


export type QueryGetDatasetMetadataArgs = {
  datasetId: Scalars['String']['input'];
  run: Scalars['Int']['input'];
  workspaceId: Scalars['String']['input'];
};


export type QueryGetDatasetRunsArgs = {
  datasetId: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type QueryGetDatasetThumbnailsArgs = {
  datasetId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type QueryGetDatasetsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  datasetId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type QueryGetDefaultGraphArgs = {
  channelId: Scalars['String']['input'];
};


export type QueryGetGanDatasetArgs = {
  datasetId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type QueryGetGanDatasetLogArgs = {
  datasetId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type QueryGetGanDatasetsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  datasetId?: InputMaybe<Scalars['String']['input']>;
  gandatasetId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type QueryGetGanModelsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  modelId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetGraphsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  graphId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  staged?: InputMaybe<Scalars['Boolean']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type QueryGetInvitationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetLlmResponseArgs = {
  promptId: Scalars['String']['input'];
};


export type QueryGetManagedChannelsArgs = {
  channelId?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  organizationId: Scalars['String']['input'];
};


export type QueryGetManagedGaNsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};


export type QueryGetManagedMapsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mapId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};


export type QueryGetManagedVolumesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  volumeId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMembersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetOrganizationLimitsArgs = {
  organizationId: Scalars['String']['input'];
  setting?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetOrganizationSettingsArgs = {
  organizationId: Scalars['String']['input'];
  setting?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetOrganizationUsageArgs = {
  member?: InputMaybe<Scalars['String']['input']>;
  month: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  workspaceId?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['String']['input'];
};


export type QueryGetOrganizationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetPlatformLimitsArgs = {
  setting?: InputMaybe<Scalars['String']['input']>;
  tier?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetPreviewArgs = {
  previewId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type QueryGetPreviewLogArgs = {
  previewId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type QueryGetStripeProductsListArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetUmapArgs = {
  umapId?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type QueryGetUmapLogArgs = {
  umapId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type QueryGetUmapPointArgs = {
  datasetId: Scalars['String']['input'];
  image: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type QueryGetUmaPsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  datasetId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  umapId?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type QueryGetVolumeDataArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  dir?: InputMaybe<Scalars['String']['input']>;
  keys?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  volumeId: Scalars['String']['input'];
};


export type QueryGetVolumesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  volumeId?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetWorkspaceLimitsArgs = {
  setting?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type QueryGetWorkspaceSettingsArgs = {
  setting?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};


export type QueryGetWorkspacesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryValidateContentCodeArgs = {
  code: Scalars['String']['input'];
};

export enum RoleType {
  Admin = 'admin',
  Guest = 'guest',
  Member = 'member'
}

export type RunData = {
  __typename?: 'RunData';
  data?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  datasetId?: Maybe<Scalars['String']['output']>;
  run?: Maybe<Scalars['Int']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

export type SessionCredentials = {
  __typename?: 'SessionCredentials';
  accesskey: Scalars['String']['output'];
  accesskeyid: Scalars['String']['output'];
  sessiontoken: Scalars['String']['output'];
};

export type StripePaymentIntentInfo = {
  __typename?: 'StripePaymentIntentInfo';
  amount?: Maybe<Scalars['Int']['output']>;
  amount_capturable?: Maybe<Scalars['Int']['output']>;
  amount_received?: Maybe<Scalars['Int']['output']>;
  confirmation_method?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type StripeProduct = {
  __typename?: 'StripeProduct';
  active?: Maybe<Scalars['Boolean']['output']>;
  billing_scheme?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  livemode?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<StripeProductMetadata>;
  name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  priceId?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Scalars['String']['output']>;
  recurring?: Maybe<StripeProductRecurring>;
  type?: Maybe<Scalars['String']['output']>;
  unit_amount?: Maybe<Scalars['Int']['output']>;
  unit_amount_decimal?: Maybe<Scalars['String']['output']>;
};

export type StripeProductMetadata = {
  __typename?: 'StripeProductMetadata';
  UMAP?: Maybe<Scalars['String']['output']>;
  analytics?: Maybe<Scalars['String']['output']>;
  annotation?: Maybe<Scalars['String']['output']>;
  channelsPerOrganization?: Maybe<Scalars['String']['output']>;
  dataVolumeGBLimit?: Maybe<Scalars['String']['output']>;
  dataVolumes?: Maybe<Scalars['String']['output']>;
  datasetGBPerOrganization?: Maybe<Scalars['String']['output']>;
  datasetsPerWorkspace?: Maybe<Scalars['String']['output']>;
  domainAdaptation?: Maybe<Scalars['String']['output']>;
  downloadBandwidth?: Maybe<Scalars['String']['output']>;
  graphsPerWorkspace?: Maybe<Scalars['String']['output']>;
  limitsOnHours?: Maybe<Scalars['String']['output']>;
  maxConcurrentComputerInstances?: Maybe<Scalars['String']['output']>;
  membersPerOrganization?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationSeat?: Maybe<Scalars['String']['output']>;
  stagedGraphsPerWorkspace?: Maybe<Scalars['String']['output']>;
  typeOfAWSInstance?: Maybe<Scalars['String']['output']>;
  workspaceGuestsWithReadAndWrite?: Maybe<Scalars['String']['output']>;
  workspacesPerOrganization?: Maybe<Scalars['String']['output']>;
};

export type StripeProductRecurring = {
  __typename?: 'StripeProductRecurring';
  interval?: Maybe<Scalars['String']['output']>;
  interval_count?: Maybe<Scalars['Int']['output']>;
  usage_type?: Maybe<Scalars['String']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  graphDeleted?: Maybe<Scalars['Boolean']['output']>;
  graphUpdates?: Maybe<GraphUpdateReponse>;
};


export type SubscriptionGraphDeletedArgs = {
  graphId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type SubscriptionGraphUpdatesArgs = {
  graphId: Scalars['String']['input'];
  uid?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};

export type Umap = {
  __typename?: 'UMAP';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  datasets: Array<Maybe<UmapDataset>>;
  name: Scalars['String']['output'];
  results?: Maybe<Scalars['String']['output']>;
  samples: Array<Maybe<Scalars['Int']['output']>>;
  seed: Scalars['String']['output'];
  status: Scalars['String']['output'];
  umapId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  workspaceId: Scalars['String']['output'];
};

export type UmapDataset = {
  __typename?: 'UMAPDataset';
  dataset?: Maybe<Scalars['String']['output']>;
  datasetId?: Maybe<Scalars['String']['output']>;
};

export type UmapImages = {
  __typename?: 'UMAPImages';
  heatmap4?: Maybe<Scalars['String']['output']>;
  heatmap8?: Maybe<Scalars['String']['output']>;
  heatmap16?: Maybe<Scalars['String']['output']>;
  heatmap32?: Maybe<Scalars['String']['output']>;
  heatmap64?: Maybe<Scalars['String']['output']>;
  image: Scalars['String']['output'];
};

export type UmapLog = {
  __typename?: 'UMAPLog';
  log?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  umapId: Scalars['String']['output'];
  workspaceId: Scalars['String']['output'];
};

export type UmapPoints = {
  __typename?: 'UMAPPoints';
  image?: Maybe<Scalars['String']['output']>;
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
  z?: Maybe<Scalars['Float']['output']>;
};

export type UploadDatasetResponse = {
  __typename?: 'UploadDatasetResponse';
  datasetId: Scalars['String']['output'];
  key: Scalars['String']['output'];
  partSize?: Maybe<Scalars['Float']['output']>;
  uploadId?: Maybe<Scalars['String']['output']>;
  urls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type UploadFields = {
  __typename?: 'UploadFields';
  algorithm?: Maybe<Scalars['String']['output']>;
  bucket?: Maybe<Scalars['String']['output']>;
  credential?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  policy?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type UploadGanResponse = {
  __typename?: 'UploadGANResponse';
  key: Scalars['String']['output'];
  modelId: Scalars['String']['output'];
  partSize?: Maybe<Scalars['Float']['output']>;
  uploadId?: Maybe<Scalars['String']['output']>;
  urls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type UploadVolumeDataResponse = {
  __typename?: 'UploadVolumeDataResponse';
  key: Scalars['String']['output'];
  partSize?: Maybe<Scalars['Float']['output']>;
  uploadId?: Maybe<Scalars['String']['output']>;
  urls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  volumeId: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type UserCredentials = {
  __typename?: 'UserCredentials';
  expires: Scalars['Int']['output'];
  idtoken: Scalars['String']['output'];
  uid: Scalars['String']['output'];
};

export type Volume = {
  __typename?: 'Volume';
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organization: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  permission: Scalars['String']['output'];
  size?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  volumeId: Scalars['String']['output'];
};

export type VolumeCredentials = {
  __typename?: 'VolumeCredentials';
  credentials: SessionCredentials;
  keys: Array<Maybe<Scalars['String']['output']>>;
  rw: Array<Maybe<Scalars['String']['output']>>;
};

export type VolumeData = {
  __typename?: 'VolumeData';
  fields?: Maybe<VolumeUploadFields>;
  hash?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type VolumeOrganization = {
  __typename?: 'VolumeOrganization';
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  permission?: Maybe<Scalars['String']['output']>;
};

export type VolumeUploadFields = {
  __typename?: 'VolumeUploadFields';
  algorithm?: Maybe<Scalars['String']['output']>;
  bucket?: Maybe<Scalars['String']['output']>;
  credential?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  policy?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type Workspace = {
  __typename?: 'Workspace';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organization?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
  previewThumbnail?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  workspaceId: Scalars['String']['output'];
};

export type WorkspaceSetting = {
  __typename?: 'WorkspaceSetting';
  limit: Scalars['String']['output'];
  setting: Scalars['String']['output'];
  units: Scalars['String']['output'];
  usage: Scalars['Float']['output'];
  workspaceId: Scalars['String']['output'];
};

export type GetVolumeData = {
  __typename?: 'getVolumeData';
  keys: Array<Maybe<VolumeData>>;
  pageInfo: PageInfo;
};

export type UploadDatasetV2MutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  size: Scalars['Float']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
}>;


export type UploadDatasetV2Mutation = { __typename?: 'Mutation', uploadDatasetV2: { __typename?: 'UploadDatasetResponse', datasetId: string, key: string, partSize?: number | null, uploadId?: string | null, urls?: Array<string | null> | null } };

export type UploadDatasetV2FinalizerMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  key: Scalars['String']['input'];
  uploadId?: InputMaybe<Scalars['String']['input']>;
  parts?: InputMaybe<Array<InputMaybe<MultipartInput>> | InputMaybe<MultipartInput>>;
}>;


export type UploadDatasetV2FinalizerMutation = { __typename?: 'Mutation', uploadDatasetV2Finalizer?: boolean | null };

export type CreateManagedGanv2MutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  size: Scalars['Float']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  flags?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateManagedGanv2Mutation = { __typename?: 'Mutation', createManagedGANV2: { __typename?: 'UploadGANResponse', key: string, modelId: string, uploadId?: string | null, partSize?: number | null, urls?: Array<string | null> | null } };

export type CreateManagedGanv2FinalizerMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  key: Scalars['String']['input'];
  uploadId?: InputMaybe<Scalars['String']['input']>;
  parts?: InputMaybe<Array<InputMaybe<MultipartInput>> | InputMaybe<MultipartInput>>;
}>;


export type CreateManagedGanv2FinalizerMutation = { __typename?: 'Mutation', createManagedGANV2Finalizer?: boolean | null };

export type PutVolumeDataV2MutationVariables = Exact<{
  volumeId: Scalars['String']['input'];
  key: Scalars['String']['input'];
  size: Scalars['Float']['input'];
}>;


export type PutVolumeDataV2Mutation = { __typename?: 'Mutation', putVolumeDataV2: { __typename?: 'UploadVolumeDataResponse', key: string, volumeId: string, uploadId?: string | null, partSize?: number | null, urls?: Array<string | null> | null } };

export type PutVolumeDataV2FinalizerMutationVariables = Exact<{
  volumeId: Scalars['String']['input'];
  key: Scalars['String']['input'];
  uploadId?: InputMaybe<Scalars['String']['input']>;
  parts?: InputMaybe<Array<InputMaybe<MultipartInput>> | InputMaybe<MultipartInput>>;
}>;


export type PutVolumeDataV2FinalizerMutation = { __typename?: 'Mutation', putVolumeDataV2Finalizer?: boolean | null };

export type CreateAnalyticsMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  type: Scalars['String']['input'];
  datasetId: Scalars['String']['input'];
}>;


export type CreateAnalyticsMutation = { __typename?: 'Mutation', createAnalytics: string };

export type DeleteAnalyticsMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  analyticsId: Scalars['String']['input'];
}>;


export type DeleteAnalyticsMutation = { __typename?: 'Mutation', deleteAnalytics: boolean };

export type GetAnalyticsQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  analyticsId: Scalars['String']['input'];
}>;


export type GetAnalyticsQuery = { __typename?: 'Query', getAnalytics: Array<{ __typename?: 'Analytics', result?: string | null } | null> };

export type GetAnalyticsTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAnalyticsTypesQuery = { __typename?: 'Query', getAnalyticsTypes: Array<string | null> };

export type GetAnalyticsLogQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  analyticsId: Scalars['String']['input'];
}>;


export type GetAnalyticsLogQuery = { __typename?: 'Query', getAnalyticsLog: { __typename?: 'AnalyticsLog', log?: string | null } };

export type CreateAnnotationMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  datasetId: Scalars['String']['input'];
  format: Scalars['String']['input'];
  mapId: Scalars['String']['input'];
}>;


export type CreateAnnotationMutation = { __typename?: 'Mutation', createAnnotation: string };

export type DownloadAnnotationMutationVariables = Exact<{
  annotationId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
}>;


export type DownloadAnnotationMutation = { __typename?: 'Mutation', downloadAnnotation: string };

export type DeleteAnnotationMutationVariables = Exact<{
  annotationId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
}>;


export type DeleteAnnotationMutation = { __typename?: 'Mutation', deleteAnnotation: string };

export type GetAnnotationFormatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAnnotationFormatsQuery = { __typename?: 'Query', getAnnotationFormats?: Array<string | null> | null };

export type GetAnnotationMapsQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
  mapId?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetAnnotationMapsQuery = { __typename?: 'Query', getAnnotationMaps: Array<{ __typename?: 'AnnotationMap', description?: string | null, mapId: string, name: string, organization: string, createdAt?: string | null, organizationId: string, updatedAt?: string | null } | null> };

export type GetAnnotationLogQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  annotationId: Scalars['String']['input'];
}>;


export type GetAnnotationLogQuery = { __typename?: 'Query', getAnnotationLog: { __typename?: 'AnnotationLog', log?: string | null } };

export type CreateManagedMapMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateManagedMapMutation = { __typename?: 'Mutation', createManagedMap: { __typename?: 'MapData', mapId: string, url?: string | null, fields?: { __typename?: 'UploadFields', algorithm?: string | null, bucket?: string | null, credential?: string | null, date?: string | null, key?: string | null, policy?: string | null, signature?: string | null, token?: string | null } | null } };

export type DeleteManagedMapMutationVariables = Exact<{
  mapId: Scalars['String']['input'];
}>;


export type DeleteManagedMapMutation = { __typename?: 'Mutation', deleteManagedMap: boolean };

export type EditManagedMapMutationVariables = Exact<{
  mapId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
}>;


export type EditManagedMapMutation = { __typename?: 'Mutation', editManagedMap: boolean };

export type GetChannelsQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars['String']['input']>;
  channelId?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetChannelsQuery = { __typename?: 'Query', getChannels?: Array<{ __typename?: 'Channel', channelId: string, name: string, description: string, documentation?: boolean | null, organization: string, organizationId: string, createdAt: string, updatedAt: string } | null> | null };

export type CreateFeedbackMutationVariables = Exact<{
  summary: Scalars['String']['input'];
  description: Scalars['String']['input'];
  files: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type CreateFeedbackMutation = { __typename?: 'Mutation', createFeedback: { __typename?: 'Feedback', feedbackId: string, uploads?: Array<{ __typename?: 'FeedbackUpload', key?: string | null, url?: string | null, fields?: { __typename?: 'FeedbackUploadFields', key?: string | null, bucket?: string | null, algorithm?: string | null, credential?: string | null, date?: string | null, token?: string | null, policy?: string | null, signature?: string | null } | null } | null> | null } };

export type GetChannelDocumentationQueryVariables = Exact<{
  channelId: Scalars['String']['input'];
}>;


export type GetChannelDocumentationQuery = { __typename?: 'Query', getChannelDocumentation?: Array<{ __typename?: 'ChannelDocumentation', markdown: string } | null> | null };

export type GetManagedChannelsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  channelId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetManagedChannelsQuery = { __typename?: 'Query', getManagedChannels: Array<{ __typename?: 'ManagedChannel', channelId: string, createdAt?: string | null, createdBy?: string | null, description?: string | null, instanceType: string, interfaceVersion?: number | null, name: string, organizationId: string, timeout?: number | null, updatedAt?: string | null, updatedBy?: string | null, volumes: Array<string | null>, organizations?: Array<{ __typename?: 'ChannelOrganization', name: string, organizationId: string } | null> | null } | null> };

export type CreateManagedChannelMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  volumes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  instance?: InputMaybe<Scalars['String']['input']>;
  timeout?: InputMaybe<Scalars['Int']['input']>;
  interfaceVersion?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CreateManagedChannelMutation = { __typename?: 'Mutation', createManagedChannel: string };

export type DeleteManagedChannelMutationVariables = Exact<{
  channelId: Scalars['String']['input'];
}>;


export type DeleteManagedChannelMutation = { __typename?: 'Mutation', deleteManagedChannel?: boolean | null };

export type RemoveChannelOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  channelId: Scalars['String']['input'];
}>;


export type RemoveChannelOrganizationMutation = { __typename?: 'Mutation', removeChannelOrganization?: boolean | null };

export type EditManagedChannelMutationVariables = Exact<{
  channelId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  volumes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  instance?: InputMaybe<Scalars['String']['input']>;
  timeout?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  interfaceVersion?: InputMaybe<Scalars['Int']['input']>;
}>;


export type EditManagedChannelMutation = { __typename?: 'Mutation', editManagedChannel?: boolean | null };

export type UploadDatasetMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type UploadDatasetMutation = { __typename?: 'Mutation', uploadDataset?: { __typename?: 'DatasetUpload', key?: string | null, url?: string | null, fields?: { __typename?: 'DatasetUploadFields', key?: string | null, bucket?: string | null, algorithm?: string | null, credential?: string | null, date?: string | null, token?: string | null, policy?: string | null, signature?: string | null } | null } | null };

export type DownloadDatasetMutationVariables = Exact<{
  datasetId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
}>;


export type DownloadDatasetMutation = { __typename?: 'Mutation', downloadDataset?: string | null };

export type DeleteDatasetMutationVariables = Exact<{
  datasetId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
}>;


export type DeleteDatasetMutation = { __typename?: 'Mutation', deleteDataset?: boolean | null };

export type GetDatasetRunsQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  datasetId: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetDatasetRunsQuery = { __typename?: 'Query', getDatasetRuns?: Array<{ __typename?: 'DatasetRun', runId?: string | null, workspaceId?: string | null, datasetId?: string | null, channelId?: string | null, startTime?: string | null, endTime?: string | null, state?: string | null, run?: number | null } | null> | null, getDatasetJobs?: Array<{ __typename?: 'DatasetJob', datasetId?: string | null, name?: string | null } | null> | null };

export type GetDatasetLogQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  datasetId: Scalars['String']['input'];
  runId: Scalars['String']['input'];
}>;


export type GetDatasetLogQuery = { __typename?: 'Query', getDatasetLog?: { __typename?: 'DatasetLog', runId?: string | null, workspaceId?: string | null, datasetId?: string | null, channelId?: string | null, email?: string | null, startTime?: string | null, endTime?: string | null, state?: string | null, run?: number | null, log?: string | null } | null };

export type GetDatasetImageQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  datasetId: Scalars['String']['input'];
  run: Scalars['Int']['input'];
}>;


export type GetDatasetImageQuery = { __typename?: 'Query', getDatasetImage?: { __typename?: 'RunData', data?: Array<string | null> | null } | null, getDatasetAnnotation?: { __typename?: 'RunData', data?: Array<string | null> | null } | null };

export type GetDatasetQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  datasetId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetDatasetQuery = { __typename?: 'Query', getDatasets?: Array<{ __typename?: 'Dataset', datasetId?: string | null, files?: number | null, name: string } | null> | null };

export type GetGanModelsQueryVariables = Exact<{
  workspaceId?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetGanModelsQuery = { __typename?: 'Query', getGANModels: Array<{ __typename?: 'GANModel', organizationId: string, modelId: string, description?: string | null, flags: string, name: string, organization: string, createdAt?: string | null, updatedAt?: string | null } | null> };

export type CreateGanDatasetMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  datasetId: Scalars['String']['input'];
  modelId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateGanDatasetMutation = { __typename?: 'Mutation', createGANDataset: string };

export type DeleteGanDatasetMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  datasetId: Scalars['String']['input'];
}>;


export type DeleteGanDatasetMutation = { __typename?: 'Mutation', deleteGANDataset?: boolean | null };

export type GetGanDatasetLogQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  datasetId: Scalars['String']['input'];
}>;


export type GetGanDatasetLogQuery = { __typename?: 'Query', getGANDatasetLog: { __typename?: 'GANDatasetLog', log?: string | null } };

export type EditManagedGanMutationVariables = Exact<{
  modelId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  flags?: InputMaybe<Scalars['String']['input']>;
}>;


export type EditManagedGanMutation = { __typename?: 'Mutation', editManagedGAN?: boolean | null };

export type DeleteManagedGanMutationVariables = Exact<{
  modelId: Scalars['String']['input'];
}>;


export type DeleteManagedGanMutation = { __typename?: 'Mutation', deleteManagedGAN?: boolean | null };

export type RemoveGanOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  modelId: Scalars['String']['input'];
}>;


export type RemoveGanOrganizationMutation = { __typename?: 'Mutation', removeGANOrganization?: boolean | null };

export type GetInvitationsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type GetInvitationsQuery = { __typename?: 'Query', getInvitations?: Array<{ __typename?: 'Invitation', email?: string | null, organizationId?: string | null, role?: RoleType | null, updatedAt?: string | null } | null> | null };

export type GetMembersQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetMembersQuery = { __typename?: 'Query', getMembers?: Array<{ __typename?: 'Member', userId: string, email: string, name: string, role: RoleType } | null> | null };

export type EditMemberMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type EditMemberMutation = { __typename?: 'Mutation', editMember?: boolean | null };

export type AddMemberMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  role: Scalars['String']['input'];
}>;


export type AddMemberMutation = { __typename?: 'Mutation', addMember?: boolean | null };

export type RemoveMemberMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;


export type RemoveMemberMutation = { __typename?: 'Mutation', removeMember?: boolean | null };

export type EditMemberByRoleMutationVariables = Exact<{
  organizationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  role: Scalars['String']['input'];
}>;


export type EditMemberByRoleMutation = { __typename?: 'Mutation', editMember?: boolean | null };

export type EditOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type EditOrganizationMutation = { __typename?: 'Mutation', editOrganization?: boolean | null };

export type DeleteOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type DeleteOrganizationMutation = { __typename?: 'Mutation', deleteOrganization?: boolean | null };

export type GetOrganizationsQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetOrganizationsQuery = { __typename?: 'Query', getOrganizations: Array<{ __typename?: 'Organization', name: string, role?: string | null, organizationId: string, stripeCustomerId?: string | null, expirationDate?: string | null, expired?: boolean | null, plan?: string | null } | null> };

export type GetOrganizationSettingsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  setting?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetOrganizationSettingsQuery = { __typename?: 'Query', getOrganizationSettings?: Array<{ __typename?: 'OrganizationSetting', organizationId: string, setting: string, limit: string, usage: number, units: string } | null> | null };

export type CreatePaymentIntentMutationVariables = Exact<{
  amount: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type CreatePaymentIntentMutation = { __typename?: 'Mutation', createPaymentIntent: string };

export type SaveDefaultPaymentMethodMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
}>;


export type SaveDefaultPaymentMethodMutation = { __typename?: 'Mutation', saveDefaultPaymentMethod: string };

export type AttachPaymentMethodToCustomerMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
}>;


export type AttachPaymentMethodToCustomerMutation = { __typename?: 'Mutation', attachPaymentMethodToCustomer: string };

export type UpdateOrganizationPlanMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  tier: Scalars['String']['input'];
}>;


export type UpdateOrganizationPlanMutation = { __typename?: 'Mutation', updateOrganizationPlan: string };

export type GetBillingHistoryQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type GetBillingHistoryQuery = { __typename?: 'Query', getBillingHistory?: Array<{ __typename?: 'StripePaymentIntentInfo', id?: string | null, object?: string | null, amount?: number | null, amount_capturable?: number | null, amount_received?: number | null, confirmation_method?: string | null, created?: number | null, currency?: string | null, description?: string | null, status?: string | null } | null> | null };

export type GetCustomerInformationQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type GetCustomerInformationQuery = { __typename?: 'Query', getCustomerInformation?: { __typename?: 'CustomerInformation', object?: string | null, email?: string | null, name?: string | null, phone?: string | null, address?: { __typename?: 'CustomerInformationAddress', city?: string | null, country?: string | null, line1?: string | null, line2?: string | null, postal_code?: string | null, state?: string | null } | null, invoice_settings?: { __typename?: 'CustomerInformationInvoiceSettings', default_payment_method?: string | null } | null } | null };

export type GetCustomerPaymentMethodsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type GetCustomerPaymentMethodsQuery = { __typename?: 'Query', getCustomerPaymentMethods?: Array<{ __typename?: 'CustomerPaymentInformation', id?: string | null, object?: string | null, created?: number | null, type?: string | null, billing_details?: { __typename?: 'CustomerPaymentInformationBillingDetails', email?: string | null, name?: string | null, phone?: string | null, address?: { __typename?: 'CustomerPaymentInformationBillingDetailsAddress', city?: string | null, country?: string | null, line1?: string | null, line2?: string | null, state?: string | null, postal_code?: string | null } | null } | null, card?: { __typename?: 'CustomerPaymentInformationCardDetails', brand?: string | null, country?: string | null, exp_month?: number | null, exp_year?: number | null, fingerprint?: string | null, funding?: string | null, last4?: string | null } | null, metadata?: { __typename?: 'CustomerPaymentInformationMetadata', organization?: string | null } | null } | null> | null };

export type CreateSubscriptionForUserMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  priceId: Scalars['String']['input'];
  paymentId: Scalars['String']['input'];
}>;


export type CreateSubscriptionForUserMutation = { __typename?: 'Mutation', createSubscriptionForUser: string };

export type GetStripeProductsListQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type GetStripeProductsListQuery = { __typename?: 'Query', getStripeProductsList?: Array<{ __typename?: 'StripeProduct', id?: string | null, priceId?: string | null, object?: string | null, active?: boolean | null, billing_scheme?: string | null, created?: number | null, nickname?: string | null, currency?: string | null, livemode?: boolean | null, product?: string | null, name?: string | null, description?: string | null, type?: string | null, unit_amount?: number | null, unit_amount_decimal?: string | null, recurring?: { __typename?: 'StripeProductRecurring', interval?: string | null, interval_count?: number | null, usage_type?: string | null } | null, metadata?: { __typename?: 'StripeProductMetadata', name?: string | null, organizationSeat?: string | null, membersPerOrganization?: string | null, workspacesPerOrganization?: string | null, workspaceGuestsWithReadAndWrite?: string | null, channelsPerOrganization?: string | null, dataVolumes?: string | null, dataVolumeGBLimit?: string | null, graphsPerWorkspace?: string | null, stagedGraphsPerWorkspace?: string | null, datasetsPerWorkspace?: string | null, datasetGBPerOrganization?: string | null, maxConcurrentComputerInstances?: string | null, typeOfAWSInstance?: string | null, annotation?: string | null, analytics?: string | null, UMAP?: string | null, domainAdaptation?: string | null, limitsOnHours?: string | null, downloadBandwidth?: string | null } | null } | null> | null };

export type GetDatasetThumbnailsQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  datasetId: Scalars['String']['input'];
}>;


export type GetDatasetThumbnailsQuery = { __typename?: 'Query', getDatasetThumbnails: Array<string | null> };

export type PingPreviewMutationVariables = Exact<{
  channelId: Scalars['String']['input'];
}>;


export type PingPreviewMutation = { __typename?: 'Mutation', pingPreview?: boolean | null };

export type CreateLinkedAppMutationVariables = Exact<{
  appId: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;


export type CreateLinkedAppMutation = { __typename?: 'Mutation', createLinkedApp?: boolean | null };

export type DeleteLinkedAppMutationVariables = Exact<{
  appId: Scalars['String']['input'];
}>;


export type DeleteLinkedAppMutation = { __typename?: 'Mutation', deleteLinkedApp?: boolean | null };

export type GetLinkedAppsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLinkedAppsQuery = { __typename?: 'Query', getLinkedApps?: Array<{ __typename?: 'LinkedApp', appId: string, name: string, linked: boolean, thumbnail?: string | null, description?: string | null, clientId?: string | null, clientSecret?: string | null } | null> | null };

export type SignUpMutationVariables = Exact<{
  email: Scalars['String']['input'];
  code?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUp?: boolean | null };

export type ValidateContentCodeQueryVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type ValidateContentCodeQuery = { __typename?: 'Query', validateContentCode?: boolean | null };

export type CreateUmapMutationVariables = Exact<{
  datasetIds: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  samples: Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>;
  seed?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
}>;


export type CreateUmapMutation = { __typename?: 'Mutation', createUMAP: string };

export type DeleteUmapMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  umapId: Scalars['String']['input'];
}>;


export type DeleteUmapMutation = { __typename?: 'Mutation', deleteUMAP: boolean };

export type EditUmapMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  umapId: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type EditUmapMutation = { __typename?: 'Mutation', editUMAP: boolean };

export type GetUmapLogQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  umapId: Scalars['String']['input'];
}>;


export type GetUmapLogQuery = { __typename?: 'Query', getUMAPLog?: { __typename?: 'UMAPLog', umapId: string, workspaceId: string, state: string, log?: string | null } | null };

export type GetUmapQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  umapId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUmapQuery = { __typename?: 'Query', getUMAPs: Array<{ __typename?: 'UMAP', name: string, results?: string | null, samples: Array<number | null>, seed: string, status: string, umapId: string, workspaceId: string, datasets: Array<{ __typename?: 'UMAPDataset', datasetId?: string | null, dataset?: string | null } | null> } | null> };

export type RemoveMapOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  mapId: Scalars['String']['input'];
}>;


export type RemoveMapOrganizationMutation = { __typename?: 'Mutation', removeMapOrganization?: boolean | null };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', getCurrentUser?: { __typename?: 'User', userId: string, email: string, name: string } | null };

export type DeleteUserMutationVariables = Exact<{
  isDeleting?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: Array<{ __typename?: 'OrganizationAdmin', organizationId?: string | null, name?: string | null } | null> | null };

export type GetVolumesQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars['String']['input']>;
  volumeId?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetVolumesQuery = { __typename?: 'Query', getVolumes?: Array<{ __typename?: 'Volume', name: string, size?: string | null, volumeId: string, updatedAt: string, createdAt: string, permission: string, description: string, organization: string, organizationId: string } | null> | null };

export type CreateManagedVolumeMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateManagedVolumeMutation = { __typename?: 'Mutation', createManagedVolume: string };

export type DeleteManagedVolumeMutationVariables = Exact<{
  volumeId: Scalars['String']['input'];
}>;


export type DeleteManagedVolumeMutation = { __typename?: 'Mutation', deleteManagedVolume?: boolean | null };

export type GetVolumeDataQueryVariables = Exact<{
  volumeId: Scalars['String']['input'];
  keys?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  dir?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetVolumeDataQuery = { __typename?: 'Query', getVolumeData: { __typename?: 'getVolumeData', keys: Array<{ __typename?: 'VolumeData', url?: string | null, updatedAt?: string | null, thumbnailUrl?: string | null, size?: number | null, key?: string | null, hash?: string | null, fields?: { __typename?: 'VolumeUploadFields', algorithm?: string | null, bucket?: string | null, credential?: string | null, date?: string | null, key?: string | null, policy?: string | null, signature?: string | null, token?: string | null } | null } | null>, pageInfo: { __typename?: 'PageInfo', totalItems: number, offset?: number | null, limit?: number | null } } };

export type DeleteVolumeDataMutationVariables = Exact<{
  volumeId: Scalars['String']['input'];
  keys?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type DeleteVolumeDataMutation = { __typename?: 'Mutation', deleteVolumeData: Array<string | null> };

export type PutVolumeDataMutationVariables = Exact<{
  volumeId: Scalars['String']['input'];
  keys: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type PutVolumeDataMutation = { __typename?: 'Mutation', putVolumeData: Array<{ __typename?: 'VolumeData', hash?: string | null, key?: string | null, updatedAt?: string | null, url?: string | null, fields?: { __typename?: 'VolumeUploadFields', algorithm?: string | null, bucket?: string | null, credential?: string | null, date?: string | null, key?: string | null, policy?: string | null, signature?: string | null, token?: string | null } | null } | null> };

export type EditVolumeDataMutationVariables = Exact<{
  volumeId: Scalars['String']['input'];
  source: Scalars['String']['input'];
  key: Scalars['String']['input'];
}>;


export type EditVolumeDataMutation = { __typename?: 'Mutation', editVolumeData?: boolean | null };

export type RemoveVolumeOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  volumeId: Scalars['String']['input'];
}>;


export type RemoveVolumeOrganizationMutation = { __typename?: 'Mutation', removeVolumeOrganization?: boolean | null };

export type EditManagedVolumeMutationVariables = Exact<{
  volumeId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  permission?: InputMaybe<Scalars['String']['input']>;
}>;


export type EditManagedVolumeMutation = { __typename?: 'Mutation', editManagedVolume?: boolean | null };

export type EditWorkspaceMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  channelIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  volumeIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  ganIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  mapIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type EditWorkspaceMutation = { __typename?: 'Mutation', editWorkspace: string };

export type EditWorkspaceThumbnailMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  thumbnail: Scalars['String']['input'];
}>;


export type EditWorkspaceThumbnailMutation = { __typename?: 'Mutation', editWorkspaceThumbnail: string };

export type CreateWorkspaceMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  channelIds: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  volumeIds: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type CreateWorkspaceMutation = { __typename?: 'Mutation', createWorkspace: string };

export type DeleteWorkspaceMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
}>;


export type DeleteWorkspaceMutation = { __typename?: 'Mutation', deleteWorkspace: string };

export type GetWorkspacesQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetWorkspacesQuery = { __typename?: 'Query', getWorkspaces?: Array<{ __typename?: 'Workspace', workspaceId: string, organizationId: string, organization?: string | null, name: string, createdAt?: string | null, updatedAt?: string | null, previewThumbnail?: string | null, status?: string | null } | null> | null };

export type GetWorkspaceSettingsQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  setting?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetWorkspaceSettingsQuery = { __typename?: 'Query', getWorkspaceSettings?: Array<{ __typename?: 'WorkspaceSetting', workspaceId: string, setting: string, limit: string, usage: number, units: string } | null> | null };

export type GetOrganizationJobsQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetOrganizationJobsQuery = { __typename?: 'Query', getDatasetJobs?: Array<{ __typename?: 'DatasetJob', datasetId?: string | null, workspaceId?: string | null, name?: string | null, runs?: number | null, status?: string | null, createdAt?: string | null, estimatedEndAt?: string | null } | null> | null };

export type GetWorkspaceNameQueryVariables = Exact<{
  workspaceId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetWorkspaceNameQuery = { __typename?: 'Query', getWorkspaces?: Array<{ __typename?: 'Workspace', name: string, workspaceId: string } | null> | null };

export type GetContentCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContentCodesQuery = { __typename?: 'Query', getContentCodes?: Array<{ __typename?: 'ContentCode', code: string, name: string, description: string, thumbnail?: string | null, link?: string | null, featured: boolean, contactUs: boolean, expiresAt?: string | null } | null> | null };

export type GetWorkspaceAnalyticsQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetWorkspaceAnalyticsQuery = { __typename?: 'Query', getAnalytics: Array<{ __typename?: 'Analytics', analyticsId: string, createdAt?: string | null, createdBy?: string | null, dataset: string, datasetId: string, result?: string | null, status: string, type: string, workspaceId: string } | null> };

export type GetWorkspaceUmapsQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetWorkspaceUmapsQuery = { __typename?: 'Query', getUMAPs: Array<{ __typename?: 'UMAP', createdAt?: string | null, createdBy?: string | null, name: string, results?: string | null, samples: Array<number | null>, seed: string, status: string, umapId: string, workspaceId: string, datasets: Array<{ __typename?: 'UMAPDataset', dataset?: string | null, datasetId?: string | null } | null> } | null> };

export type GetGraphsQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  staged?: InputMaybe<Scalars['Boolean']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetGraphsQuery = { __typename?: 'Query', getGraphs?: Array<{ __typename?: 'Graph', channel: string, createdAt?: string | null, createdBy?: string | null, description?: string | null, graphId: string, name: string, thumbnail?: string | null, updatedAt?: string | null, workspaceId: string } | null> | null };

export type GetGraphMetadataQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  graphId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetGraphMetadataQuery = { __typename?: 'Query', getGraphs?: Array<{ __typename?: 'Graph', channelId: string, description?: string | null, graphId: string, name: string, previewId?: string | null, readOnly?: boolean | null, showPreview?: boolean | null, workspaceId: string, updatedAt?: string | null } | null> | null };

export type GetGraphDataMutationVariables = Exact<{
  graphId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
}>;


export type GetGraphDataMutation = { __typename?: 'Mutation', downloadGraph?: string | null };

export type EditGraphMutationVariables = Exact<{
  graphId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type EditGraphMutation = { __typename?: 'Mutation', editGraph?: boolean | null };

export type DeleteGraphMutationVariables = Exact<{
  graphId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
}>;


export type DeleteGraphMutation = { __typename?: 'Mutation', deleteGraph?: boolean | null };

export type DownloadGraphMutationVariables = Exact<{
  graphId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
}>;


export type DownloadGraphMutation = { __typename?: 'Mutation', downloadGraph?: string | null };

export type DuplicateGraphMutationVariables = Exact<{
  graphId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
}>;


export type DuplicateGraphMutation = { __typename?: 'Mutation', duplicateGraph?: string | null };

export type ValidateGraphMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  graphId: Scalars['String']['input'];
}>;


export type ValidateGraphMutation = { __typename?: 'Mutation', validateGraph?: Array<{ __typename?: 'GraphValidationError', node?: string | null, input?: string | null, errorMessage?: string | null, type?: string | null } | null> | null };

export type SaveGraphMutationVariables = Exact<{
  graphId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
  graph?: InputMaybe<Scalars['String']['input']>;
}>;


export type SaveGraphMutation = { __typename?: 'Mutation', editGraph?: boolean | null };

export type CreateGraphMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  channelId: Scalars['String']['input'];
  graph: Scalars['String']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  staged?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateGraphMutation = { __typename?: 'Mutation', createGraph: string };

export type CreatePreviewMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  graphId: Scalars['String']['input'];
}>;


export type CreatePreviewMutation = { __typename?: 'Mutation', createPreview: string };

export type DeletePreviewMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  previewId: Scalars['String']['input'];
}>;


export type DeletePreviewMutation = { __typename?: 'Mutation', deletePreview?: boolean | null };

export type GetPreviewQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  previewId: Scalars['String']['input'];
}>;


export type GetPreviewQuery = { __typename?: 'Query', getPreview?: { __typename?: 'Preview', status?: string | null, previewId: string, thumbnail?: string | null, updatedAt?: string | null, workspaceId: string } | null };

export type MarkPreviewShownMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  graphId: Scalars['String']['input'];
  showPreview?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type MarkPreviewShownMutation = { __typename?: 'Mutation', editGraph?: boolean | null };

export type GetGraphLastUpdatedQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  graphId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetGraphLastUpdatedQuery = { __typename?: 'Query', getGraphs?: Array<{ __typename?: 'Graph', updatedAt?: string | null } | null> | null };

export type GetChannelSchemaQueryVariables = Exact<{
  channelId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetChannelSchemaQuery = { __typename?: 'Query', getChannelSchema: string };

export type GetPreviewLogQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  previewId: Scalars['String']['input'];
}>;


export type GetPreviewLogQuery = { __typename?: 'Query', getPreviewLog?: { __typename?: 'PreviewLog', log?: string | null, previewId: string, status?: string | null } | null };

export type GetJobsQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetJobsQuery = { __typename?: 'Query', getDatasetJobs?: Array<{ __typename?: 'DatasetJob', name?: string | null, datasetId?: string | null, status?: string | null, priority?: number | null, graphId?: string | null, channelId?: string | null, workspaceId?: string | null, averageRuntime?: string | null, instancesQueued?: number | null, instancesRunning?: number | null, instancesStarting?: number | null, runsSuccess?: number | null, runsTimeout?: number | null, seed?: number | null, runsRunning?: number | null, runsFailed?: number | null, runsCancelled?: number | null, runs?: number | null, runsStarting?: number | null, runsQueued?: number | null, updatedAt?: string | null, estimatedEndAt?: string | null, createdBy?: string | null, createdAt?: string | null, channel?: string | null } | null> | null };

export type GetStagedGraphsQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  staged?: InputMaybe<Scalars['Boolean']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetStagedGraphsQuery = { __typename?: 'Query', getGraphs?: Array<{ __typename?: 'Graph', channel: string, createdAt?: string | null, createdBy?: string | null, description?: string | null, graphId: string, name: string, thumbnail?: string | null, workspaceId: string, updatedAt?: string | null } | null> | null };

export type CreateDatasetMutationVariables = Exact<{
  graphId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  runs?: InputMaybe<Scalars['Int']['input']>;
  workspaceId: Scalars['String']['input'];
  seed?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateDatasetMutation = { __typename?: 'Mutation', createDataset?: string | null };

export type ClearWorkspaceJobsMutationVariables = Exact<{
  workspaceId?: InputMaybe<Scalars['String']['input']>;
}>;


export type ClearWorkspaceJobsMutation = { __typename?: 'Mutation', clearDatasetJobs?: boolean | null };

export type HideDatasetMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  datasetId: Scalars['String']['input'];
  showJob?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type HideDatasetMutation = { __typename?: 'Mutation', editDataset?: boolean | null };

export type GetRecentJobsQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
}>;


export type GetRecentJobsQuery = { __typename?: 'Query', getDatasetJobs?: Array<{ __typename?: 'DatasetJob', createdAt?: string | null, datasetId?: string | null, estimatedEndAt?: string | null, name?: string | null, status?: string | null } | null> | null };

export type GetRecentGraphsQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  staged?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetRecentGraphsQuery = { __typename?: 'Query', getGraphs?: Array<{ __typename?: 'Graph', channel: string, channelId: string, createdAt?: string | null, createdBy?: string | null, description?: string | null, graphId: string, name: string, thumbnail?: string | null, updatedAt?: string | null, workspaceId: string } | null> | null };

export type GetRecentDatasetsQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
}>;


export type GetRecentDatasetsQuery = { __typename?: 'Query', getDatasets?: Array<{ __typename?: 'Dataset', createdAt?: string | null, datasetId?: string | null, name: string, runs?: number | null } | null> | null };

export type EditGanDatasetMutationVariables = Exact<{
  datasetId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type EditGanDatasetMutation = { __typename?: 'Mutation', editDataset?: boolean | null };

export type EditDatasetMutationVariables = Exact<{
  datasetId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type EditDatasetMutation = { __typename?: 'Mutation', editDataset?: boolean | null };

export type GetDatasetDetailsAnalyticsQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  datasetId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetDatasetDetailsAnalyticsQuery = { __typename?: 'Query', getAnalytics: Array<{ __typename?: 'Analytics', analyticsId: string, datasetId: string, status: string, type: string, workspaceId: string } | null> };

export type GetAnnotationsQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  datasetId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAnnotationsQuery = { __typename?: 'Query', getAnnotations: Array<{ __typename?: 'Annotation', annotationId: string, datasetId: string, format: string, map: string, status: string, updatedAt?: string | null, workspaceId: string } | null> };

export type GetDatasetDetailsGanDatasetsQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  datasetId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetDatasetDetailsGanDatasetsQuery = { __typename?: 'Query', getGANDatasets: Array<{ __typename?: 'GANDataset', datasetId: string, description?: string | null, model: string, name?: string | null, status: string, updatedAt: string, workspaceId: string } | null> };

export type GetDatasetDetailsUmapsQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  datasetId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetDatasetDetailsUmapsQuery = { __typename?: 'Query', getUMAPs: Array<{ __typename?: 'UMAP', name: string, status: string, umapId: string } | null> };

export type GetDatasetsQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetDatasetsQuery = { __typename?: 'Query', getDatasets?: Array<{ __typename?: 'Dataset', channel?: string | null, createdAt?: string | null, createdBy?: string | null, datasetId?: string | null, description?: string | null, files?: number | null, graphId?: string | null, name: string, runs?: number | null, seed?: string | null, size?: number | null, status?: string | null } | null> | null };


export const UploadDatasetV2Document = gql`
    mutation UploadDatasetV2($workspaceId: String!, $name: String!, $size: Float!, $description: String) {
  uploadDatasetV2(
    workspaceId: $workspaceId
    name: $name
    size: $size
    description: $description
  ) {
    datasetId
    key
    partSize
    uploadId
    urls
  }
}
    `;
export type UploadDatasetV2MutationFn = Apollo.MutationFunction<UploadDatasetV2Mutation, UploadDatasetV2MutationVariables>;

/**
 * __useUploadDatasetV2Mutation__
 *
 * To run a mutation, you first call `useUploadDatasetV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDatasetV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDatasetV2Mutation, { data, loading, error }] = useUploadDatasetV2Mutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      name: // value for 'name'
 *      size: // value for 'size'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUploadDatasetV2Mutation(baseOptions?: Apollo.MutationHookOptions<UploadDatasetV2Mutation, UploadDatasetV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadDatasetV2Mutation, UploadDatasetV2MutationVariables>(UploadDatasetV2Document, options);
      }
export type UploadDatasetV2MutationHookResult = ReturnType<typeof useUploadDatasetV2Mutation>;
export type UploadDatasetV2MutationResult = Apollo.MutationResult<UploadDatasetV2Mutation>;
export type UploadDatasetV2MutationOptions = Apollo.BaseMutationOptions<UploadDatasetV2Mutation, UploadDatasetV2MutationVariables>;
export const UploadDatasetV2FinalizerDocument = gql`
    mutation UploadDatasetV2Finalizer($workspaceId: String!, $key: String!, $uploadId: String, $parts: [MultipartInput]) {
  uploadDatasetV2Finalizer(
    workspaceId: $workspaceId
    uploadId: $uploadId
    key: $key
    parts: $parts
  )
}
    `;
export type UploadDatasetV2FinalizerMutationFn = Apollo.MutationFunction<UploadDatasetV2FinalizerMutation, UploadDatasetV2FinalizerMutationVariables>;

/**
 * __useUploadDatasetV2FinalizerMutation__
 *
 * To run a mutation, you first call `useUploadDatasetV2FinalizerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDatasetV2FinalizerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDatasetV2FinalizerMutation, { data, loading, error }] = useUploadDatasetV2FinalizerMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      key: // value for 'key'
 *      uploadId: // value for 'uploadId'
 *      parts: // value for 'parts'
 *   },
 * });
 */
export function useUploadDatasetV2FinalizerMutation(baseOptions?: Apollo.MutationHookOptions<UploadDatasetV2FinalizerMutation, UploadDatasetV2FinalizerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadDatasetV2FinalizerMutation, UploadDatasetV2FinalizerMutationVariables>(UploadDatasetV2FinalizerDocument, options);
      }
export type UploadDatasetV2FinalizerMutationHookResult = ReturnType<typeof useUploadDatasetV2FinalizerMutation>;
export type UploadDatasetV2FinalizerMutationResult = Apollo.MutationResult<UploadDatasetV2FinalizerMutation>;
export type UploadDatasetV2FinalizerMutationOptions = Apollo.BaseMutationOptions<UploadDatasetV2FinalizerMutation, UploadDatasetV2FinalizerMutationVariables>;
export const CreateManagedGanv2Document = gql`
    mutation CreateManagedGANV2($organizationId: String!, $name: String!, $size: Float!, $description: String, $flags: String) {
  createManagedGANV2(
    organizationId: $organizationId
    name: $name
    size: $size
    description: $description
    flags: $flags
  ) {
    key
    modelId
    uploadId
    partSize
    urls
  }
}
    `;
export type CreateManagedGanv2MutationFn = Apollo.MutationFunction<CreateManagedGanv2Mutation, CreateManagedGanv2MutationVariables>;

/**
 * __useCreateManagedGanv2Mutation__
 *
 * To run a mutation, you first call `useCreateManagedGanv2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManagedGanv2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManagedGanv2Mutation, { data, loading, error }] = useCreateManagedGanv2Mutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *      size: // value for 'size'
 *      description: // value for 'description'
 *      flags: // value for 'flags'
 *   },
 * });
 */
export function useCreateManagedGanv2Mutation(baseOptions?: Apollo.MutationHookOptions<CreateManagedGanv2Mutation, CreateManagedGanv2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManagedGanv2Mutation, CreateManagedGanv2MutationVariables>(CreateManagedGanv2Document, options);
      }
export type CreateManagedGanv2MutationHookResult = ReturnType<typeof useCreateManagedGanv2Mutation>;
export type CreateManagedGanv2MutationResult = Apollo.MutationResult<CreateManagedGanv2Mutation>;
export type CreateManagedGanv2MutationOptions = Apollo.BaseMutationOptions<CreateManagedGanv2Mutation, CreateManagedGanv2MutationVariables>;
export const CreateManagedGanv2FinalizerDocument = gql`
    mutation CreateManagedGANV2Finalizer($organizationId: String!, $key: String!, $uploadId: String, $parts: [MultipartInput]) {
  createManagedGANV2Finalizer(
    organizationId: $organizationId
    key: $key
    uploadId: $uploadId
    parts: $parts
  )
}
    `;
export type CreateManagedGanv2FinalizerMutationFn = Apollo.MutationFunction<CreateManagedGanv2FinalizerMutation, CreateManagedGanv2FinalizerMutationVariables>;

/**
 * __useCreateManagedGanv2FinalizerMutation__
 *
 * To run a mutation, you first call `useCreateManagedGanv2FinalizerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManagedGanv2FinalizerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManagedGanv2FinalizerMutation, { data, loading, error }] = useCreateManagedGanv2FinalizerMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      key: // value for 'key'
 *      uploadId: // value for 'uploadId'
 *      parts: // value for 'parts'
 *   },
 * });
 */
export function useCreateManagedGanv2FinalizerMutation(baseOptions?: Apollo.MutationHookOptions<CreateManagedGanv2FinalizerMutation, CreateManagedGanv2FinalizerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManagedGanv2FinalizerMutation, CreateManagedGanv2FinalizerMutationVariables>(CreateManagedGanv2FinalizerDocument, options);
      }
export type CreateManagedGanv2FinalizerMutationHookResult = ReturnType<typeof useCreateManagedGanv2FinalizerMutation>;
export type CreateManagedGanv2FinalizerMutationResult = Apollo.MutationResult<CreateManagedGanv2FinalizerMutation>;
export type CreateManagedGanv2FinalizerMutationOptions = Apollo.BaseMutationOptions<CreateManagedGanv2FinalizerMutation, CreateManagedGanv2FinalizerMutationVariables>;
export const PutVolumeDataV2Document = gql`
    mutation PutVolumeDataV2($volumeId: String!, $key: String!, $size: Float!) {
  putVolumeDataV2(volumeId: $volumeId, key: $key, size: $size) {
    key
    volumeId
    uploadId
    partSize
    urls
  }
}
    `;
export type PutVolumeDataV2MutationFn = Apollo.MutationFunction<PutVolumeDataV2Mutation, PutVolumeDataV2MutationVariables>;

/**
 * __usePutVolumeDataV2Mutation__
 *
 * To run a mutation, you first call `usePutVolumeDataV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutVolumeDataV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putVolumeDataV2Mutation, { data, loading, error }] = usePutVolumeDataV2Mutation({
 *   variables: {
 *      volumeId: // value for 'volumeId'
 *      key: // value for 'key'
 *      size: // value for 'size'
 *   },
 * });
 */
export function usePutVolumeDataV2Mutation(baseOptions?: Apollo.MutationHookOptions<PutVolumeDataV2Mutation, PutVolumeDataV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PutVolumeDataV2Mutation, PutVolumeDataV2MutationVariables>(PutVolumeDataV2Document, options);
      }
export type PutVolumeDataV2MutationHookResult = ReturnType<typeof usePutVolumeDataV2Mutation>;
export type PutVolumeDataV2MutationResult = Apollo.MutationResult<PutVolumeDataV2Mutation>;
export type PutVolumeDataV2MutationOptions = Apollo.BaseMutationOptions<PutVolumeDataV2Mutation, PutVolumeDataV2MutationVariables>;
export const PutVolumeDataV2FinalizerDocument = gql`
    mutation PutVolumeDataV2Finalizer($volumeId: String!, $key: String!, $uploadId: String, $parts: [MultipartInput]) {
  putVolumeDataV2Finalizer(
    volumeId: $volumeId
    key: $key
    uploadId: $uploadId
    parts: $parts
  )
}
    `;
export type PutVolumeDataV2FinalizerMutationFn = Apollo.MutationFunction<PutVolumeDataV2FinalizerMutation, PutVolumeDataV2FinalizerMutationVariables>;

/**
 * __usePutVolumeDataV2FinalizerMutation__
 *
 * To run a mutation, you first call `usePutVolumeDataV2FinalizerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutVolumeDataV2FinalizerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putVolumeDataV2FinalizerMutation, { data, loading, error }] = usePutVolumeDataV2FinalizerMutation({
 *   variables: {
 *      volumeId: // value for 'volumeId'
 *      key: // value for 'key'
 *      uploadId: // value for 'uploadId'
 *      parts: // value for 'parts'
 *   },
 * });
 */
export function usePutVolumeDataV2FinalizerMutation(baseOptions?: Apollo.MutationHookOptions<PutVolumeDataV2FinalizerMutation, PutVolumeDataV2FinalizerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PutVolumeDataV2FinalizerMutation, PutVolumeDataV2FinalizerMutationVariables>(PutVolumeDataV2FinalizerDocument, options);
      }
export type PutVolumeDataV2FinalizerMutationHookResult = ReturnType<typeof usePutVolumeDataV2FinalizerMutation>;
export type PutVolumeDataV2FinalizerMutationResult = Apollo.MutationResult<PutVolumeDataV2FinalizerMutation>;
export type PutVolumeDataV2FinalizerMutationOptions = Apollo.BaseMutationOptions<PutVolumeDataV2FinalizerMutation, PutVolumeDataV2FinalizerMutationVariables>;
export const CreateAnalyticsDocument = gql`
    mutation createAnalytics($workspaceId: String!, $type: String!, $datasetId: String!) {
  createAnalytics(workspaceId: $workspaceId, datasetId: $datasetId, type: $type)
}
    `;
export type CreateAnalyticsMutationFn = Apollo.MutationFunction<CreateAnalyticsMutation, CreateAnalyticsMutationVariables>;

/**
 * __useCreateAnalyticsMutation__
 *
 * To run a mutation, you first call `useCreateAnalyticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnalyticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnalyticsMutation, { data, loading, error }] = useCreateAnalyticsMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      type: // value for 'type'
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useCreateAnalyticsMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnalyticsMutation, CreateAnalyticsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnalyticsMutation, CreateAnalyticsMutationVariables>(CreateAnalyticsDocument, options);
      }
export type CreateAnalyticsMutationHookResult = ReturnType<typeof useCreateAnalyticsMutation>;
export type CreateAnalyticsMutationResult = Apollo.MutationResult<CreateAnalyticsMutation>;
export type CreateAnalyticsMutationOptions = Apollo.BaseMutationOptions<CreateAnalyticsMutation, CreateAnalyticsMutationVariables>;
export const DeleteAnalyticsDocument = gql`
    mutation deleteAnalytics($workspaceId: String!, $analyticsId: String!) {
  deleteAnalytics(workspaceId: $workspaceId, analyticsId: $analyticsId)
}
    `;
export type DeleteAnalyticsMutationFn = Apollo.MutationFunction<DeleteAnalyticsMutation, DeleteAnalyticsMutationVariables>;

/**
 * __useDeleteAnalyticsMutation__
 *
 * To run a mutation, you first call `useDeleteAnalyticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnalyticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnalyticsMutation, { data, loading, error }] = useDeleteAnalyticsMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      analyticsId: // value for 'analyticsId'
 *   },
 * });
 */
export function useDeleteAnalyticsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAnalyticsMutation, DeleteAnalyticsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAnalyticsMutation, DeleteAnalyticsMutationVariables>(DeleteAnalyticsDocument, options);
      }
export type DeleteAnalyticsMutationHookResult = ReturnType<typeof useDeleteAnalyticsMutation>;
export type DeleteAnalyticsMutationResult = Apollo.MutationResult<DeleteAnalyticsMutation>;
export type DeleteAnalyticsMutationOptions = Apollo.BaseMutationOptions<DeleteAnalyticsMutation, DeleteAnalyticsMutationVariables>;
export const GetAnalyticsDocument = gql`
    query getAnalytics($workspaceId: String!, $analyticsId: String!) {
  getAnalytics(workspaceId: $workspaceId, analyticsId: $analyticsId) {
    result
  }
}
    `;

/**
 * __useGetAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyticsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      analyticsId: // value for 'analyticsId'
 *   },
 * });
 */
export function useGetAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GetAnalyticsQuery, GetAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnalyticsQuery, GetAnalyticsQueryVariables>(GetAnalyticsDocument, options);
      }
export function useGetAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnalyticsQuery, GetAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnalyticsQuery, GetAnalyticsQueryVariables>(GetAnalyticsDocument, options);
        }
export type GetAnalyticsQueryHookResult = ReturnType<typeof useGetAnalyticsQuery>;
export type GetAnalyticsLazyQueryHookResult = ReturnType<typeof useGetAnalyticsLazyQuery>;
export type GetAnalyticsQueryResult = Apollo.QueryResult<GetAnalyticsQuery, GetAnalyticsQueryVariables>;
export const GetAnalyticsTypesDocument = gql`
    query getAnalyticsTypes {
  getAnalyticsTypes
}
    `;

/**
 * __useGetAnalyticsTypesQuery__
 *
 * To run a query within a React component, call `useGetAnalyticsTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyticsTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyticsTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAnalyticsTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetAnalyticsTypesQuery, GetAnalyticsTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnalyticsTypesQuery, GetAnalyticsTypesQueryVariables>(GetAnalyticsTypesDocument, options);
      }
export function useGetAnalyticsTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnalyticsTypesQuery, GetAnalyticsTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnalyticsTypesQuery, GetAnalyticsTypesQueryVariables>(GetAnalyticsTypesDocument, options);
        }
export type GetAnalyticsTypesQueryHookResult = ReturnType<typeof useGetAnalyticsTypesQuery>;
export type GetAnalyticsTypesLazyQueryHookResult = ReturnType<typeof useGetAnalyticsTypesLazyQuery>;
export type GetAnalyticsTypesQueryResult = Apollo.QueryResult<GetAnalyticsTypesQuery, GetAnalyticsTypesQueryVariables>;
export const GetAnalyticsLogDocument = gql`
    query getAnalyticsLog($workspaceId: String!, $analyticsId: String!) {
  getAnalyticsLog(workspaceId: $workspaceId, analyticsId: $analyticsId) {
    log
  }
}
    `;

/**
 * __useGetAnalyticsLogQuery__
 *
 * To run a query within a React component, call `useGetAnalyticsLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyticsLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyticsLogQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      analyticsId: // value for 'analyticsId'
 *   },
 * });
 */
export function useGetAnalyticsLogQuery(baseOptions: Apollo.QueryHookOptions<GetAnalyticsLogQuery, GetAnalyticsLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnalyticsLogQuery, GetAnalyticsLogQueryVariables>(GetAnalyticsLogDocument, options);
      }
export function useGetAnalyticsLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnalyticsLogQuery, GetAnalyticsLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnalyticsLogQuery, GetAnalyticsLogQueryVariables>(GetAnalyticsLogDocument, options);
        }
export type GetAnalyticsLogQueryHookResult = ReturnType<typeof useGetAnalyticsLogQuery>;
export type GetAnalyticsLogLazyQueryHookResult = ReturnType<typeof useGetAnalyticsLogLazyQuery>;
export type GetAnalyticsLogQueryResult = Apollo.QueryResult<GetAnalyticsLogQuery, GetAnalyticsLogQueryVariables>;
export const CreateAnnotationDocument = gql`
    mutation createAnnotation($workspaceId: String!, $datasetId: String!, $format: String!, $mapId: String!) {
  createAnnotation(
    workspaceId: $workspaceId
    datasetId: $datasetId
    format: $format
    mapId: $mapId
  )
}
    `;
export type CreateAnnotationMutationFn = Apollo.MutationFunction<CreateAnnotationMutation, CreateAnnotationMutationVariables>;

/**
 * __useCreateAnnotationMutation__
 *
 * To run a mutation, you first call `useCreateAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnnotationMutation, { data, loading, error }] = useCreateAnnotationMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      datasetId: // value for 'datasetId'
 *      format: // value for 'format'
 *      mapId: // value for 'mapId'
 *   },
 * });
 */
export function useCreateAnnotationMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnnotationMutation, CreateAnnotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnnotationMutation, CreateAnnotationMutationVariables>(CreateAnnotationDocument, options);
      }
export type CreateAnnotationMutationHookResult = ReturnType<typeof useCreateAnnotationMutation>;
export type CreateAnnotationMutationResult = Apollo.MutationResult<CreateAnnotationMutation>;
export type CreateAnnotationMutationOptions = Apollo.BaseMutationOptions<CreateAnnotationMutation, CreateAnnotationMutationVariables>;
export const DownloadAnnotationDocument = gql`
    mutation downloadAnnotation($annotationId: String!, $workspaceId: String!) {
  downloadAnnotation(annotationId: $annotationId, workspaceId: $workspaceId)
}
    `;
export type DownloadAnnotationMutationFn = Apollo.MutationFunction<DownloadAnnotationMutation, DownloadAnnotationMutationVariables>;

/**
 * __useDownloadAnnotationMutation__
 *
 * To run a mutation, you first call `useDownloadAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadAnnotationMutation, { data, loading, error }] = useDownloadAnnotationMutation({
 *   variables: {
 *      annotationId: // value for 'annotationId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useDownloadAnnotationMutation(baseOptions?: Apollo.MutationHookOptions<DownloadAnnotationMutation, DownloadAnnotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadAnnotationMutation, DownloadAnnotationMutationVariables>(DownloadAnnotationDocument, options);
      }
export type DownloadAnnotationMutationHookResult = ReturnType<typeof useDownloadAnnotationMutation>;
export type DownloadAnnotationMutationResult = Apollo.MutationResult<DownloadAnnotationMutation>;
export type DownloadAnnotationMutationOptions = Apollo.BaseMutationOptions<DownloadAnnotationMutation, DownloadAnnotationMutationVariables>;
export const DeleteAnnotationDocument = gql`
    mutation deleteAnnotation($annotationId: String!, $workspaceId: String!) {
  deleteAnnotation(annotationId: $annotationId, workspaceId: $workspaceId)
}
    `;
export type DeleteAnnotationMutationFn = Apollo.MutationFunction<DeleteAnnotationMutation, DeleteAnnotationMutationVariables>;

/**
 * __useDeleteAnnotationMutation__
 *
 * To run a mutation, you first call `useDeleteAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnnotationMutation, { data, loading, error }] = useDeleteAnnotationMutation({
 *   variables: {
 *      annotationId: // value for 'annotationId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useDeleteAnnotationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAnnotationMutation, DeleteAnnotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAnnotationMutation, DeleteAnnotationMutationVariables>(DeleteAnnotationDocument, options);
      }
export type DeleteAnnotationMutationHookResult = ReturnType<typeof useDeleteAnnotationMutation>;
export type DeleteAnnotationMutationResult = Apollo.MutationResult<DeleteAnnotationMutation>;
export type DeleteAnnotationMutationOptions = Apollo.BaseMutationOptions<DeleteAnnotationMutation, DeleteAnnotationMutationVariables>;
export const GetAnnotationFormatsDocument = gql`
    query getAnnotationFormats {
  getAnnotationFormats
}
    `;

/**
 * __useGetAnnotationFormatsQuery__
 *
 * To run a query within a React component, call `useGetAnnotationFormatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnotationFormatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnotationFormatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAnnotationFormatsQuery(baseOptions?: Apollo.QueryHookOptions<GetAnnotationFormatsQuery, GetAnnotationFormatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnnotationFormatsQuery, GetAnnotationFormatsQueryVariables>(GetAnnotationFormatsDocument, options);
      }
export function useGetAnnotationFormatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnnotationFormatsQuery, GetAnnotationFormatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnnotationFormatsQuery, GetAnnotationFormatsQueryVariables>(GetAnnotationFormatsDocument, options);
        }
export type GetAnnotationFormatsQueryHookResult = ReturnType<typeof useGetAnnotationFormatsQuery>;
export type GetAnnotationFormatsLazyQueryHookResult = ReturnType<typeof useGetAnnotationFormatsLazyQuery>;
export type GetAnnotationFormatsQueryResult = Apollo.QueryResult<GetAnnotationFormatsQuery, GetAnnotationFormatsQueryVariables>;
export const GetAnnotationMapsDocument = gql`
    query getAnnotationMaps($organizationId: String, $workspaceId: String, $mapId: String, $cursor: String, $limit: Int) {
  getAnnotationMaps(
    organizationId: $organizationId
    workspaceId: $workspaceId
    mapId: $mapId
    cursor: $cursor
    limit: $limit
  ) {
    description
    mapId
    name
    organization
    createdAt
    organizationId
    updatedAt
  }
}
    `;

/**
 * __useGetAnnotationMapsQuery__
 *
 * To run a query within a React component, call `useGetAnnotationMapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnotationMapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnotationMapsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      workspaceId: // value for 'workspaceId'
 *      mapId: // value for 'mapId'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAnnotationMapsQuery(baseOptions?: Apollo.QueryHookOptions<GetAnnotationMapsQuery, GetAnnotationMapsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnnotationMapsQuery, GetAnnotationMapsQueryVariables>(GetAnnotationMapsDocument, options);
      }
export function useGetAnnotationMapsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnnotationMapsQuery, GetAnnotationMapsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnnotationMapsQuery, GetAnnotationMapsQueryVariables>(GetAnnotationMapsDocument, options);
        }
export type GetAnnotationMapsQueryHookResult = ReturnType<typeof useGetAnnotationMapsQuery>;
export type GetAnnotationMapsLazyQueryHookResult = ReturnType<typeof useGetAnnotationMapsLazyQuery>;
export type GetAnnotationMapsQueryResult = Apollo.QueryResult<GetAnnotationMapsQuery, GetAnnotationMapsQueryVariables>;
export const GetAnnotationLogDocument = gql`
    query getAnnotationLog($workspaceId: String!, $annotationId: String!) {
  getAnnotationLog(workspaceId: $workspaceId, annotationId: $annotationId) {
    log
  }
}
    `;

/**
 * __useGetAnnotationLogQuery__
 *
 * To run a query within a React component, call `useGetAnnotationLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnotationLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnotationLogQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      annotationId: // value for 'annotationId'
 *   },
 * });
 */
export function useGetAnnotationLogQuery(baseOptions: Apollo.QueryHookOptions<GetAnnotationLogQuery, GetAnnotationLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnnotationLogQuery, GetAnnotationLogQueryVariables>(GetAnnotationLogDocument, options);
      }
export function useGetAnnotationLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnnotationLogQuery, GetAnnotationLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnnotationLogQuery, GetAnnotationLogQueryVariables>(GetAnnotationLogDocument, options);
        }
export type GetAnnotationLogQueryHookResult = ReturnType<typeof useGetAnnotationLogQuery>;
export type GetAnnotationLogLazyQueryHookResult = ReturnType<typeof useGetAnnotationLogLazyQuery>;
export type GetAnnotationLogQueryResult = Apollo.QueryResult<GetAnnotationLogQuery, GetAnnotationLogQueryVariables>;
export const CreateManagedMapDocument = gql`
    mutation createManagedMap($organizationId: String!, $name: String!, $description: String) {
  createManagedMap(
    organizationId: $organizationId
    name: $name
    description: $description
  ) {
    mapId
    url
    fields {
      algorithm
      bucket
      credential
      date
      key
      policy
      signature
      token
    }
  }
}
    `;
export type CreateManagedMapMutationFn = Apollo.MutationFunction<CreateManagedMapMutation, CreateManagedMapMutationVariables>;

/**
 * __useCreateManagedMapMutation__
 *
 * To run a mutation, you first call `useCreateManagedMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManagedMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManagedMapMutation, { data, loading, error }] = useCreateManagedMapMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateManagedMapMutation(baseOptions?: Apollo.MutationHookOptions<CreateManagedMapMutation, CreateManagedMapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManagedMapMutation, CreateManagedMapMutationVariables>(CreateManagedMapDocument, options);
      }
export type CreateManagedMapMutationHookResult = ReturnType<typeof useCreateManagedMapMutation>;
export type CreateManagedMapMutationResult = Apollo.MutationResult<CreateManagedMapMutation>;
export type CreateManagedMapMutationOptions = Apollo.BaseMutationOptions<CreateManagedMapMutation, CreateManagedMapMutationVariables>;
export const DeleteManagedMapDocument = gql`
    mutation deleteManagedMap($mapId: String!) {
  deleteManagedMap(mapId: $mapId)
}
    `;
export type DeleteManagedMapMutationFn = Apollo.MutationFunction<DeleteManagedMapMutation, DeleteManagedMapMutationVariables>;

/**
 * __useDeleteManagedMapMutation__
 *
 * To run a mutation, you first call `useDeleteManagedMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedMapMutation, { data, loading, error }] = useDeleteManagedMapMutation({
 *   variables: {
 *      mapId: // value for 'mapId'
 *   },
 * });
 */
export function useDeleteManagedMapMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedMapMutation, DeleteManagedMapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedMapMutation, DeleteManagedMapMutationVariables>(DeleteManagedMapDocument, options);
      }
export type DeleteManagedMapMutationHookResult = ReturnType<typeof useDeleteManagedMapMutation>;
export type DeleteManagedMapMutationResult = Apollo.MutationResult<DeleteManagedMapMutation>;
export type DeleteManagedMapMutationOptions = Apollo.BaseMutationOptions<DeleteManagedMapMutation, DeleteManagedMapMutationVariables>;
export const EditManagedMapDocument = gql`
    mutation editManagedMap($mapId: String!, $name: String!, $description: String) {
  editManagedMap(mapId: $mapId, name: $name, description: $description)
}
    `;
export type EditManagedMapMutationFn = Apollo.MutationFunction<EditManagedMapMutation, EditManagedMapMutationVariables>;

/**
 * __useEditManagedMapMutation__
 *
 * To run a mutation, you first call `useEditManagedMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditManagedMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editManagedMapMutation, { data, loading, error }] = useEditManagedMapMutation({
 *   variables: {
 *      mapId: // value for 'mapId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useEditManagedMapMutation(baseOptions?: Apollo.MutationHookOptions<EditManagedMapMutation, EditManagedMapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditManagedMapMutation, EditManagedMapMutationVariables>(EditManagedMapDocument, options);
      }
export type EditManagedMapMutationHookResult = ReturnType<typeof useEditManagedMapMutation>;
export type EditManagedMapMutationResult = Apollo.MutationResult<EditManagedMapMutation>;
export type EditManagedMapMutationOptions = Apollo.BaseMutationOptions<EditManagedMapMutation, EditManagedMapMutationVariables>;
export const GetChannelsDocument = gql`
    query getChannels($organizationId: String, $channelId: String, $workspaceId: String, $cursor: String, $limit: Int) {
  getChannels(
    organizationId: $organizationId
    channelId: $channelId
    workspaceId: $workspaceId
    cursor: $cursor
    limit: $limit
  ) {
    channelId
    name
    description
    documentation
    organization
    organizationId
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetChannelsQuery__
 *
 * To run a query within a React component, call `useGetChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      channelId: // value for 'channelId'
 *      workspaceId: // value for 'workspaceId'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetChannelsQuery(baseOptions?: Apollo.QueryHookOptions<GetChannelsQuery, GetChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelsQuery, GetChannelsQueryVariables>(GetChannelsDocument, options);
      }
export function useGetChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelsQuery, GetChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelsQuery, GetChannelsQueryVariables>(GetChannelsDocument, options);
        }
export type GetChannelsQueryHookResult = ReturnType<typeof useGetChannelsQuery>;
export type GetChannelsLazyQueryHookResult = ReturnType<typeof useGetChannelsLazyQuery>;
export type GetChannelsQueryResult = Apollo.QueryResult<GetChannelsQuery, GetChannelsQueryVariables>;
export const CreateFeedbackDocument = gql`
    mutation createFeedback($summary: String!, $description: String!, $files: [String]!) {
  createFeedback(summary: $summary, description: $description, files: $files) {
    feedbackId
    uploads {
      key
      url
      fields {
        key
        bucket
        algorithm
        credential
        date
        token
        policy
        signature
      }
    }
  }
}
    `;
export type CreateFeedbackMutationFn = Apollo.MutationFunction<CreateFeedbackMutation, CreateFeedbackMutationVariables>;

/**
 * __useCreateFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackMutation, { data, loading, error }] = useCreateFeedbackMutation({
 *   variables: {
 *      summary: // value for 'summary'
 *      description: // value for 'description'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useCreateFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeedbackMutation, CreateFeedbackMutationVariables>(CreateFeedbackDocument, options);
      }
export type CreateFeedbackMutationHookResult = ReturnType<typeof useCreateFeedbackMutation>;
export type CreateFeedbackMutationResult = Apollo.MutationResult<CreateFeedbackMutation>;
export type CreateFeedbackMutationOptions = Apollo.BaseMutationOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>;
export const GetChannelDocumentationDocument = gql`
    query getChannelDocumentation($channelId: String!) {
  getChannelDocumentation(channelId: $channelId) {
    markdown
  }
}
    `;

/**
 * __useGetChannelDocumentationQuery__
 *
 * To run a query within a React component, call `useGetChannelDocumentationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelDocumentationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelDocumentationQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useGetChannelDocumentationQuery(baseOptions: Apollo.QueryHookOptions<GetChannelDocumentationQuery, GetChannelDocumentationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelDocumentationQuery, GetChannelDocumentationQueryVariables>(GetChannelDocumentationDocument, options);
      }
export function useGetChannelDocumentationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelDocumentationQuery, GetChannelDocumentationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelDocumentationQuery, GetChannelDocumentationQueryVariables>(GetChannelDocumentationDocument, options);
        }
export type GetChannelDocumentationQueryHookResult = ReturnType<typeof useGetChannelDocumentationQuery>;
export type GetChannelDocumentationLazyQueryHookResult = ReturnType<typeof useGetChannelDocumentationLazyQuery>;
export type GetChannelDocumentationQueryResult = Apollo.QueryResult<GetChannelDocumentationQuery, GetChannelDocumentationQueryVariables>;
export const GetManagedChannelsDocument = gql`
    query getManagedChannels($organizationId: String!, $channelId: String) {
  getManagedChannels(organizationId: $organizationId, channelId: $channelId) {
    channelId
    createdAt
    createdBy
    description
    instanceType
    interfaceVersion
    name
    organizationId
    organizations {
      name
      organizationId
    }
    timeout
    updatedAt
    updatedBy
    volumes
  }
}
    `;

/**
 * __useGetManagedChannelsQuery__
 *
 * To run a query within a React component, call `useGetManagedChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagedChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagedChannelsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useGetManagedChannelsQuery(baseOptions: Apollo.QueryHookOptions<GetManagedChannelsQuery, GetManagedChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManagedChannelsQuery, GetManagedChannelsQueryVariables>(GetManagedChannelsDocument, options);
      }
export function useGetManagedChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManagedChannelsQuery, GetManagedChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManagedChannelsQuery, GetManagedChannelsQueryVariables>(GetManagedChannelsDocument, options);
        }
export type GetManagedChannelsQueryHookResult = ReturnType<typeof useGetManagedChannelsQuery>;
export type GetManagedChannelsLazyQueryHookResult = ReturnType<typeof useGetManagedChannelsLazyQuery>;
export type GetManagedChannelsQueryResult = Apollo.QueryResult<GetManagedChannelsQuery, GetManagedChannelsQueryVariables>;
export const CreateManagedChannelDocument = gql`
    mutation createManagedChannel($organizationId: String!, $name: String!, $description: String, $volumes: [String], $instance: String, $timeout: Int, $interfaceVersion: Int) {
  createManagedChannel(
    organizationId: $organizationId
    name: $name
    description: $description
    volumes: $volumes
    instance: $instance
    timeout: $timeout
    interfaceVersion: $interfaceVersion
  )
}
    `;
export type CreateManagedChannelMutationFn = Apollo.MutationFunction<CreateManagedChannelMutation, CreateManagedChannelMutationVariables>;

/**
 * __useCreateManagedChannelMutation__
 *
 * To run a mutation, you first call `useCreateManagedChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManagedChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManagedChannelMutation, { data, loading, error }] = useCreateManagedChannelMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      volumes: // value for 'volumes'
 *      instance: // value for 'instance'
 *      timeout: // value for 'timeout'
 *      interfaceVersion: // value for 'interfaceVersion'
 *   },
 * });
 */
export function useCreateManagedChannelMutation(baseOptions?: Apollo.MutationHookOptions<CreateManagedChannelMutation, CreateManagedChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManagedChannelMutation, CreateManagedChannelMutationVariables>(CreateManagedChannelDocument, options);
      }
export type CreateManagedChannelMutationHookResult = ReturnType<typeof useCreateManagedChannelMutation>;
export type CreateManagedChannelMutationResult = Apollo.MutationResult<CreateManagedChannelMutation>;
export type CreateManagedChannelMutationOptions = Apollo.BaseMutationOptions<CreateManagedChannelMutation, CreateManagedChannelMutationVariables>;
export const DeleteManagedChannelDocument = gql`
    mutation deleteManagedChannel($channelId: String!) {
  deleteManagedChannel(channelId: $channelId)
}
    `;
export type DeleteManagedChannelMutationFn = Apollo.MutationFunction<DeleteManagedChannelMutation, DeleteManagedChannelMutationVariables>;

/**
 * __useDeleteManagedChannelMutation__
 *
 * To run a mutation, you first call `useDeleteManagedChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedChannelMutation, { data, loading, error }] = useDeleteManagedChannelMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useDeleteManagedChannelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedChannelMutation, DeleteManagedChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedChannelMutation, DeleteManagedChannelMutationVariables>(DeleteManagedChannelDocument, options);
      }
export type DeleteManagedChannelMutationHookResult = ReturnType<typeof useDeleteManagedChannelMutation>;
export type DeleteManagedChannelMutationResult = Apollo.MutationResult<DeleteManagedChannelMutation>;
export type DeleteManagedChannelMutationOptions = Apollo.BaseMutationOptions<DeleteManagedChannelMutation, DeleteManagedChannelMutationVariables>;
export const RemoveChannelOrganizationDocument = gql`
    mutation removeChannelOrganization($organizationId: String!, $channelId: String!) {
  removeChannelOrganization(
    organizationId: $organizationId
    channelId: $channelId
  )
}
    `;
export type RemoveChannelOrganizationMutationFn = Apollo.MutationFunction<RemoveChannelOrganizationMutation, RemoveChannelOrganizationMutationVariables>;

/**
 * __useRemoveChannelOrganizationMutation__
 *
 * To run a mutation, you first call `useRemoveChannelOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveChannelOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeChannelOrganizationMutation, { data, loading, error }] = useRemoveChannelOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useRemoveChannelOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveChannelOrganizationMutation, RemoveChannelOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveChannelOrganizationMutation, RemoveChannelOrganizationMutationVariables>(RemoveChannelOrganizationDocument, options);
      }
export type RemoveChannelOrganizationMutationHookResult = ReturnType<typeof useRemoveChannelOrganizationMutation>;
export type RemoveChannelOrganizationMutationResult = Apollo.MutationResult<RemoveChannelOrganizationMutation>;
export type RemoveChannelOrganizationMutationOptions = Apollo.BaseMutationOptions<RemoveChannelOrganizationMutation, RemoveChannelOrganizationMutationVariables>;
export const EditManagedChannelDocument = gql`
    mutation editManagedChannel($channelId: String!, $name: String, $description: String, $volumes: [String], $instance: String, $timeout: Int, $status: String, $interfaceVersion: Int) {
  editManagedChannel(
    channelId: $channelId
    name: $name
    description: $description
    volumes: $volumes
    instance: $instance
    timeout: $timeout
    status: $status
    interfaceVersion: $interfaceVersion
  )
}
    `;
export type EditManagedChannelMutationFn = Apollo.MutationFunction<EditManagedChannelMutation, EditManagedChannelMutationVariables>;

/**
 * __useEditManagedChannelMutation__
 *
 * To run a mutation, you first call `useEditManagedChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditManagedChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editManagedChannelMutation, { data, loading, error }] = useEditManagedChannelMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      volumes: // value for 'volumes'
 *      instance: // value for 'instance'
 *      timeout: // value for 'timeout'
 *      status: // value for 'status'
 *      interfaceVersion: // value for 'interfaceVersion'
 *   },
 * });
 */
export function useEditManagedChannelMutation(baseOptions?: Apollo.MutationHookOptions<EditManagedChannelMutation, EditManagedChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditManagedChannelMutation, EditManagedChannelMutationVariables>(EditManagedChannelDocument, options);
      }
export type EditManagedChannelMutationHookResult = ReturnType<typeof useEditManagedChannelMutation>;
export type EditManagedChannelMutationResult = Apollo.MutationResult<EditManagedChannelMutation>;
export type EditManagedChannelMutationOptions = Apollo.BaseMutationOptions<EditManagedChannelMutation, EditManagedChannelMutationVariables>;
export const UploadDatasetDocument = gql`
    mutation uploadDataset($workspaceId: String!, $description: String!, $name: String!) {
  uploadDataset(workspaceId: $workspaceId, name: $name, description: $description) {
    key
    url
    fields {
      key
      bucket
      algorithm
      credential
      date
      token
      policy
      signature
    }
  }
}
    `;
export type UploadDatasetMutationFn = Apollo.MutationFunction<UploadDatasetMutation, UploadDatasetMutationVariables>;

/**
 * __useUploadDatasetMutation__
 *
 * To run a mutation, you first call `useUploadDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDatasetMutation, { data, loading, error }] = useUploadDatasetMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      description: // value for 'description'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUploadDatasetMutation(baseOptions?: Apollo.MutationHookOptions<UploadDatasetMutation, UploadDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadDatasetMutation, UploadDatasetMutationVariables>(UploadDatasetDocument, options);
      }
export type UploadDatasetMutationHookResult = ReturnType<typeof useUploadDatasetMutation>;
export type UploadDatasetMutationResult = Apollo.MutationResult<UploadDatasetMutation>;
export type UploadDatasetMutationOptions = Apollo.BaseMutationOptions<UploadDatasetMutation, UploadDatasetMutationVariables>;
export const DownloadDatasetDocument = gql`
    mutation downloadDataset($datasetId: String!, $workspaceId: String!) {
  downloadDataset(datasetId: $datasetId, workspaceId: $workspaceId)
}
    `;
export type DownloadDatasetMutationFn = Apollo.MutationFunction<DownloadDatasetMutation, DownloadDatasetMutationVariables>;

/**
 * __useDownloadDatasetMutation__
 *
 * To run a mutation, you first call `useDownloadDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadDatasetMutation, { data, loading, error }] = useDownloadDatasetMutation({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useDownloadDatasetMutation(baseOptions?: Apollo.MutationHookOptions<DownloadDatasetMutation, DownloadDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadDatasetMutation, DownloadDatasetMutationVariables>(DownloadDatasetDocument, options);
      }
export type DownloadDatasetMutationHookResult = ReturnType<typeof useDownloadDatasetMutation>;
export type DownloadDatasetMutationResult = Apollo.MutationResult<DownloadDatasetMutation>;
export type DownloadDatasetMutationOptions = Apollo.BaseMutationOptions<DownloadDatasetMutation, DownloadDatasetMutationVariables>;
export const DeleteDatasetDocument = gql`
    mutation deleteDataset($datasetId: String!, $workspaceId: String!) {
  deleteDataset(datasetId: $datasetId, workspaceId: $workspaceId)
}
    `;
export type DeleteDatasetMutationFn = Apollo.MutationFunction<DeleteDatasetMutation, DeleteDatasetMutationVariables>;

/**
 * __useDeleteDatasetMutation__
 *
 * To run a mutation, you first call `useDeleteDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDatasetMutation, { data, loading, error }] = useDeleteDatasetMutation({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useDeleteDatasetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDatasetMutation, DeleteDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDatasetMutation, DeleteDatasetMutationVariables>(DeleteDatasetDocument, options);
      }
export type DeleteDatasetMutationHookResult = ReturnType<typeof useDeleteDatasetMutation>;
export type DeleteDatasetMutationResult = Apollo.MutationResult<DeleteDatasetMutation>;
export type DeleteDatasetMutationOptions = Apollo.BaseMutationOptions<DeleteDatasetMutation, DeleteDatasetMutationVariables>;
export const GetDatasetRunsDocument = gql`
    query getDatasetRuns($workspaceId: String!, $datasetId: String!, $state: String) {
  getDatasetRuns(workspaceId: $workspaceId, datasetId: $datasetId, state: $state) {
    runId
    workspaceId
    datasetId
    channelId
    startTime
    endTime
    state
    run
  }
  getDatasetJobs(workspaceId: $workspaceId, datasetId: $datasetId) {
    datasetId
    name
  }
}
    `;

/**
 * __useGetDatasetRunsQuery__
 *
 * To run a query within a React component, call `useGetDatasetRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasetRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasetRunsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      datasetId: // value for 'datasetId'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useGetDatasetRunsQuery(baseOptions: Apollo.QueryHookOptions<GetDatasetRunsQuery, GetDatasetRunsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatasetRunsQuery, GetDatasetRunsQueryVariables>(GetDatasetRunsDocument, options);
      }
export function useGetDatasetRunsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatasetRunsQuery, GetDatasetRunsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatasetRunsQuery, GetDatasetRunsQueryVariables>(GetDatasetRunsDocument, options);
        }
export type GetDatasetRunsQueryHookResult = ReturnType<typeof useGetDatasetRunsQuery>;
export type GetDatasetRunsLazyQueryHookResult = ReturnType<typeof useGetDatasetRunsLazyQuery>;
export type GetDatasetRunsQueryResult = Apollo.QueryResult<GetDatasetRunsQuery, GetDatasetRunsQueryVariables>;
export const GetDatasetLogDocument = gql`
    query getDatasetLog($workspaceId: String!, $datasetId: String!, $runId: String!) {
  getDatasetLog(workspaceId: $workspaceId, datasetId: $datasetId, runId: $runId) {
    runId
    workspaceId
    datasetId
    channelId
    email
    startTime
    endTime
    state
    run
    log
  }
}
    `;

/**
 * __useGetDatasetLogQuery__
 *
 * To run a query within a React component, call `useGetDatasetLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasetLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasetLogQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      datasetId: // value for 'datasetId'
 *      runId: // value for 'runId'
 *   },
 * });
 */
export function useGetDatasetLogQuery(baseOptions: Apollo.QueryHookOptions<GetDatasetLogQuery, GetDatasetLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatasetLogQuery, GetDatasetLogQueryVariables>(GetDatasetLogDocument, options);
      }
export function useGetDatasetLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatasetLogQuery, GetDatasetLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatasetLogQuery, GetDatasetLogQueryVariables>(GetDatasetLogDocument, options);
        }
export type GetDatasetLogQueryHookResult = ReturnType<typeof useGetDatasetLogQuery>;
export type GetDatasetLogLazyQueryHookResult = ReturnType<typeof useGetDatasetLogLazyQuery>;
export type GetDatasetLogQueryResult = Apollo.QueryResult<GetDatasetLogQuery, GetDatasetLogQueryVariables>;
export const GetDatasetImageDocument = gql`
    query GetDatasetImage($workspaceId: String!, $datasetId: String!, $run: Int!) {
  getDatasetImage(workspaceId: $workspaceId, datasetId: $datasetId, run: $run) {
    data
  }
  getDatasetAnnotation(
    workspaceId: $workspaceId
    datasetId: $datasetId
    run: $run
  ) {
    data
  }
}
    `;

/**
 * __useGetDatasetImageQuery__
 *
 * To run a query within a React component, call `useGetDatasetImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasetImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasetImageQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      datasetId: // value for 'datasetId'
 *      run: // value for 'run'
 *   },
 * });
 */
export function useGetDatasetImageQuery(baseOptions: Apollo.QueryHookOptions<GetDatasetImageQuery, GetDatasetImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatasetImageQuery, GetDatasetImageQueryVariables>(GetDatasetImageDocument, options);
      }
export function useGetDatasetImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatasetImageQuery, GetDatasetImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatasetImageQuery, GetDatasetImageQueryVariables>(GetDatasetImageDocument, options);
        }
export type GetDatasetImageQueryHookResult = ReturnType<typeof useGetDatasetImageQuery>;
export type GetDatasetImageLazyQueryHookResult = ReturnType<typeof useGetDatasetImageLazyQuery>;
export type GetDatasetImageQueryResult = Apollo.QueryResult<GetDatasetImageQuery, GetDatasetImageQueryVariables>;
export const GetDatasetDocument = gql`
    query GetDataset($workspaceId: String!, $datasetId: String) {
  getDatasets(workspaceId: $workspaceId, datasetId: $datasetId) {
    datasetId
    files
    name
  }
}
    `;

/**
 * __useGetDatasetQuery__
 *
 * To run a query within a React component, call `useGetDatasetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasetQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetDatasetQuery(baseOptions: Apollo.QueryHookOptions<GetDatasetQuery, GetDatasetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatasetQuery, GetDatasetQueryVariables>(GetDatasetDocument, options);
      }
export function useGetDatasetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatasetQuery, GetDatasetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatasetQuery, GetDatasetQueryVariables>(GetDatasetDocument, options);
        }
export type GetDatasetQueryHookResult = ReturnType<typeof useGetDatasetQuery>;
export type GetDatasetLazyQueryHookResult = ReturnType<typeof useGetDatasetLazyQuery>;
export type GetDatasetQueryResult = Apollo.QueryResult<GetDatasetQuery, GetDatasetQueryVariables>;
export const GetGanModelsDocument = gql`
    query getGANModels($workspaceId: String, $modelId: String, $organizationId: String, $cursor: String, $limit: Int) {
  getGANModels(
    workspaceId: $workspaceId
    modelId: $modelId
    organizationId: $organizationId
    cursor: $cursor
    limit: $limit
  ) {
    organizationId
    modelId
    description
    flags
    name
    organization
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetGanModelsQuery__
 *
 * To run a query within a React component, call `useGetGanModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGanModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGanModelsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      modelId: // value for 'modelId'
 *      organizationId: // value for 'organizationId'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetGanModelsQuery(baseOptions?: Apollo.QueryHookOptions<GetGanModelsQuery, GetGanModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGanModelsQuery, GetGanModelsQueryVariables>(GetGanModelsDocument, options);
      }
export function useGetGanModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGanModelsQuery, GetGanModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGanModelsQuery, GetGanModelsQueryVariables>(GetGanModelsDocument, options);
        }
export type GetGanModelsQueryHookResult = ReturnType<typeof useGetGanModelsQuery>;
export type GetGanModelsLazyQueryHookResult = ReturnType<typeof useGetGanModelsLazyQuery>;
export type GetGanModelsQueryResult = Apollo.QueryResult<GetGanModelsQuery, GetGanModelsQueryVariables>;
export const CreateGanDatasetDocument = gql`
    mutation createGANDataset($workspaceId: String!, $datasetId: String!, $modelId: String!, $name: String!, $description: String) {
  createGANDataset(
    workspaceId: $workspaceId
    datasetId: $datasetId
    modelId: $modelId
    name: $name
    description: $description
  )
}
    `;
export type CreateGanDatasetMutationFn = Apollo.MutationFunction<CreateGanDatasetMutation, CreateGanDatasetMutationVariables>;

/**
 * __useCreateGanDatasetMutation__
 *
 * To run a mutation, you first call `useCreateGanDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGanDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGanDatasetMutation, { data, loading, error }] = useCreateGanDatasetMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      datasetId: // value for 'datasetId'
 *      modelId: // value for 'modelId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateGanDatasetMutation(baseOptions?: Apollo.MutationHookOptions<CreateGanDatasetMutation, CreateGanDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGanDatasetMutation, CreateGanDatasetMutationVariables>(CreateGanDatasetDocument, options);
      }
export type CreateGanDatasetMutationHookResult = ReturnType<typeof useCreateGanDatasetMutation>;
export type CreateGanDatasetMutationResult = Apollo.MutationResult<CreateGanDatasetMutation>;
export type CreateGanDatasetMutationOptions = Apollo.BaseMutationOptions<CreateGanDatasetMutation, CreateGanDatasetMutationVariables>;
export const DeleteGanDatasetDocument = gql`
    mutation deleteGANDataset($workspaceId: String!, $datasetId: String!) {
  deleteGANDataset(workspaceId: $workspaceId, datasetId: $datasetId)
}
    `;
export type DeleteGanDatasetMutationFn = Apollo.MutationFunction<DeleteGanDatasetMutation, DeleteGanDatasetMutationVariables>;

/**
 * __useDeleteGanDatasetMutation__
 *
 * To run a mutation, you first call `useDeleteGanDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGanDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGanDatasetMutation, { data, loading, error }] = useDeleteGanDatasetMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useDeleteGanDatasetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGanDatasetMutation, DeleteGanDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGanDatasetMutation, DeleteGanDatasetMutationVariables>(DeleteGanDatasetDocument, options);
      }
export type DeleteGanDatasetMutationHookResult = ReturnType<typeof useDeleteGanDatasetMutation>;
export type DeleteGanDatasetMutationResult = Apollo.MutationResult<DeleteGanDatasetMutation>;
export type DeleteGanDatasetMutationOptions = Apollo.BaseMutationOptions<DeleteGanDatasetMutation, DeleteGanDatasetMutationVariables>;
export const GetGanDatasetLogDocument = gql`
    query GetGANDatasetLog($workspaceId: String!, $datasetId: String!) {
  getGANDatasetLog(workspaceId: $workspaceId, datasetId: $datasetId) {
    log
  }
}
    `;

/**
 * __useGetGanDatasetLogQuery__
 *
 * To run a query within a React component, call `useGetGanDatasetLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGanDatasetLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGanDatasetLogQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetGanDatasetLogQuery(baseOptions: Apollo.QueryHookOptions<GetGanDatasetLogQuery, GetGanDatasetLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGanDatasetLogQuery, GetGanDatasetLogQueryVariables>(GetGanDatasetLogDocument, options);
      }
export function useGetGanDatasetLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGanDatasetLogQuery, GetGanDatasetLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGanDatasetLogQuery, GetGanDatasetLogQueryVariables>(GetGanDatasetLogDocument, options);
        }
export type GetGanDatasetLogQueryHookResult = ReturnType<typeof useGetGanDatasetLogQuery>;
export type GetGanDatasetLogLazyQueryHookResult = ReturnType<typeof useGetGanDatasetLogLazyQuery>;
export type GetGanDatasetLogQueryResult = Apollo.QueryResult<GetGanDatasetLogQuery, GetGanDatasetLogQueryVariables>;
export const EditManagedGanDocument = gql`
    mutation editManagedGAN($modelId: String!, $name: String, $description: String, $flags: String) {
  editManagedGAN(
    modelId: $modelId
    name: $name
    description: $description
    flags: $flags
  )
}
    `;
export type EditManagedGanMutationFn = Apollo.MutationFunction<EditManagedGanMutation, EditManagedGanMutationVariables>;

/**
 * __useEditManagedGanMutation__
 *
 * To run a mutation, you first call `useEditManagedGanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditManagedGanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editManagedGanMutation, { data, loading, error }] = useEditManagedGanMutation({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      flags: // value for 'flags'
 *   },
 * });
 */
export function useEditManagedGanMutation(baseOptions?: Apollo.MutationHookOptions<EditManagedGanMutation, EditManagedGanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditManagedGanMutation, EditManagedGanMutationVariables>(EditManagedGanDocument, options);
      }
export type EditManagedGanMutationHookResult = ReturnType<typeof useEditManagedGanMutation>;
export type EditManagedGanMutationResult = Apollo.MutationResult<EditManagedGanMutation>;
export type EditManagedGanMutationOptions = Apollo.BaseMutationOptions<EditManagedGanMutation, EditManagedGanMutationVariables>;
export const DeleteManagedGanDocument = gql`
    mutation deleteManagedGAN($modelId: String!) {
  deleteManagedGAN(modelId: $modelId)
}
    `;
export type DeleteManagedGanMutationFn = Apollo.MutationFunction<DeleteManagedGanMutation, DeleteManagedGanMutationVariables>;

/**
 * __useDeleteManagedGanMutation__
 *
 * To run a mutation, you first call `useDeleteManagedGanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedGanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedGanMutation, { data, loading, error }] = useDeleteManagedGanMutation({
 *   variables: {
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useDeleteManagedGanMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedGanMutation, DeleteManagedGanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedGanMutation, DeleteManagedGanMutationVariables>(DeleteManagedGanDocument, options);
      }
export type DeleteManagedGanMutationHookResult = ReturnType<typeof useDeleteManagedGanMutation>;
export type DeleteManagedGanMutationResult = Apollo.MutationResult<DeleteManagedGanMutation>;
export type DeleteManagedGanMutationOptions = Apollo.BaseMutationOptions<DeleteManagedGanMutation, DeleteManagedGanMutationVariables>;
export const RemoveGanOrganizationDocument = gql`
    mutation removeGANOrganization($organizationId: String!, $modelId: String!) {
  removeGANOrganization(organizationId: $organizationId, modelId: $modelId)
}
    `;
export type RemoveGanOrganizationMutationFn = Apollo.MutationFunction<RemoveGanOrganizationMutation, RemoveGanOrganizationMutationVariables>;

/**
 * __useRemoveGanOrganizationMutation__
 *
 * To run a mutation, you first call `useRemoveGanOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGanOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGanOrganizationMutation, { data, loading, error }] = useRemoveGanOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useRemoveGanOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveGanOrganizationMutation, RemoveGanOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveGanOrganizationMutation, RemoveGanOrganizationMutationVariables>(RemoveGanOrganizationDocument, options);
      }
export type RemoveGanOrganizationMutationHookResult = ReturnType<typeof useRemoveGanOrganizationMutation>;
export type RemoveGanOrganizationMutationResult = Apollo.MutationResult<RemoveGanOrganizationMutation>;
export type RemoveGanOrganizationMutationOptions = Apollo.BaseMutationOptions<RemoveGanOrganizationMutation, RemoveGanOrganizationMutationVariables>;
export const GetInvitationsDocument = gql`
    query getInvitations($organizationId: String!) {
  getInvitations(organizationId: $organizationId) {
    email
    organizationId
    role
    updatedAt
  }
}
    `;

/**
 * __useGetInvitationsQuery__
 *
 * To run a query within a React component, call `useGetInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetInvitationsQuery(baseOptions: Apollo.QueryHookOptions<GetInvitationsQuery, GetInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvitationsQuery, GetInvitationsQueryVariables>(GetInvitationsDocument, options);
      }
export function useGetInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvitationsQuery, GetInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvitationsQuery, GetInvitationsQueryVariables>(GetInvitationsDocument, options);
        }
export type GetInvitationsQueryHookResult = ReturnType<typeof useGetInvitationsQuery>;
export type GetInvitationsLazyQueryHookResult = ReturnType<typeof useGetInvitationsLazyQuery>;
export type GetInvitationsQueryResult = Apollo.QueryResult<GetInvitationsQuery, GetInvitationsQueryVariables>;
export const GetMembersDocument = gql`
    query getMembers($organizationId: String!, $limit: Int, $cursor: String) {
  getMembers(organizationId: $organizationId, limit: $limit, cursor: $cursor) {
    userId
    email
    name
    role
  }
}
    `;

/**
 * __useGetMembersQuery__
 *
 * To run a query within a React component, call `useGetMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetMembersQuery(baseOptions: Apollo.QueryHookOptions<GetMembersQuery, GetMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, options);
      }
export function useGetMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembersQuery, GetMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, options);
        }
export type GetMembersQueryHookResult = ReturnType<typeof useGetMembersQuery>;
export type GetMembersLazyQueryHookResult = ReturnType<typeof useGetMembersLazyQuery>;
export type GetMembersQueryResult = Apollo.QueryResult<GetMembersQuery, GetMembersQueryVariables>;
export const EditMemberDocument = gql`
    mutation editMember($email: String, $name: String) {
  editMember(email: $email, name: $name)
}
    `;
export type EditMemberMutationFn = Apollo.MutationFunction<EditMemberMutation, EditMemberMutationVariables>;

/**
 * __useEditMemberMutation__
 *
 * To run a mutation, you first call `useEditMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMemberMutation, { data, loading, error }] = useEditMemberMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditMemberMutation(baseOptions?: Apollo.MutationHookOptions<EditMemberMutation, EditMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMemberMutation, EditMemberMutationVariables>(EditMemberDocument, options);
      }
export type EditMemberMutationHookResult = ReturnType<typeof useEditMemberMutation>;
export type EditMemberMutationResult = Apollo.MutationResult<EditMemberMutation>;
export type EditMemberMutationOptions = Apollo.BaseMutationOptions<EditMemberMutation, EditMemberMutationVariables>;
export const AddMemberDocument = gql`
    mutation addMember($organizationId: String!, $email: String!, $role: String!) {
  addMember(organizationId: $organizationId, email: $email, role: $role)
}
    `;
export type AddMemberMutationFn = Apollo.MutationFunction<AddMemberMutation, AddMemberMutationVariables>;

/**
 * __useAddMemberMutation__
 *
 * To run a mutation, you first call `useAddMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMemberMutation, { data, loading, error }] = useAddMemberMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      email: // value for 'email'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useAddMemberMutation(baseOptions?: Apollo.MutationHookOptions<AddMemberMutation, AddMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMemberMutation, AddMemberMutationVariables>(AddMemberDocument, options);
      }
export type AddMemberMutationHookResult = ReturnType<typeof useAddMemberMutation>;
export type AddMemberMutationResult = Apollo.MutationResult<AddMemberMutation>;
export type AddMemberMutationOptions = Apollo.BaseMutationOptions<AddMemberMutation, AddMemberMutationVariables>;
export const RemoveMemberDocument = gql`
    mutation removeMember($organizationId: String!, $email: String!) {
  removeMember(organizationId: $organizationId, email: $email)
}
    `;
export type RemoveMemberMutationFn = Apollo.MutationFunction<RemoveMemberMutation, RemoveMemberMutationVariables>;

/**
 * __useRemoveMemberMutation__
 *
 * To run a mutation, you first call `useRemoveMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberMutation, { data, loading, error }] = useRemoveMemberMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRemoveMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMemberMutation, RemoveMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMemberMutation, RemoveMemberMutationVariables>(RemoveMemberDocument, options);
      }
export type RemoveMemberMutationHookResult = ReturnType<typeof useRemoveMemberMutation>;
export type RemoveMemberMutationResult = Apollo.MutationResult<RemoveMemberMutation>;
export type RemoveMemberMutationOptions = Apollo.BaseMutationOptions<RemoveMemberMutation, RemoveMemberMutationVariables>;
export const EditMemberByRoleDocument = gql`
    mutation editMemberByRole($organizationId: String, $email: String!, $role: String!) {
  editMember(organizationId: $organizationId, email: $email, role: $role)
}
    `;
export type EditMemberByRoleMutationFn = Apollo.MutationFunction<EditMemberByRoleMutation, EditMemberByRoleMutationVariables>;

/**
 * __useEditMemberByRoleMutation__
 *
 * To run a mutation, you first call `useEditMemberByRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMemberByRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMemberByRoleMutation, { data, loading, error }] = useEditMemberByRoleMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      email: // value for 'email'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useEditMemberByRoleMutation(baseOptions?: Apollo.MutationHookOptions<EditMemberByRoleMutation, EditMemberByRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMemberByRoleMutation, EditMemberByRoleMutationVariables>(EditMemberByRoleDocument, options);
      }
export type EditMemberByRoleMutationHookResult = ReturnType<typeof useEditMemberByRoleMutation>;
export type EditMemberByRoleMutationResult = Apollo.MutationResult<EditMemberByRoleMutation>;
export type EditMemberByRoleMutationOptions = Apollo.BaseMutationOptions<EditMemberByRoleMutation, EditMemberByRoleMutationVariables>;
export const EditOrganizationDocument = gql`
    mutation editOrganization($organizationId: String!, $name: String!) {
  editOrganization(organizationId: $organizationId, name: $name)
}
    `;
export type EditOrganizationMutationFn = Apollo.MutationFunction<EditOrganizationMutation, EditOrganizationMutationVariables>;

/**
 * __useEditOrganizationMutation__
 *
 * To run a mutation, you first call `useEditOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrganizationMutation, { data, loading, error }] = useEditOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<EditOrganizationMutation, EditOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOrganizationMutation, EditOrganizationMutationVariables>(EditOrganizationDocument, options);
      }
export type EditOrganizationMutationHookResult = ReturnType<typeof useEditOrganizationMutation>;
export type EditOrganizationMutationResult = Apollo.MutationResult<EditOrganizationMutation>;
export type EditOrganizationMutationOptions = Apollo.BaseMutationOptions<EditOrganizationMutation, EditOrganizationMutationVariables>;
export const DeleteOrganizationDocument = gql`
    mutation deleteOrganization($organizationId: String!) {
  deleteOrganization(organizationId: $organizationId)
}
    `;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>(DeleteOrganizationDocument, options);
      }
export type DeleteOrganizationMutationHookResult = ReturnType<typeof useDeleteOrganizationMutation>;
export type DeleteOrganizationMutationResult = Apollo.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;
export const GetOrganizationsDocument = gql`
    query getOrganizations($organizationId: String) {
  getOrganizations(organizationId: $organizationId) {
    name
    role
    organizationId
    stripeCustomerId
    expirationDate
    expired
    plan
  }
}
    `;

/**
 * __useGetOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
      }
export function useGetOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
        }
export type GetOrganizationsQueryHookResult = ReturnType<typeof useGetOrganizationsQuery>;
export type GetOrganizationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationsLazyQuery>;
export type GetOrganizationsQueryResult = Apollo.QueryResult<GetOrganizationsQuery, GetOrganizationsQueryVariables>;
export const GetOrganizationSettingsDocument = gql`
    query getOrganizationSettings($organizationId: String!, $setting: String) {
  getOrganizationSettings(organizationId: $organizationId, setting: $setting) {
    organizationId
    setting
    limit
    usage
    units
  }
}
    `;

/**
 * __useGetOrganizationSettingsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationSettingsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      setting: // value for 'setting'
 *   },
 * });
 */
export function useGetOrganizationSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationSettingsQuery, GetOrganizationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationSettingsQuery, GetOrganizationSettingsQueryVariables>(GetOrganizationSettingsDocument, options);
      }
export function useGetOrganizationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationSettingsQuery, GetOrganizationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationSettingsQuery, GetOrganizationSettingsQueryVariables>(GetOrganizationSettingsDocument, options);
        }
export type GetOrganizationSettingsQueryHookResult = ReturnType<typeof useGetOrganizationSettingsQuery>;
export type GetOrganizationSettingsLazyQueryHookResult = ReturnType<typeof useGetOrganizationSettingsLazyQuery>;
export type GetOrganizationSettingsQueryResult = Apollo.QueryResult<GetOrganizationSettingsQuery, GetOrganizationSettingsQueryVariables>;
export const CreatePaymentIntentDocument = gql`
    mutation createPaymentIntent($amount: Int!, $organizationId: String!) {
  createPaymentIntent(amount: $amount, organizationId: $organizationId)
}
    `;
export type CreatePaymentIntentMutationFn = Apollo.MutationFunction<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>;

/**
 * __useCreatePaymentIntentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentIntentMutation, { data, loading, error }] = useCreatePaymentIntentMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreatePaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>(CreatePaymentIntentDocument, options);
      }
export type CreatePaymentIntentMutationHookResult = ReturnType<typeof useCreatePaymentIntentMutation>;
export type CreatePaymentIntentMutationResult = Apollo.MutationResult<CreatePaymentIntentMutation>;
export type CreatePaymentIntentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>;
export const SaveDefaultPaymentMethodDocument = gql`
    mutation saveDefaultPaymentMethod($organizationId: String!, $paymentMethodId: String!) {
  saveDefaultPaymentMethod(
    paymentMethodId: $paymentMethodId
    organizationId: $organizationId
  )
}
    `;
export type SaveDefaultPaymentMethodMutationFn = Apollo.MutationFunction<SaveDefaultPaymentMethodMutation, SaveDefaultPaymentMethodMutationVariables>;

/**
 * __useSaveDefaultPaymentMethodMutation__
 *
 * To run a mutation, you first call `useSaveDefaultPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDefaultPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDefaultPaymentMethodMutation, { data, loading, error }] = useSaveDefaultPaymentMethodMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useSaveDefaultPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<SaveDefaultPaymentMethodMutation, SaveDefaultPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDefaultPaymentMethodMutation, SaveDefaultPaymentMethodMutationVariables>(SaveDefaultPaymentMethodDocument, options);
      }
export type SaveDefaultPaymentMethodMutationHookResult = ReturnType<typeof useSaveDefaultPaymentMethodMutation>;
export type SaveDefaultPaymentMethodMutationResult = Apollo.MutationResult<SaveDefaultPaymentMethodMutation>;
export type SaveDefaultPaymentMethodMutationOptions = Apollo.BaseMutationOptions<SaveDefaultPaymentMethodMutation, SaveDefaultPaymentMethodMutationVariables>;
export const AttachPaymentMethodToCustomerDocument = gql`
    mutation attachPaymentMethodToCustomer($organizationId: String!, $paymentMethodId: String!) {
  attachPaymentMethodToCustomer(
    organizationId: $organizationId
    paymentMethodId: $paymentMethodId
  )
}
    `;
export type AttachPaymentMethodToCustomerMutationFn = Apollo.MutationFunction<AttachPaymentMethodToCustomerMutation, AttachPaymentMethodToCustomerMutationVariables>;

/**
 * __useAttachPaymentMethodToCustomerMutation__
 *
 * To run a mutation, you first call `useAttachPaymentMethodToCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachPaymentMethodToCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachPaymentMethodToCustomerMutation, { data, loading, error }] = useAttachPaymentMethodToCustomerMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useAttachPaymentMethodToCustomerMutation(baseOptions?: Apollo.MutationHookOptions<AttachPaymentMethodToCustomerMutation, AttachPaymentMethodToCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttachPaymentMethodToCustomerMutation, AttachPaymentMethodToCustomerMutationVariables>(AttachPaymentMethodToCustomerDocument, options);
      }
export type AttachPaymentMethodToCustomerMutationHookResult = ReturnType<typeof useAttachPaymentMethodToCustomerMutation>;
export type AttachPaymentMethodToCustomerMutationResult = Apollo.MutationResult<AttachPaymentMethodToCustomerMutation>;
export type AttachPaymentMethodToCustomerMutationOptions = Apollo.BaseMutationOptions<AttachPaymentMethodToCustomerMutation, AttachPaymentMethodToCustomerMutationVariables>;
export const UpdateOrganizationPlanDocument = gql`
    mutation updateOrganizationPlan($organizationId: String!, $tier: String!) {
  updateOrganizationPlan(organizationId: $organizationId, tier: $tier)
}
    `;
export type UpdateOrganizationPlanMutationFn = Apollo.MutationFunction<UpdateOrganizationPlanMutation, UpdateOrganizationPlanMutationVariables>;

/**
 * __useUpdateOrganizationPlanMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationPlanMutation, { data, loading, error }] = useUpdateOrganizationPlanMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      tier: // value for 'tier'
 *   },
 * });
 */
export function useUpdateOrganizationPlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationPlanMutation, UpdateOrganizationPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationPlanMutation, UpdateOrganizationPlanMutationVariables>(UpdateOrganizationPlanDocument, options);
      }
export type UpdateOrganizationPlanMutationHookResult = ReturnType<typeof useUpdateOrganizationPlanMutation>;
export type UpdateOrganizationPlanMutationResult = Apollo.MutationResult<UpdateOrganizationPlanMutation>;
export type UpdateOrganizationPlanMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationPlanMutation, UpdateOrganizationPlanMutationVariables>;
export const GetBillingHistoryDocument = gql`
    query getBillingHistory($organizationId: String!) {
  getBillingHistory(organizationId: $organizationId) {
    id
    object
    amount
    amount_capturable
    amount_received
    confirmation_method
    created
    currency
    description
    status
  }
}
    `;

/**
 * __useGetBillingHistoryQuery__
 *
 * To run a query within a React component, call `useGetBillingHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingHistoryQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetBillingHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetBillingHistoryQuery, GetBillingHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBillingHistoryQuery, GetBillingHistoryQueryVariables>(GetBillingHistoryDocument, options);
      }
export function useGetBillingHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillingHistoryQuery, GetBillingHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBillingHistoryQuery, GetBillingHistoryQueryVariables>(GetBillingHistoryDocument, options);
        }
export type GetBillingHistoryQueryHookResult = ReturnType<typeof useGetBillingHistoryQuery>;
export type GetBillingHistoryLazyQueryHookResult = ReturnType<typeof useGetBillingHistoryLazyQuery>;
export type GetBillingHistoryQueryResult = Apollo.QueryResult<GetBillingHistoryQuery, GetBillingHistoryQueryVariables>;
export const GetCustomerInformationDocument = gql`
    query getCustomerInformation($organizationId: String!) {
  getCustomerInformation(organizationId: $organizationId) {
    object
    address {
      city
      country
      line1
      line2
      postal_code
      state
    }
    invoice_settings {
      default_payment_method
    }
    email
    name
    phone
  }
}
    `;

/**
 * __useGetCustomerInformationQuery__
 *
 * To run a query within a React component, call `useGetCustomerInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerInformationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetCustomerInformationQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerInformationQuery, GetCustomerInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerInformationQuery, GetCustomerInformationQueryVariables>(GetCustomerInformationDocument, options);
      }
export function useGetCustomerInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerInformationQuery, GetCustomerInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerInformationQuery, GetCustomerInformationQueryVariables>(GetCustomerInformationDocument, options);
        }
export type GetCustomerInformationQueryHookResult = ReturnType<typeof useGetCustomerInformationQuery>;
export type GetCustomerInformationLazyQueryHookResult = ReturnType<typeof useGetCustomerInformationLazyQuery>;
export type GetCustomerInformationQueryResult = Apollo.QueryResult<GetCustomerInformationQuery, GetCustomerInformationQueryVariables>;
export const GetCustomerPaymentMethodsDocument = gql`
    query getCustomerPaymentMethods($organizationId: String!) {
  getCustomerPaymentMethods(organizationId: $organizationId) {
    id
    object
    billing_details {
      address {
        city
        country
        line1
        line2
        state
        postal_code
      }
      email
      name
      phone
    }
    card {
      brand
      country
      exp_month
      exp_year
      fingerprint
      funding
      last4
    }
    metadata {
      organization
    }
    created
    type
  }
}
    `;

/**
 * __useGetCustomerPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetCustomerPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerPaymentMethodsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetCustomerPaymentMethodsQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerPaymentMethodsQuery, GetCustomerPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerPaymentMethodsQuery, GetCustomerPaymentMethodsQueryVariables>(GetCustomerPaymentMethodsDocument, options);
      }
export function useGetCustomerPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerPaymentMethodsQuery, GetCustomerPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerPaymentMethodsQuery, GetCustomerPaymentMethodsQueryVariables>(GetCustomerPaymentMethodsDocument, options);
        }
export type GetCustomerPaymentMethodsQueryHookResult = ReturnType<typeof useGetCustomerPaymentMethodsQuery>;
export type GetCustomerPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetCustomerPaymentMethodsLazyQuery>;
export type GetCustomerPaymentMethodsQueryResult = Apollo.QueryResult<GetCustomerPaymentMethodsQuery, GetCustomerPaymentMethodsQueryVariables>;
export const CreateSubscriptionForUserDocument = gql`
    mutation createSubscriptionForUser($organizationId: String!, $priceId: String!, $paymentId: String!) {
  createSubscriptionForUser(
    organizationId: $organizationId
    priceId: $priceId
    paymentId: $paymentId
  )
}
    `;
export type CreateSubscriptionForUserMutationFn = Apollo.MutationFunction<CreateSubscriptionForUserMutation, CreateSubscriptionForUserMutationVariables>;

/**
 * __useCreateSubscriptionForUserMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionForUserMutation, { data, loading, error }] = useCreateSubscriptionForUserMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      priceId: // value for 'priceId'
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useCreateSubscriptionForUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionForUserMutation, CreateSubscriptionForUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionForUserMutation, CreateSubscriptionForUserMutationVariables>(CreateSubscriptionForUserDocument, options);
      }
export type CreateSubscriptionForUserMutationHookResult = ReturnType<typeof useCreateSubscriptionForUserMutation>;
export type CreateSubscriptionForUserMutationResult = Apollo.MutationResult<CreateSubscriptionForUserMutation>;
export type CreateSubscriptionForUserMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionForUserMutation, CreateSubscriptionForUserMutationVariables>;
export const GetStripeProductsListDocument = gql`
    query getStripeProductsList($organizationId: String!) {
  getStripeProductsList(organizationId: $organizationId) {
    id
    priceId
    object
    active
    billing_scheme
    created
    nickname
    currency
    livemode
    product
    name
    description
    recurring {
      interval
      interval_count
      usage_type
    }
    metadata {
      name
      organizationSeat
      membersPerOrganization
      workspacesPerOrganization
      workspaceGuestsWithReadAndWrite
      channelsPerOrganization
      dataVolumes
      dataVolumeGBLimit
      graphsPerWorkspace
      stagedGraphsPerWorkspace
      datasetsPerWorkspace
      datasetGBPerOrganization
      maxConcurrentComputerInstances
      typeOfAWSInstance
      annotation
      analytics
      UMAP
      domainAdaptation
      limitsOnHours
      downloadBandwidth
    }
    type
    unit_amount
    unit_amount_decimal
  }
}
    `;

/**
 * __useGetStripeProductsListQuery__
 *
 * To run a query within a React component, call `useGetStripeProductsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeProductsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeProductsListQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetStripeProductsListQuery(baseOptions: Apollo.QueryHookOptions<GetStripeProductsListQuery, GetStripeProductsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeProductsListQuery, GetStripeProductsListQueryVariables>(GetStripeProductsListDocument, options);
      }
export function useGetStripeProductsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeProductsListQuery, GetStripeProductsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeProductsListQuery, GetStripeProductsListQueryVariables>(GetStripeProductsListDocument, options);
        }
export type GetStripeProductsListQueryHookResult = ReturnType<typeof useGetStripeProductsListQuery>;
export type GetStripeProductsListLazyQueryHookResult = ReturnType<typeof useGetStripeProductsListLazyQuery>;
export type GetStripeProductsListQueryResult = Apollo.QueryResult<GetStripeProductsListQuery, GetStripeProductsListQueryVariables>;
export const GetDatasetThumbnailsDocument = gql`
    query getDatasetThumbnails($workspaceId: String!, $datasetId: String!) {
  getDatasetThumbnails(workspaceId: $workspaceId, datasetId: $datasetId)
}
    `;

/**
 * __useGetDatasetThumbnailsQuery__
 *
 * To run a query within a React component, call `useGetDatasetThumbnailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasetThumbnailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasetThumbnailsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetDatasetThumbnailsQuery(baseOptions: Apollo.QueryHookOptions<GetDatasetThumbnailsQuery, GetDatasetThumbnailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatasetThumbnailsQuery, GetDatasetThumbnailsQueryVariables>(GetDatasetThumbnailsDocument, options);
      }
export function useGetDatasetThumbnailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatasetThumbnailsQuery, GetDatasetThumbnailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatasetThumbnailsQuery, GetDatasetThumbnailsQueryVariables>(GetDatasetThumbnailsDocument, options);
        }
export type GetDatasetThumbnailsQueryHookResult = ReturnType<typeof useGetDatasetThumbnailsQuery>;
export type GetDatasetThumbnailsLazyQueryHookResult = ReturnType<typeof useGetDatasetThumbnailsLazyQuery>;
export type GetDatasetThumbnailsQueryResult = Apollo.QueryResult<GetDatasetThumbnailsQuery, GetDatasetThumbnailsQueryVariables>;
export const PingPreviewDocument = gql`
    mutation pingPreview($channelId: String!) {
  pingPreview(channelId: $channelId)
}
    `;
export type PingPreviewMutationFn = Apollo.MutationFunction<PingPreviewMutation, PingPreviewMutationVariables>;

/**
 * __usePingPreviewMutation__
 *
 * To run a mutation, you first call `usePingPreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePingPreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pingPreviewMutation, { data, loading, error }] = usePingPreviewMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function usePingPreviewMutation(baseOptions?: Apollo.MutationHookOptions<PingPreviewMutation, PingPreviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PingPreviewMutation, PingPreviewMutationVariables>(PingPreviewDocument, options);
      }
export type PingPreviewMutationHookResult = ReturnType<typeof usePingPreviewMutation>;
export type PingPreviewMutationResult = Apollo.MutationResult<PingPreviewMutation>;
export type PingPreviewMutationOptions = Apollo.BaseMutationOptions<PingPreviewMutation, PingPreviewMutationVariables>;
export const CreateLinkedAppDocument = gql`
    mutation createLinkedApp($appId: String!, $token: String!) {
  createLinkedApp(appId: $appId, token: $token)
}
    `;
export type CreateLinkedAppMutationFn = Apollo.MutationFunction<CreateLinkedAppMutation, CreateLinkedAppMutationVariables>;

/**
 * __useCreateLinkedAppMutation__
 *
 * To run a mutation, you first call `useCreateLinkedAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinkedAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinkedAppMutation, { data, loading, error }] = useCreateLinkedAppMutation({
 *   variables: {
 *      appId: // value for 'appId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCreateLinkedAppMutation(baseOptions?: Apollo.MutationHookOptions<CreateLinkedAppMutation, CreateLinkedAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLinkedAppMutation, CreateLinkedAppMutationVariables>(CreateLinkedAppDocument, options);
      }
export type CreateLinkedAppMutationHookResult = ReturnType<typeof useCreateLinkedAppMutation>;
export type CreateLinkedAppMutationResult = Apollo.MutationResult<CreateLinkedAppMutation>;
export type CreateLinkedAppMutationOptions = Apollo.BaseMutationOptions<CreateLinkedAppMutation, CreateLinkedAppMutationVariables>;
export const DeleteLinkedAppDocument = gql`
    mutation deleteLinkedApp($appId: String!) {
  deleteLinkedApp(appId: $appId)
}
    `;
export type DeleteLinkedAppMutationFn = Apollo.MutationFunction<DeleteLinkedAppMutation, DeleteLinkedAppMutationVariables>;

/**
 * __useDeleteLinkedAppMutation__
 *
 * To run a mutation, you first call `useDeleteLinkedAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLinkedAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLinkedAppMutation, { data, loading, error }] = useDeleteLinkedAppMutation({
 *   variables: {
 *      appId: // value for 'appId'
 *   },
 * });
 */
export function useDeleteLinkedAppMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLinkedAppMutation, DeleteLinkedAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLinkedAppMutation, DeleteLinkedAppMutationVariables>(DeleteLinkedAppDocument, options);
      }
export type DeleteLinkedAppMutationHookResult = ReturnType<typeof useDeleteLinkedAppMutation>;
export type DeleteLinkedAppMutationResult = Apollo.MutationResult<DeleteLinkedAppMutation>;
export type DeleteLinkedAppMutationOptions = Apollo.BaseMutationOptions<DeleteLinkedAppMutation, DeleteLinkedAppMutationVariables>;
export const GetLinkedAppsDocument = gql`
    query getLinkedApps {
  getLinkedApps {
    appId
    name
    linked
    thumbnail
    description
    clientId
    clientSecret
  }
}
    `;

/**
 * __useGetLinkedAppsQuery__
 *
 * To run a query within a React component, call `useGetLinkedAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLinkedAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLinkedAppsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLinkedAppsQuery(baseOptions?: Apollo.QueryHookOptions<GetLinkedAppsQuery, GetLinkedAppsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLinkedAppsQuery, GetLinkedAppsQueryVariables>(GetLinkedAppsDocument, options);
      }
export function useGetLinkedAppsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLinkedAppsQuery, GetLinkedAppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLinkedAppsQuery, GetLinkedAppsQueryVariables>(GetLinkedAppsDocument, options);
        }
export type GetLinkedAppsQueryHookResult = ReturnType<typeof useGetLinkedAppsQuery>;
export type GetLinkedAppsLazyQueryHookResult = ReturnType<typeof useGetLinkedAppsLazyQuery>;
export type GetLinkedAppsQueryResult = Apollo.QueryResult<GetLinkedAppsQuery, GetLinkedAppsQueryVariables>;
export const SignUpDocument = gql`
    mutation signUp($email: String!, $code: String, $name: String!) {
  signUp(email: $email, code: $code, name: $name)
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const ValidateContentCodeDocument = gql`
    query validateContentCode($code: String!) {
  validateContentCode(code: $code)
}
    `;

/**
 * __useValidateContentCodeQuery__
 *
 * To run a query within a React component, call `useValidateContentCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateContentCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateContentCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useValidateContentCodeQuery(baseOptions: Apollo.QueryHookOptions<ValidateContentCodeQuery, ValidateContentCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateContentCodeQuery, ValidateContentCodeQueryVariables>(ValidateContentCodeDocument, options);
      }
export function useValidateContentCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateContentCodeQuery, ValidateContentCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateContentCodeQuery, ValidateContentCodeQueryVariables>(ValidateContentCodeDocument, options);
        }
export type ValidateContentCodeQueryHookResult = ReturnType<typeof useValidateContentCodeQuery>;
export type ValidateContentCodeLazyQueryHookResult = ReturnType<typeof useValidateContentCodeLazyQuery>;
export type ValidateContentCodeQueryResult = Apollo.QueryResult<ValidateContentCodeQuery, ValidateContentCodeQueryVariables>;
export const CreateUmapDocument = gql`
    mutation createUMAP($datasetIds: [String]!, $name: String, $samples: [Int]!, $seed: String, $workspaceId: String!) {
  createUMAP(
    datasetIds: $datasetIds
    name: $name
    samples: $samples
    seed: $seed
    workspaceId: $workspaceId
  )
}
    `;
export type CreateUmapMutationFn = Apollo.MutationFunction<CreateUmapMutation, CreateUmapMutationVariables>;

/**
 * __useCreateUmapMutation__
 *
 * To run a mutation, you first call `useCreateUmapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUmapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUmapMutation, { data, loading, error }] = useCreateUmapMutation({
 *   variables: {
 *      datasetIds: // value for 'datasetIds'
 *      name: // value for 'name'
 *      samples: // value for 'samples'
 *      seed: // value for 'seed'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useCreateUmapMutation(baseOptions?: Apollo.MutationHookOptions<CreateUmapMutation, CreateUmapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUmapMutation, CreateUmapMutationVariables>(CreateUmapDocument, options);
      }
export type CreateUmapMutationHookResult = ReturnType<typeof useCreateUmapMutation>;
export type CreateUmapMutationResult = Apollo.MutationResult<CreateUmapMutation>;
export type CreateUmapMutationOptions = Apollo.BaseMutationOptions<CreateUmapMutation, CreateUmapMutationVariables>;
export const DeleteUmapDocument = gql`
    mutation deleteUMAP($workspaceId: String!, $umapId: String!) {
  deleteUMAP(workspaceId: $workspaceId, umapId: $umapId)
}
    `;
export type DeleteUmapMutationFn = Apollo.MutationFunction<DeleteUmapMutation, DeleteUmapMutationVariables>;

/**
 * __useDeleteUmapMutation__
 *
 * To run a mutation, you first call `useDeleteUmapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUmapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUmapMutation, { data, loading, error }] = useDeleteUmapMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      umapId: // value for 'umapId'
 *   },
 * });
 */
export function useDeleteUmapMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUmapMutation, DeleteUmapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUmapMutation, DeleteUmapMutationVariables>(DeleteUmapDocument, options);
      }
export type DeleteUmapMutationHookResult = ReturnType<typeof useDeleteUmapMutation>;
export type DeleteUmapMutationResult = Apollo.MutationResult<DeleteUmapMutation>;
export type DeleteUmapMutationOptions = Apollo.BaseMutationOptions<DeleteUmapMutation, DeleteUmapMutationVariables>;
export const EditUmapDocument = gql`
    mutation editUMAP($workspaceId: String!, $umapId: String!, $name: String!) {
  editUMAP(workspaceId: $workspaceId, umapId: $umapId, name: $name)
}
    `;
export type EditUmapMutationFn = Apollo.MutationFunction<EditUmapMutation, EditUmapMutationVariables>;

/**
 * __useEditUmapMutation__
 *
 * To run a mutation, you first call `useEditUmapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUmapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUmapMutation, { data, loading, error }] = useEditUmapMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      umapId: // value for 'umapId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditUmapMutation(baseOptions?: Apollo.MutationHookOptions<EditUmapMutation, EditUmapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUmapMutation, EditUmapMutationVariables>(EditUmapDocument, options);
      }
export type EditUmapMutationHookResult = ReturnType<typeof useEditUmapMutation>;
export type EditUmapMutationResult = Apollo.MutationResult<EditUmapMutation>;
export type EditUmapMutationOptions = Apollo.BaseMutationOptions<EditUmapMutation, EditUmapMutationVariables>;
export const GetUmapLogDocument = gql`
    query getUMAPLog($workspaceId: String!, $umapId: String!) {
  getUMAPLog(workspaceId: $workspaceId, umapId: $umapId) {
    umapId
    workspaceId
    state
    log
  }
}
    `;

/**
 * __useGetUmapLogQuery__
 *
 * To run a query within a React component, call `useGetUmapLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUmapLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUmapLogQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      umapId: // value for 'umapId'
 *   },
 * });
 */
export function useGetUmapLogQuery(baseOptions: Apollo.QueryHookOptions<GetUmapLogQuery, GetUmapLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUmapLogQuery, GetUmapLogQueryVariables>(GetUmapLogDocument, options);
      }
export function useGetUmapLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUmapLogQuery, GetUmapLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUmapLogQuery, GetUmapLogQueryVariables>(GetUmapLogDocument, options);
        }
export type GetUmapLogQueryHookResult = ReturnType<typeof useGetUmapLogQuery>;
export type GetUmapLogLazyQueryHookResult = ReturnType<typeof useGetUmapLogLazyQuery>;
export type GetUmapLogQueryResult = Apollo.QueryResult<GetUmapLogQuery, GetUmapLogQueryVariables>;
export const GetUmapDocument = gql`
    query getUMAP($workspaceId: String!, $umapId: String) {
  getUMAPs(workspaceId: $workspaceId, umapId: $umapId) {
    datasets {
      datasetId
      dataset
    }
    name
    results
    samples
    seed
    status
    umapId
    workspaceId
  }
}
    `;

/**
 * __useGetUmapQuery__
 *
 * To run a query within a React component, call `useGetUmapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUmapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUmapQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      umapId: // value for 'umapId'
 *   },
 * });
 */
export function useGetUmapQuery(baseOptions: Apollo.QueryHookOptions<GetUmapQuery, GetUmapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUmapQuery, GetUmapQueryVariables>(GetUmapDocument, options);
      }
export function useGetUmapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUmapQuery, GetUmapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUmapQuery, GetUmapQueryVariables>(GetUmapDocument, options);
        }
export type GetUmapQueryHookResult = ReturnType<typeof useGetUmapQuery>;
export type GetUmapLazyQueryHookResult = ReturnType<typeof useGetUmapLazyQuery>;
export type GetUmapQueryResult = Apollo.QueryResult<GetUmapQuery, GetUmapQueryVariables>;
export const RemoveMapOrganizationDocument = gql`
    mutation removeMapOrganization($organizationId: String!, $mapId: String!) {
  removeMapOrganization(organizationId: $organizationId, mapId: $mapId)
}
    `;
export type RemoveMapOrganizationMutationFn = Apollo.MutationFunction<RemoveMapOrganizationMutation, RemoveMapOrganizationMutationVariables>;

/**
 * __useRemoveMapOrganizationMutation__
 *
 * To run a mutation, you first call `useRemoveMapOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMapOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMapOrganizationMutation, { data, loading, error }] = useRemoveMapOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      mapId: // value for 'mapId'
 *   },
 * });
 */
export function useRemoveMapOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMapOrganizationMutation, RemoveMapOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMapOrganizationMutation, RemoveMapOrganizationMutationVariables>(RemoveMapOrganizationDocument, options);
      }
export type RemoveMapOrganizationMutationHookResult = ReturnType<typeof useRemoveMapOrganizationMutation>;
export type RemoveMapOrganizationMutationResult = Apollo.MutationResult<RemoveMapOrganizationMutation>;
export type RemoveMapOrganizationMutationOptions = Apollo.BaseMutationOptions<RemoveMapOrganizationMutation, RemoveMapOrganizationMutationVariables>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  getCurrentUser {
    userId
    email
    name
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($isDeleting: Boolean) {
  deleteUser(isDeleting: $isDeleting) {
    organizationId
    name
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      isDeleting: // value for 'isDeleting'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const GetVolumesDocument = gql`
    query getVolumes($organizationId: String, $volumeId: String, $workspaceId: String, $cursor: String, $limit: Int) {
  getVolumes(
    organizationId: $organizationId
    volumeId: $volumeId
    workspaceId: $workspaceId
    cursor: $cursor
    limit: $limit
  ) {
    name
    size
    volumeId
    updatedAt
    createdAt
    permission
    description
    organization
    organizationId
  }
}
    `;

/**
 * __useGetVolumesQuery__
 *
 * To run a query within a React component, call `useGetVolumesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVolumesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVolumesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      volumeId: // value for 'volumeId'
 *      workspaceId: // value for 'workspaceId'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetVolumesQuery(baseOptions?: Apollo.QueryHookOptions<GetVolumesQuery, GetVolumesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVolumesQuery, GetVolumesQueryVariables>(GetVolumesDocument, options);
      }
export function useGetVolumesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVolumesQuery, GetVolumesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVolumesQuery, GetVolumesQueryVariables>(GetVolumesDocument, options);
        }
export type GetVolumesQueryHookResult = ReturnType<typeof useGetVolumesQuery>;
export type GetVolumesLazyQueryHookResult = ReturnType<typeof useGetVolumesLazyQuery>;
export type GetVolumesQueryResult = Apollo.QueryResult<GetVolumesQuery, GetVolumesQueryVariables>;
export const CreateManagedVolumeDocument = gql`
    mutation createManagedVolume($organizationId: String!, $name: String!, $description: String) {
  createManagedVolume(
    organizationId: $organizationId
    name: $name
    description: $description
  )
}
    `;
export type CreateManagedVolumeMutationFn = Apollo.MutationFunction<CreateManagedVolumeMutation, CreateManagedVolumeMutationVariables>;

/**
 * __useCreateManagedVolumeMutation__
 *
 * To run a mutation, you first call `useCreateManagedVolumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManagedVolumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManagedVolumeMutation, { data, loading, error }] = useCreateManagedVolumeMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateManagedVolumeMutation(baseOptions?: Apollo.MutationHookOptions<CreateManagedVolumeMutation, CreateManagedVolumeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManagedVolumeMutation, CreateManagedVolumeMutationVariables>(CreateManagedVolumeDocument, options);
      }
export type CreateManagedVolumeMutationHookResult = ReturnType<typeof useCreateManagedVolumeMutation>;
export type CreateManagedVolumeMutationResult = Apollo.MutationResult<CreateManagedVolumeMutation>;
export type CreateManagedVolumeMutationOptions = Apollo.BaseMutationOptions<CreateManagedVolumeMutation, CreateManagedVolumeMutationVariables>;
export const DeleteManagedVolumeDocument = gql`
    mutation deleteManagedVolume($volumeId: String!) {
  deleteManagedVolume(volumeId: $volumeId)
}
    `;
export type DeleteManagedVolumeMutationFn = Apollo.MutationFunction<DeleteManagedVolumeMutation, DeleteManagedVolumeMutationVariables>;

/**
 * __useDeleteManagedVolumeMutation__
 *
 * To run a mutation, you first call `useDeleteManagedVolumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedVolumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedVolumeMutation, { data, loading, error }] = useDeleteManagedVolumeMutation({
 *   variables: {
 *      volumeId: // value for 'volumeId'
 *   },
 * });
 */
export function useDeleteManagedVolumeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedVolumeMutation, DeleteManagedVolumeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedVolumeMutation, DeleteManagedVolumeMutationVariables>(DeleteManagedVolumeDocument, options);
      }
export type DeleteManagedVolumeMutationHookResult = ReturnType<typeof useDeleteManagedVolumeMutation>;
export type DeleteManagedVolumeMutationResult = Apollo.MutationResult<DeleteManagedVolumeMutation>;
export type DeleteManagedVolumeMutationOptions = Apollo.BaseMutationOptions<DeleteManagedVolumeMutation, DeleteManagedVolumeMutationVariables>;
export const GetVolumeDataDocument = gql`
    query getVolumeData($volumeId: String!, $keys: [String], $dir: String, $cursor: String, $limit: Int) {
  getVolumeData(
    volumeId: $volumeId
    keys: $keys
    dir: $dir
    cursor: $cursor
    limit: $limit
  ) {
    keys {
      url
      updatedAt
      thumbnailUrl
      size
      key
      hash
      fields {
        algorithm
        bucket
        credential
        date
        key
        policy
        signature
        token
      }
    }
    pageInfo {
      totalItems
      offset
      limit
    }
  }
}
    `;

/**
 * __useGetVolumeDataQuery__
 *
 * To run a query within a React component, call `useGetVolumeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVolumeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVolumeDataQuery({
 *   variables: {
 *      volumeId: // value for 'volumeId'
 *      keys: // value for 'keys'
 *      dir: // value for 'dir'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetVolumeDataQuery(baseOptions: Apollo.QueryHookOptions<GetVolumeDataQuery, GetVolumeDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVolumeDataQuery, GetVolumeDataQueryVariables>(GetVolumeDataDocument, options);
      }
export function useGetVolumeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVolumeDataQuery, GetVolumeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVolumeDataQuery, GetVolumeDataQueryVariables>(GetVolumeDataDocument, options);
        }
export type GetVolumeDataQueryHookResult = ReturnType<typeof useGetVolumeDataQuery>;
export type GetVolumeDataLazyQueryHookResult = ReturnType<typeof useGetVolumeDataLazyQuery>;
export type GetVolumeDataQueryResult = Apollo.QueryResult<GetVolumeDataQuery, GetVolumeDataQueryVariables>;
export const DeleteVolumeDataDocument = gql`
    mutation deleteVolumeData($volumeId: String!, $keys: [String]) {
  deleteVolumeData(volumeId: $volumeId, keys: $keys)
}
    `;
export type DeleteVolumeDataMutationFn = Apollo.MutationFunction<DeleteVolumeDataMutation, DeleteVolumeDataMutationVariables>;

/**
 * __useDeleteVolumeDataMutation__
 *
 * To run a mutation, you first call `useDeleteVolumeDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVolumeDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVolumeDataMutation, { data, loading, error }] = useDeleteVolumeDataMutation({
 *   variables: {
 *      volumeId: // value for 'volumeId'
 *      keys: // value for 'keys'
 *   },
 * });
 */
export function useDeleteVolumeDataMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVolumeDataMutation, DeleteVolumeDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVolumeDataMutation, DeleteVolumeDataMutationVariables>(DeleteVolumeDataDocument, options);
      }
export type DeleteVolumeDataMutationHookResult = ReturnType<typeof useDeleteVolumeDataMutation>;
export type DeleteVolumeDataMutationResult = Apollo.MutationResult<DeleteVolumeDataMutation>;
export type DeleteVolumeDataMutationOptions = Apollo.BaseMutationOptions<DeleteVolumeDataMutation, DeleteVolumeDataMutationVariables>;
export const PutVolumeDataDocument = gql`
    mutation putVolumeData($volumeId: String!, $keys: [String]!) {
  putVolumeData(volumeId: $volumeId, keys: $keys) {
    fields {
      algorithm
      bucket
      credential
      date
      key
      policy
      signature
      token
    }
    hash
    key
    updatedAt
    url
  }
}
    `;
export type PutVolumeDataMutationFn = Apollo.MutationFunction<PutVolumeDataMutation, PutVolumeDataMutationVariables>;

/**
 * __usePutVolumeDataMutation__
 *
 * To run a mutation, you first call `usePutVolumeDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutVolumeDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putVolumeDataMutation, { data, loading, error }] = usePutVolumeDataMutation({
 *   variables: {
 *      volumeId: // value for 'volumeId'
 *      keys: // value for 'keys'
 *   },
 * });
 */
export function usePutVolumeDataMutation(baseOptions?: Apollo.MutationHookOptions<PutVolumeDataMutation, PutVolumeDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PutVolumeDataMutation, PutVolumeDataMutationVariables>(PutVolumeDataDocument, options);
      }
export type PutVolumeDataMutationHookResult = ReturnType<typeof usePutVolumeDataMutation>;
export type PutVolumeDataMutationResult = Apollo.MutationResult<PutVolumeDataMutation>;
export type PutVolumeDataMutationOptions = Apollo.BaseMutationOptions<PutVolumeDataMutation, PutVolumeDataMutationVariables>;
export const EditVolumeDataDocument = gql`
    mutation editVolumeData($volumeId: String!, $source: String!, $key: String!) {
  editVolumeData(volumeId: $volumeId, source: $source, key: $key)
}
    `;
export type EditVolumeDataMutationFn = Apollo.MutationFunction<EditVolumeDataMutation, EditVolumeDataMutationVariables>;

/**
 * __useEditVolumeDataMutation__
 *
 * To run a mutation, you first call `useEditVolumeDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVolumeDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVolumeDataMutation, { data, loading, error }] = useEditVolumeDataMutation({
 *   variables: {
 *      volumeId: // value for 'volumeId'
 *      source: // value for 'source'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useEditVolumeDataMutation(baseOptions?: Apollo.MutationHookOptions<EditVolumeDataMutation, EditVolumeDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditVolumeDataMutation, EditVolumeDataMutationVariables>(EditVolumeDataDocument, options);
      }
export type EditVolumeDataMutationHookResult = ReturnType<typeof useEditVolumeDataMutation>;
export type EditVolumeDataMutationResult = Apollo.MutationResult<EditVolumeDataMutation>;
export type EditVolumeDataMutationOptions = Apollo.BaseMutationOptions<EditVolumeDataMutation, EditVolumeDataMutationVariables>;
export const RemoveVolumeOrganizationDocument = gql`
    mutation removeVolumeOrganization($organizationId: String!, $volumeId: String!) {
  removeVolumeOrganization(organizationId: $organizationId, volumeId: $volumeId)
}
    `;
export type RemoveVolumeOrganizationMutationFn = Apollo.MutationFunction<RemoveVolumeOrganizationMutation, RemoveVolumeOrganizationMutationVariables>;

/**
 * __useRemoveVolumeOrganizationMutation__
 *
 * To run a mutation, you first call `useRemoveVolumeOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVolumeOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVolumeOrganizationMutation, { data, loading, error }] = useRemoveVolumeOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      volumeId: // value for 'volumeId'
 *   },
 * });
 */
export function useRemoveVolumeOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveVolumeOrganizationMutation, RemoveVolumeOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveVolumeOrganizationMutation, RemoveVolumeOrganizationMutationVariables>(RemoveVolumeOrganizationDocument, options);
      }
export type RemoveVolumeOrganizationMutationHookResult = ReturnType<typeof useRemoveVolumeOrganizationMutation>;
export type RemoveVolumeOrganizationMutationResult = Apollo.MutationResult<RemoveVolumeOrganizationMutation>;
export type RemoveVolumeOrganizationMutationOptions = Apollo.BaseMutationOptions<RemoveVolumeOrganizationMutation, RemoveVolumeOrganizationMutationVariables>;
export const EditManagedVolumeDocument = gql`
    mutation editManagedVolume($volumeId: String!, $name: String, $description: String, $permission: String) {
  editManagedVolume(
    volumeId: $volumeId
    name: $name
    description: $description
    permission: $permission
  )
}
    `;
export type EditManagedVolumeMutationFn = Apollo.MutationFunction<EditManagedVolumeMutation, EditManagedVolumeMutationVariables>;

/**
 * __useEditManagedVolumeMutation__
 *
 * To run a mutation, you first call `useEditManagedVolumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditManagedVolumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editManagedVolumeMutation, { data, loading, error }] = useEditManagedVolumeMutation({
 *   variables: {
 *      volumeId: // value for 'volumeId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useEditManagedVolumeMutation(baseOptions?: Apollo.MutationHookOptions<EditManagedVolumeMutation, EditManagedVolumeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditManagedVolumeMutation, EditManagedVolumeMutationVariables>(EditManagedVolumeDocument, options);
      }
export type EditManagedVolumeMutationHookResult = ReturnType<typeof useEditManagedVolumeMutation>;
export type EditManagedVolumeMutationResult = Apollo.MutationResult<EditManagedVolumeMutation>;
export type EditManagedVolumeMutationOptions = Apollo.BaseMutationOptions<EditManagedVolumeMutation, EditManagedVolumeMutationVariables>;
export const EditWorkspaceDocument = gql`
    mutation editWorkspace($workspaceId: String!, $name: String, $channelIds: [String], $volumeIds: [String], $ganIds: [String], $mapIds: [String]) {
  editWorkspace(
    workspaceId: $workspaceId
    name: $name
    channelIds: $channelIds
    volumeIds: $volumeIds
    ganIds: $ganIds
    mapIds: $mapIds
  )
}
    `;
export type EditWorkspaceMutationFn = Apollo.MutationFunction<EditWorkspaceMutation, EditWorkspaceMutationVariables>;

/**
 * __useEditWorkspaceMutation__
 *
 * To run a mutation, you first call `useEditWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editWorkspaceMutation, { data, loading, error }] = useEditWorkspaceMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      name: // value for 'name'
 *      channelIds: // value for 'channelIds'
 *      volumeIds: // value for 'volumeIds'
 *      ganIds: // value for 'ganIds'
 *      mapIds: // value for 'mapIds'
 *   },
 * });
 */
export function useEditWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<EditWorkspaceMutation, EditWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditWorkspaceMutation, EditWorkspaceMutationVariables>(EditWorkspaceDocument, options);
      }
export type EditWorkspaceMutationHookResult = ReturnType<typeof useEditWorkspaceMutation>;
export type EditWorkspaceMutationResult = Apollo.MutationResult<EditWorkspaceMutation>;
export type EditWorkspaceMutationOptions = Apollo.BaseMutationOptions<EditWorkspaceMutation, EditWorkspaceMutationVariables>;
export const EditWorkspaceThumbnailDocument = gql`
    mutation editWorkspaceThumbnail($workspaceId: String!, $thumbnail: String!) {
  editWorkspaceThumbnail(workspaceId: $workspaceId, thumbnail: $thumbnail)
}
    `;
export type EditWorkspaceThumbnailMutationFn = Apollo.MutationFunction<EditWorkspaceThumbnailMutation, EditWorkspaceThumbnailMutationVariables>;

/**
 * __useEditWorkspaceThumbnailMutation__
 *
 * To run a mutation, you first call `useEditWorkspaceThumbnailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditWorkspaceThumbnailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editWorkspaceThumbnailMutation, { data, loading, error }] = useEditWorkspaceThumbnailMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      thumbnail: // value for 'thumbnail'
 *   },
 * });
 */
export function useEditWorkspaceThumbnailMutation(baseOptions?: Apollo.MutationHookOptions<EditWorkspaceThumbnailMutation, EditWorkspaceThumbnailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditWorkspaceThumbnailMutation, EditWorkspaceThumbnailMutationVariables>(EditWorkspaceThumbnailDocument, options);
      }
export type EditWorkspaceThumbnailMutationHookResult = ReturnType<typeof useEditWorkspaceThumbnailMutation>;
export type EditWorkspaceThumbnailMutationResult = Apollo.MutationResult<EditWorkspaceThumbnailMutation>;
export type EditWorkspaceThumbnailMutationOptions = Apollo.BaseMutationOptions<EditWorkspaceThumbnailMutation, EditWorkspaceThumbnailMutationVariables>;
export const CreateWorkspaceDocument = gql`
    mutation createWorkspace($organizationId: String!, $name: String!, $channelIds: [String]!, $code: String, $volumeIds: [String]!) {
  createWorkspace(
    organizationId: $organizationId
    name: $name
    channelIds: $channelIds
    code: $code
    volumeIds: $volumeIds
  )
}
    `;
export type CreateWorkspaceMutationFn = Apollo.MutationFunction<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>;

/**
 * __useCreateWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceMutation, { data, loading, error }] = useCreateWorkspaceMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *      channelIds: // value for 'channelIds'
 *      code: // value for 'code'
 *      volumeIds: // value for 'volumeIds'
 *   },
 * });
 */
export function useCreateWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>(CreateWorkspaceDocument, options);
      }
export type CreateWorkspaceMutationHookResult = ReturnType<typeof useCreateWorkspaceMutation>;
export type CreateWorkspaceMutationResult = Apollo.MutationResult<CreateWorkspaceMutation>;
export type CreateWorkspaceMutationOptions = Apollo.BaseMutationOptions<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>;
export const DeleteWorkspaceDocument = gql`
    mutation deleteWorkspace($workspaceId: String!) {
  deleteWorkspace(workspaceId: $workspaceId)
}
    `;
export type DeleteWorkspaceMutationFn = Apollo.MutationFunction<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>;

/**
 * __useDeleteWorkspaceMutation__
 *
 * To run a mutation, you first call `useDeleteWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkspaceMutation, { data, loading, error }] = useDeleteWorkspaceMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useDeleteWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>(DeleteWorkspaceDocument, options);
      }
export type DeleteWorkspaceMutationHookResult = ReturnType<typeof useDeleteWorkspaceMutation>;
export type DeleteWorkspaceMutationResult = Apollo.MutationResult<DeleteWorkspaceMutation>;
export type DeleteWorkspaceMutationOptions = Apollo.BaseMutationOptions<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>;
export const GetWorkspacesDocument = gql`
    query getWorkspaces($organizationId: String, $workspaceId: String, $limit: Int, $cursor: String) {
  getWorkspaces(
    organizationId: $organizationId
    workspaceId: $workspaceId
    limit: $limit
    cursor: $cursor
  ) {
    workspaceId
    organizationId
    organization
    name
    createdAt
    updatedAt
    previewThumbnail
    status
  }
}
    `;

/**
 * __useGetWorkspacesQuery__
 *
 * To run a query within a React component, call `useGetWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspacesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      workspaceId: // value for 'workspaceId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetWorkspacesQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkspacesQuery, GetWorkspacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspacesQuery, GetWorkspacesQueryVariables>(GetWorkspacesDocument, options);
      }
export function useGetWorkspacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspacesQuery, GetWorkspacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspacesQuery, GetWorkspacesQueryVariables>(GetWorkspacesDocument, options);
        }
export type GetWorkspacesQueryHookResult = ReturnType<typeof useGetWorkspacesQuery>;
export type GetWorkspacesLazyQueryHookResult = ReturnType<typeof useGetWorkspacesLazyQuery>;
export type GetWorkspacesQueryResult = Apollo.QueryResult<GetWorkspacesQuery, GetWorkspacesQueryVariables>;
export const GetWorkspaceSettingsDocument = gql`
    query getWorkspaceSettings($workspaceId: String!, $setting: String) {
  getWorkspaceSettings(workspaceId: $workspaceId, setting: $setting) {
    workspaceId
    setting
    limit
    usage
    units
  }
}
    `;

/**
 * __useGetWorkspaceSettingsQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceSettingsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      setting: // value for 'setting'
 *   },
 * });
 */
export function useGetWorkspaceSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetWorkspaceSettingsQuery, GetWorkspaceSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceSettingsQuery, GetWorkspaceSettingsQueryVariables>(GetWorkspaceSettingsDocument, options);
      }
export function useGetWorkspaceSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceSettingsQuery, GetWorkspaceSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceSettingsQuery, GetWorkspaceSettingsQueryVariables>(GetWorkspaceSettingsDocument, options);
        }
export type GetWorkspaceSettingsQueryHookResult = ReturnType<typeof useGetWorkspaceSettingsQuery>;
export type GetWorkspaceSettingsLazyQueryHookResult = ReturnType<typeof useGetWorkspaceSettingsLazyQuery>;
export type GetWorkspaceSettingsQueryResult = Apollo.QueryResult<GetWorkspaceSettingsQuery, GetWorkspaceSettingsQueryVariables>;
export const GetOrganizationJobsDocument = gql`
    query GetOrganizationJobs($organizationId: String) {
  getDatasetJobs(organizationId: $organizationId) {
    datasetId
    workspaceId
    name
    runs
    status
    createdAt
    estimatedEndAt
  }
}
    `;

/**
 * __useGetOrganizationJobsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationJobsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOrganizationJobsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationJobsQuery, GetOrganizationJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationJobsQuery, GetOrganizationJobsQueryVariables>(GetOrganizationJobsDocument, options);
      }
export function useGetOrganizationJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationJobsQuery, GetOrganizationJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationJobsQuery, GetOrganizationJobsQueryVariables>(GetOrganizationJobsDocument, options);
        }
export type GetOrganizationJobsQueryHookResult = ReturnType<typeof useGetOrganizationJobsQuery>;
export type GetOrganizationJobsLazyQueryHookResult = ReturnType<typeof useGetOrganizationJobsLazyQuery>;
export type GetOrganizationJobsQueryResult = Apollo.QueryResult<GetOrganizationJobsQuery, GetOrganizationJobsQueryVariables>;
export const GetWorkspaceNameDocument = gql`
    query GetWorkspaceName($workspaceId: String) {
  getWorkspaces(workspaceId: $workspaceId) {
    name
    workspaceId
  }
}
    `;

/**
 * __useGetWorkspaceNameQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceNameQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetWorkspaceNameQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkspaceNameQuery, GetWorkspaceNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceNameQuery, GetWorkspaceNameQueryVariables>(GetWorkspaceNameDocument, options);
      }
export function useGetWorkspaceNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceNameQuery, GetWorkspaceNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceNameQuery, GetWorkspaceNameQueryVariables>(GetWorkspaceNameDocument, options);
        }
export type GetWorkspaceNameQueryHookResult = ReturnType<typeof useGetWorkspaceNameQuery>;
export type GetWorkspaceNameLazyQueryHookResult = ReturnType<typeof useGetWorkspaceNameLazyQuery>;
export type GetWorkspaceNameQueryResult = Apollo.QueryResult<GetWorkspaceNameQuery, GetWorkspaceNameQueryVariables>;
export const GetContentCodesDocument = gql`
    query GetContentCodes {
  getContentCodes {
    code
    name
    description
    thumbnail
    link
    featured
    contactUs
    expiresAt
  }
}
    `;

/**
 * __useGetContentCodesQuery__
 *
 * To run a query within a React component, call `useGetContentCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContentCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetContentCodesQuery, GetContentCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentCodesQuery, GetContentCodesQueryVariables>(GetContentCodesDocument, options);
      }
export function useGetContentCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentCodesQuery, GetContentCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentCodesQuery, GetContentCodesQueryVariables>(GetContentCodesDocument, options);
        }
export type GetContentCodesQueryHookResult = ReturnType<typeof useGetContentCodesQuery>;
export type GetContentCodesLazyQueryHookResult = ReturnType<typeof useGetContentCodesLazyQuery>;
export type GetContentCodesQueryResult = Apollo.QueryResult<GetContentCodesQuery, GetContentCodesQueryVariables>;
export const GetWorkspaceAnalyticsDocument = gql`
    query GetWorkspaceAnalytics($workspaceId: String!, $cursor: String, $limit: Int) {
  getAnalytics(workspaceId: $workspaceId, cursor: $cursor, limit: $limit) {
    analyticsId
    createdAt
    createdBy
    dataset
    datasetId
    result
    status
    type
    workspaceId
  }
}
    `;

/**
 * __useGetWorkspaceAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceAnalyticsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetWorkspaceAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GetWorkspaceAnalyticsQuery, GetWorkspaceAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceAnalyticsQuery, GetWorkspaceAnalyticsQueryVariables>(GetWorkspaceAnalyticsDocument, options);
      }
export function useGetWorkspaceAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceAnalyticsQuery, GetWorkspaceAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceAnalyticsQuery, GetWorkspaceAnalyticsQueryVariables>(GetWorkspaceAnalyticsDocument, options);
        }
export type GetWorkspaceAnalyticsQueryHookResult = ReturnType<typeof useGetWorkspaceAnalyticsQuery>;
export type GetWorkspaceAnalyticsLazyQueryHookResult = ReturnType<typeof useGetWorkspaceAnalyticsLazyQuery>;
export type GetWorkspaceAnalyticsQueryResult = Apollo.QueryResult<GetWorkspaceAnalyticsQuery, GetWorkspaceAnalyticsQueryVariables>;
export const GetWorkspaceUmapsDocument = gql`
    query GetWorkspaceUmaps($workspaceId: String!, $cursor: String, $limit: Int) {
  getUMAPs(workspaceId: $workspaceId, cursor: $cursor, limit: $limit) {
    createdAt
    createdBy
    datasets {
      dataset
      datasetId
    }
    name
    results
    samples
    seed
    status
    umapId
    workspaceId
  }
}
    `;

/**
 * __useGetWorkspaceUmapsQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceUmapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceUmapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceUmapsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetWorkspaceUmapsQuery(baseOptions: Apollo.QueryHookOptions<GetWorkspaceUmapsQuery, GetWorkspaceUmapsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceUmapsQuery, GetWorkspaceUmapsQueryVariables>(GetWorkspaceUmapsDocument, options);
      }
export function useGetWorkspaceUmapsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceUmapsQuery, GetWorkspaceUmapsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceUmapsQuery, GetWorkspaceUmapsQueryVariables>(GetWorkspaceUmapsDocument, options);
        }
export type GetWorkspaceUmapsQueryHookResult = ReturnType<typeof useGetWorkspaceUmapsQuery>;
export type GetWorkspaceUmapsLazyQueryHookResult = ReturnType<typeof useGetWorkspaceUmapsLazyQuery>;
export type GetWorkspaceUmapsQueryResult = Apollo.QueryResult<GetWorkspaceUmapsQuery, GetWorkspaceUmapsQueryVariables>;
export const GetGraphsDocument = gql`
    query GetGraphs($workspaceId: String!, $staged: Boolean, $cursor: String, $limit: Int) {
  getGraphs(
    workspaceId: $workspaceId
    staged: $staged
    cursor: $cursor
    limit: $limit
  ) {
    channel
    createdAt
    createdBy
    description
    graphId
    name
    thumbnail
    updatedAt
    workspaceId
  }
}
    `;

/**
 * __useGetGraphsQuery__
 *
 * To run a query within a React component, call `useGetGraphsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraphsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraphsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      staged: // value for 'staged'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetGraphsQuery(baseOptions: Apollo.QueryHookOptions<GetGraphsQuery, GetGraphsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraphsQuery, GetGraphsQueryVariables>(GetGraphsDocument, options);
      }
export function useGetGraphsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraphsQuery, GetGraphsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraphsQuery, GetGraphsQueryVariables>(GetGraphsDocument, options);
        }
export type GetGraphsQueryHookResult = ReturnType<typeof useGetGraphsQuery>;
export type GetGraphsLazyQueryHookResult = ReturnType<typeof useGetGraphsLazyQuery>;
export type GetGraphsQueryResult = Apollo.QueryResult<GetGraphsQuery, GetGraphsQueryVariables>;
export const GetGraphMetadataDocument = gql`
    query GetGraphMetadata($workspaceId: String!, $graphId: String) {
  getGraphs(workspaceId: $workspaceId, graphId: $graphId) {
    channelId
    description
    graphId
    name
    previewId
    readOnly
    showPreview
    workspaceId
    updatedAt
  }
}
    `;

/**
 * __useGetGraphMetadataQuery__
 *
 * To run a query within a React component, call `useGetGraphMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraphMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraphMetadataQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      graphId: // value for 'graphId'
 *   },
 * });
 */
export function useGetGraphMetadataQuery(baseOptions: Apollo.QueryHookOptions<GetGraphMetadataQuery, GetGraphMetadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraphMetadataQuery, GetGraphMetadataQueryVariables>(GetGraphMetadataDocument, options);
      }
export function useGetGraphMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraphMetadataQuery, GetGraphMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraphMetadataQuery, GetGraphMetadataQueryVariables>(GetGraphMetadataDocument, options);
        }
export type GetGraphMetadataQueryHookResult = ReturnType<typeof useGetGraphMetadataQuery>;
export type GetGraphMetadataLazyQueryHookResult = ReturnType<typeof useGetGraphMetadataLazyQuery>;
export type GetGraphMetadataQueryResult = Apollo.QueryResult<GetGraphMetadataQuery, GetGraphMetadataQueryVariables>;
export const GetGraphDataDocument = gql`
    mutation GetGraphData($graphId: String!, $workspaceId: String!) {
  downloadGraph(graphId: $graphId, workspaceId: $workspaceId)
}
    `;
export type GetGraphDataMutationFn = Apollo.MutationFunction<GetGraphDataMutation, GetGraphDataMutationVariables>;

/**
 * __useGetGraphDataMutation__
 *
 * To run a mutation, you first call `useGetGraphDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetGraphDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getGraphDataMutation, { data, loading, error }] = useGetGraphDataMutation({
 *   variables: {
 *      graphId: // value for 'graphId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetGraphDataMutation(baseOptions?: Apollo.MutationHookOptions<GetGraphDataMutation, GetGraphDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetGraphDataMutation, GetGraphDataMutationVariables>(GetGraphDataDocument, options);
      }
export type GetGraphDataMutationHookResult = ReturnType<typeof useGetGraphDataMutation>;
export type GetGraphDataMutationResult = Apollo.MutationResult<GetGraphDataMutation>;
export type GetGraphDataMutationOptions = Apollo.BaseMutationOptions<GetGraphDataMutation, GetGraphDataMutationVariables>;
export const EditGraphDocument = gql`
    mutation EditGraph($graphId: String!, $workspaceId: String!, $description: String, $name: String) {
  editGraph(
    graphId: $graphId
    workspaceId: $workspaceId
    description: $description
    name: $name
  )
}
    `;
export type EditGraphMutationFn = Apollo.MutationFunction<EditGraphMutation, EditGraphMutationVariables>;

/**
 * __useEditGraphMutation__
 *
 * To run a mutation, you first call `useEditGraphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGraphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGraphMutation, { data, loading, error }] = useEditGraphMutation({
 *   variables: {
 *      graphId: // value for 'graphId'
 *      workspaceId: // value for 'workspaceId'
 *      description: // value for 'description'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditGraphMutation(baseOptions?: Apollo.MutationHookOptions<EditGraphMutation, EditGraphMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditGraphMutation, EditGraphMutationVariables>(EditGraphDocument, options);
      }
export type EditGraphMutationHookResult = ReturnType<typeof useEditGraphMutation>;
export type EditGraphMutationResult = Apollo.MutationResult<EditGraphMutation>;
export type EditGraphMutationOptions = Apollo.BaseMutationOptions<EditGraphMutation, EditGraphMutationVariables>;
export const DeleteGraphDocument = gql`
    mutation DeleteGraph($graphId: String!, $workspaceId: String!) {
  deleteGraph(graphId: $graphId, workspaceId: $workspaceId)
}
    `;
export type DeleteGraphMutationFn = Apollo.MutationFunction<DeleteGraphMutation, DeleteGraphMutationVariables>;

/**
 * __useDeleteGraphMutation__
 *
 * To run a mutation, you first call `useDeleteGraphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGraphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGraphMutation, { data, loading, error }] = useDeleteGraphMutation({
 *   variables: {
 *      graphId: // value for 'graphId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useDeleteGraphMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGraphMutation, DeleteGraphMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGraphMutation, DeleteGraphMutationVariables>(DeleteGraphDocument, options);
      }
export type DeleteGraphMutationHookResult = ReturnType<typeof useDeleteGraphMutation>;
export type DeleteGraphMutationResult = Apollo.MutationResult<DeleteGraphMutation>;
export type DeleteGraphMutationOptions = Apollo.BaseMutationOptions<DeleteGraphMutation, DeleteGraphMutationVariables>;
export const DownloadGraphDocument = gql`
    mutation DownloadGraph($graphId: String!, $workspaceId: String!) {
  downloadGraph(graphId: $graphId, workspaceId: $workspaceId)
}
    `;
export type DownloadGraphMutationFn = Apollo.MutationFunction<DownloadGraphMutation, DownloadGraphMutationVariables>;

/**
 * __useDownloadGraphMutation__
 *
 * To run a mutation, you first call `useDownloadGraphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadGraphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadGraphMutation, { data, loading, error }] = useDownloadGraphMutation({
 *   variables: {
 *      graphId: // value for 'graphId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useDownloadGraphMutation(baseOptions?: Apollo.MutationHookOptions<DownloadGraphMutation, DownloadGraphMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadGraphMutation, DownloadGraphMutationVariables>(DownloadGraphDocument, options);
      }
export type DownloadGraphMutationHookResult = ReturnType<typeof useDownloadGraphMutation>;
export type DownloadGraphMutationResult = Apollo.MutationResult<DownloadGraphMutation>;
export type DownloadGraphMutationOptions = Apollo.BaseMutationOptions<DownloadGraphMutation, DownloadGraphMutationVariables>;
export const DuplicateGraphDocument = gql`
    mutation DuplicateGraph($graphId: String!, $workspaceId: String!, $name: String, $description: String) {
  duplicateGraph(
    graphId: $graphId
    workspaceId: $workspaceId
    name: $name
    description: $description
  )
}
    `;
export type DuplicateGraphMutationFn = Apollo.MutationFunction<DuplicateGraphMutation, DuplicateGraphMutationVariables>;

/**
 * __useDuplicateGraphMutation__
 *
 * To run a mutation, you first call `useDuplicateGraphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateGraphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateGraphMutation, { data, loading, error }] = useDuplicateGraphMutation({
 *   variables: {
 *      graphId: // value for 'graphId'
 *      workspaceId: // value for 'workspaceId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useDuplicateGraphMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateGraphMutation, DuplicateGraphMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateGraphMutation, DuplicateGraphMutationVariables>(DuplicateGraphDocument, options);
      }
export type DuplicateGraphMutationHookResult = ReturnType<typeof useDuplicateGraphMutation>;
export type DuplicateGraphMutationResult = Apollo.MutationResult<DuplicateGraphMutation>;
export type DuplicateGraphMutationOptions = Apollo.BaseMutationOptions<DuplicateGraphMutation, DuplicateGraphMutationVariables>;
export const ValidateGraphDocument = gql`
    mutation ValidateGraph($workspaceId: String!, $graphId: String!) {
  validateGraph(workspaceId: $workspaceId, graphId: $graphId) {
    node
    input
    errorMessage
    type
  }
}
    `;
export type ValidateGraphMutationFn = Apollo.MutationFunction<ValidateGraphMutation, ValidateGraphMutationVariables>;

/**
 * __useValidateGraphMutation__
 *
 * To run a mutation, you first call `useValidateGraphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateGraphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateGraphMutation, { data, loading, error }] = useValidateGraphMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      graphId: // value for 'graphId'
 *   },
 * });
 */
export function useValidateGraphMutation(baseOptions?: Apollo.MutationHookOptions<ValidateGraphMutation, ValidateGraphMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateGraphMutation, ValidateGraphMutationVariables>(ValidateGraphDocument, options);
      }
export type ValidateGraphMutationHookResult = ReturnType<typeof useValidateGraphMutation>;
export type ValidateGraphMutationResult = Apollo.MutationResult<ValidateGraphMutation>;
export type ValidateGraphMutationOptions = Apollo.BaseMutationOptions<ValidateGraphMutation, ValidateGraphMutationVariables>;
export const SaveGraphDocument = gql`
    mutation SaveGraph($graphId: String!, $workspaceId: String!, $graph: String) {
  editGraph(graphId: $graphId, workspaceId: $workspaceId, graph: $graph)
}
    `;
export type SaveGraphMutationFn = Apollo.MutationFunction<SaveGraphMutation, SaveGraphMutationVariables>;

/**
 * __useSaveGraphMutation__
 *
 * To run a mutation, you first call `useSaveGraphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveGraphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveGraphMutation, { data, loading, error }] = useSaveGraphMutation({
 *   variables: {
 *      graphId: // value for 'graphId'
 *      workspaceId: // value for 'workspaceId'
 *      graph: // value for 'graph'
 *   },
 * });
 */
export function useSaveGraphMutation(baseOptions?: Apollo.MutationHookOptions<SaveGraphMutation, SaveGraphMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveGraphMutation, SaveGraphMutationVariables>(SaveGraphDocument, options);
      }
export type SaveGraphMutationHookResult = ReturnType<typeof useSaveGraphMutation>;
export type SaveGraphMutationResult = Apollo.MutationResult<SaveGraphMutation>;
export type SaveGraphMutationOptions = Apollo.BaseMutationOptions<SaveGraphMutation, SaveGraphMutationVariables>;
export const CreateGraphDocument = gql`
    mutation CreateGraph($workspaceId: String!, $channelId: String!, $graph: String!, $name: String!, $description: String, $staged: Boolean) {
  createGraph(
    workspaceId: $workspaceId
    channelId: $channelId
    graph: $graph
    name: $name
    description: $description
    staged: $staged
  )
}
    `;
export type CreateGraphMutationFn = Apollo.MutationFunction<CreateGraphMutation, CreateGraphMutationVariables>;

/**
 * __useCreateGraphMutation__
 *
 * To run a mutation, you first call `useCreateGraphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGraphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGraphMutation, { data, loading, error }] = useCreateGraphMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      channelId: // value for 'channelId'
 *      graph: // value for 'graph'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      staged: // value for 'staged'
 *   },
 * });
 */
export function useCreateGraphMutation(baseOptions?: Apollo.MutationHookOptions<CreateGraphMutation, CreateGraphMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGraphMutation, CreateGraphMutationVariables>(CreateGraphDocument, options);
      }
export type CreateGraphMutationHookResult = ReturnType<typeof useCreateGraphMutation>;
export type CreateGraphMutationResult = Apollo.MutationResult<CreateGraphMutation>;
export type CreateGraphMutationOptions = Apollo.BaseMutationOptions<CreateGraphMutation, CreateGraphMutationVariables>;
export const CreatePreviewDocument = gql`
    mutation CreatePreview($workspaceId: String!, $graphId: String!) {
  createPreview(workspaceId: $workspaceId, graphId: $graphId)
}
    `;
export type CreatePreviewMutationFn = Apollo.MutationFunction<CreatePreviewMutation, CreatePreviewMutationVariables>;

/**
 * __useCreatePreviewMutation__
 *
 * To run a mutation, you first call `useCreatePreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreviewMutation, { data, loading, error }] = useCreatePreviewMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      graphId: // value for 'graphId'
 *   },
 * });
 */
export function useCreatePreviewMutation(baseOptions?: Apollo.MutationHookOptions<CreatePreviewMutation, CreatePreviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePreviewMutation, CreatePreviewMutationVariables>(CreatePreviewDocument, options);
      }
export type CreatePreviewMutationHookResult = ReturnType<typeof useCreatePreviewMutation>;
export type CreatePreviewMutationResult = Apollo.MutationResult<CreatePreviewMutation>;
export type CreatePreviewMutationOptions = Apollo.BaseMutationOptions<CreatePreviewMutation, CreatePreviewMutationVariables>;
export const DeletePreviewDocument = gql`
    mutation DeletePreview($workspaceId: String!, $previewId: String!) {
  deletePreview(workspaceId: $workspaceId, previewId: $previewId)
}
    `;
export type DeletePreviewMutationFn = Apollo.MutationFunction<DeletePreviewMutation, DeletePreviewMutationVariables>;

/**
 * __useDeletePreviewMutation__
 *
 * To run a mutation, you first call `useDeletePreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePreviewMutation, { data, loading, error }] = useDeletePreviewMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      previewId: // value for 'previewId'
 *   },
 * });
 */
export function useDeletePreviewMutation(baseOptions?: Apollo.MutationHookOptions<DeletePreviewMutation, DeletePreviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePreviewMutation, DeletePreviewMutationVariables>(DeletePreviewDocument, options);
      }
export type DeletePreviewMutationHookResult = ReturnType<typeof useDeletePreviewMutation>;
export type DeletePreviewMutationResult = Apollo.MutationResult<DeletePreviewMutation>;
export type DeletePreviewMutationOptions = Apollo.BaseMutationOptions<DeletePreviewMutation, DeletePreviewMutationVariables>;
export const GetPreviewDocument = gql`
    query GetPreview($workspaceId: String!, $previewId: String!) {
  getPreview(workspaceId: $workspaceId, previewId: $previewId) {
    status
    previewId
    thumbnail
    updatedAt
    workspaceId
  }
}
    `;

/**
 * __useGetPreviewQuery__
 *
 * To run a query within a React component, call `useGetPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreviewQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      previewId: // value for 'previewId'
 *   },
 * });
 */
export function useGetPreviewQuery(baseOptions: Apollo.QueryHookOptions<GetPreviewQuery, GetPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPreviewQuery, GetPreviewQueryVariables>(GetPreviewDocument, options);
      }
export function useGetPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPreviewQuery, GetPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPreviewQuery, GetPreviewQueryVariables>(GetPreviewDocument, options);
        }
export type GetPreviewQueryHookResult = ReturnType<typeof useGetPreviewQuery>;
export type GetPreviewLazyQueryHookResult = ReturnType<typeof useGetPreviewLazyQuery>;
export type GetPreviewQueryResult = Apollo.QueryResult<GetPreviewQuery, GetPreviewQueryVariables>;
export const MarkPreviewShownDocument = gql`
    mutation MarkPreviewShown($workspaceId: String!, $graphId: String!, $showPreview: Boolean) {
  editGraph(
    workspaceId: $workspaceId
    graphId: $graphId
    showPreview: $showPreview
  )
}
    `;
export type MarkPreviewShownMutationFn = Apollo.MutationFunction<MarkPreviewShownMutation, MarkPreviewShownMutationVariables>;

/**
 * __useMarkPreviewShownMutation__
 *
 * To run a mutation, you first call `useMarkPreviewShownMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkPreviewShownMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markPreviewShownMutation, { data, loading, error }] = useMarkPreviewShownMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      graphId: // value for 'graphId'
 *      showPreview: // value for 'showPreview'
 *   },
 * });
 */
export function useMarkPreviewShownMutation(baseOptions?: Apollo.MutationHookOptions<MarkPreviewShownMutation, MarkPreviewShownMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkPreviewShownMutation, MarkPreviewShownMutationVariables>(MarkPreviewShownDocument, options);
      }
export type MarkPreviewShownMutationHookResult = ReturnType<typeof useMarkPreviewShownMutation>;
export type MarkPreviewShownMutationResult = Apollo.MutationResult<MarkPreviewShownMutation>;
export type MarkPreviewShownMutationOptions = Apollo.BaseMutationOptions<MarkPreviewShownMutation, MarkPreviewShownMutationVariables>;
export const GetGraphLastUpdatedDocument = gql`
    query GetGraphLastUpdated($workspaceId: String!, $graphId: String) {
  getGraphs(workspaceId: $workspaceId, graphId: $graphId) {
    updatedAt
  }
}
    `;

/**
 * __useGetGraphLastUpdatedQuery__
 *
 * To run a query within a React component, call `useGetGraphLastUpdatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraphLastUpdatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraphLastUpdatedQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      graphId: // value for 'graphId'
 *   },
 * });
 */
export function useGetGraphLastUpdatedQuery(baseOptions: Apollo.QueryHookOptions<GetGraphLastUpdatedQuery, GetGraphLastUpdatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraphLastUpdatedQuery, GetGraphLastUpdatedQueryVariables>(GetGraphLastUpdatedDocument, options);
      }
export function useGetGraphLastUpdatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraphLastUpdatedQuery, GetGraphLastUpdatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraphLastUpdatedQuery, GetGraphLastUpdatedQueryVariables>(GetGraphLastUpdatedDocument, options);
        }
export type GetGraphLastUpdatedQueryHookResult = ReturnType<typeof useGetGraphLastUpdatedQuery>;
export type GetGraphLastUpdatedLazyQueryHookResult = ReturnType<typeof useGetGraphLastUpdatedLazyQuery>;
export type GetGraphLastUpdatedQueryResult = Apollo.QueryResult<GetGraphLastUpdatedQuery, GetGraphLastUpdatedQueryVariables>;
export const GetChannelSchemaDocument = gql`
    query GetChannelSchema($channelId: String) {
  getChannelSchema(channelId: $channelId)
}
    `;

/**
 * __useGetChannelSchemaQuery__
 *
 * To run a query within a React component, call `useGetChannelSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelSchemaQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useGetChannelSchemaQuery(baseOptions?: Apollo.QueryHookOptions<GetChannelSchemaQuery, GetChannelSchemaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelSchemaQuery, GetChannelSchemaQueryVariables>(GetChannelSchemaDocument, options);
      }
export function useGetChannelSchemaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelSchemaQuery, GetChannelSchemaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelSchemaQuery, GetChannelSchemaQueryVariables>(GetChannelSchemaDocument, options);
        }
export type GetChannelSchemaQueryHookResult = ReturnType<typeof useGetChannelSchemaQuery>;
export type GetChannelSchemaLazyQueryHookResult = ReturnType<typeof useGetChannelSchemaLazyQuery>;
export type GetChannelSchemaQueryResult = Apollo.QueryResult<GetChannelSchemaQuery, GetChannelSchemaQueryVariables>;
export const GetPreviewLogDocument = gql`
    query GetPreviewLog($workspaceId: String!, $previewId: String!) {
  getPreviewLog(workspaceId: $workspaceId, previewId: $previewId) {
    log
    previewId
    status
  }
}
    `;

/**
 * __useGetPreviewLogQuery__
 *
 * To run a query within a React component, call `useGetPreviewLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreviewLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreviewLogQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      previewId: // value for 'previewId'
 *   },
 * });
 */
export function useGetPreviewLogQuery(baseOptions: Apollo.QueryHookOptions<GetPreviewLogQuery, GetPreviewLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPreviewLogQuery, GetPreviewLogQueryVariables>(GetPreviewLogDocument, options);
      }
export function useGetPreviewLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPreviewLogQuery, GetPreviewLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPreviewLogQuery, GetPreviewLogQueryVariables>(GetPreviewLogDocument, options);
        }
export type GetPreviewLogQueryHookResult = ReturnType<typeof useGetPreviewLogQuery>;
export type GetPreviewLogLazyQueryHookResult = ReturnType<typeof useGetPreviewLogLazyQuery>;
export type GetPreviewLogQueryResult = Apollo.QueryResult<GetPreviewLogQuery, GetPreviewLogQueryVariables>;
export const GetJobsDocument = gql`
    query GetJobs($workspaceId: String!, $cursor: String, $limit: Int) {
  getDatasetJobs(workspaceId: $workspaceId, cursor: $cursor, limit: $limit) {
    name
    datasetId
    status
    priority
    graphId
    channelId
    workspaceId
    averageRuntime
    instancesQueued
    instancesRunning
    instancesStarting
    runsSuccess
    runsTimeout
    seed
    runsRunning
    runsFailed
    runsCancelled
    runs
    runsStarting
    runsQueued
    updatedAt
    estimatedEndAt
    createdBy
    createdAt
    channel
  }
}
    `;

/**
 * __useGetJobsQuery__
 *
 * To run a query within a React component, call `useGetJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetJobsQuery(baseOptions: Apollo.QueryHookOptions<GetJobsQuery, GetJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobsQuery, GetJobsQueryVariables>(GetJobsDocument, options);
      }
export function useGetJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobsQuery, GetJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobsQuery, GetJobsQueryVariables>(GetJobsDocument, options);
        }
export type GetJobsQueryHookResult = ReturnType<typeof useGetJobsQuery>;
export type GetJobsLazyQueryHookResult = ReturnType<typeof useGetJobsLazyQuery>;
export type GetJobsQueryResult = Apollo.QueryResult<GetJobsQuery, GetJobsQueryVariables>;
export const GetStagedGraphsDocument = gql`
    query GetStagedGraphs($workspaceId: String!, $staged: Boolean, $cursor: String, $limit: Int) {
  getGraphs(
    workspaceId: $workspaceId
    staged: $staged
    cursor: $cursor
    limit: $limit
  ) {
    channel
    createdAt
    createdBy
    description
    graphId
    name
    thumbnail
    workspaceId
    updatedAt
  }
}
    `;

/**
 * __useGetStagedGraphsQuery__
 *
 * To run a query within a React component, call `useGetStagedGraphsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStagedGraphsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStagedGraphsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      staged: // value for 'staged'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetStagedGraphsQuery(baseOptions: Apollo.QueryHookOptions<GetStagedGraphsQuery, GetStagedGraphsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStagedGraphsQuery, GetStagedGraphsQueryVariables>(GetStagedGraphsDocument, options);
      }
export function useGetStagedGraphsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStagedGraphsQuery, GetStagedGraphsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStagedGraphsQuery, GetStagedGraphsQueryVariables>(GetStagedGraphsDocument, options);
        }
export type GetStagedGraphsQueryHookResult = ReturnType<typeof useGetStagedGraphsQuery>;
export type GetStagedGraphsLazyQueryHookResult = ReturnType<typeof useGetStagedGraphsLazyQuery>;
export type GetStagedGraphsQueryResult = Apollo.QueryResult<GetStagedGraphsQuery, GetStagedGraphsQueryVariables>;
export const CreateDatasetDocument = gql`
    mutation CreateDataset($graphId: String!, $name: String!, $runs: Int, $workspaceId: String!, $seed: Int, $priority: Int, $description: String) {
  createDataset(
    graphId: $graphId
    name: $name
    runs: $runs
    workspaceId: $workspaceId
    seed: $seed
    priority: $priority
    description: $description
  )
}
    `;
export type CreateDatasetMutationFn = Apollo.MutationFunction<CreateDatasetMutation, CreateDatasetMutationVariables>;

/**
 * __useCreateDatasetMutation__
 *
 * To run a mutation, you first call `useCreateDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDatasetMutation, { data, loading, error }] = useCreateDatasetMutation({
 *   variables: {
 *      graphId: // value for 'graphId'
 *      name: // value for 'name'
 *      runs: // value for 'runs'
 *      workspaceId: // value for 'workspaceId'
 *      seed: // value for 'seed'
 *      priority: // value for 'priority'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateDatasetMutation(baseOptions?: Apollo.MutationHookOptions<CreateDatasetMutation, CreateDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDatasetMutation, CreateDatasetMutationVariables>(CreateDatasetDocument, options);
      }
export type CreateDatasetMutationHookResult = ReturnType<typeof useCreateDatasetMutation>;
export type CreateDatasetMutationResult = Apollo.MutationResult<CreateDatasetMutation>;
export type CreateDatasetMutationOptions = Apollo.BaseMutationOptions<CreateDatasetMutation, CreateDatasetMutationVariables>;
export const ClearWorkspaceJobsDocument = gql`
    mutation ClearWorkspaceJobs($workspaceId: String) {
  clearDatasetJobs(workspaceId: $workspaceId)
}
    `;
export type ClearWorkspaceJobsMutationFn = Apollo.MutationFunction<ClearWorkspaceJobsMutation, ClearWorkspaceJobsMutationVariables>;

/**
 * __useClearWorkspaceJobsMutation__
 *
 * To run a mutation, you first call `useClearWorkspaceJobsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearWorkspaceJobsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearWorkspaceJobsMutation, { data, loading, error }] = useClearWorkspaceJobsMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useClearWorkspaceJobsMutation(baseOptions?: Apollo.MutationHookOptions<ClearWorkspaceJobsMutation, ClearWorkspaceJobsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearWorkspaceJobsMutation, ClearWorkspaceJobsMutationVariables>(ClearWorkspaceJobsDocument, options);
      }
export type ClearWorkspaceJobsMutationHookResult = ReturnType<typeof useClearWorkspaceJobsMutation>;
export type ClearWorkspaceJobsMutationResult = Apollo.MutationResult<ClearWorkspaceJobsMutation>;
export type ClearWorkspaceJobsMutationOptions = Apollo.BaseMutationOptions<ClearWorkspaceJobsMutation, ClearWorkspaceJobsMutationVariables>;
export const HideDatasetDocument = gql`
    mutation HideDataset($workspaceId: String!, $datasetId: String!, $showJob: Boolean) {
  editDataset(workspaceId: $workspaceId, datasetId: $datasetId, showJob: $showJob)
}
    `;
export type HideDatasetMutationFn = Apollo.MutationFunction<HideDatasetMutation, HideDatasetMutationVariables>;

/**
 * __useHideDatasetMutation__
 *
 * To run a mutation, you first call `useHideDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideDatasetMutation, { data, loading, error }] = useHideDatasetMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      datasetId: // value for 'datasetId'
 *      showJob: // value for 'showJob'
 *   },
 * });
 */
export function useHideDatasetMutation(baseOptions?: Apollo.MutationHookOptions<HideDatasetMutation, HideDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HideDatasetMutation, HideDatasetMutationVariables>(HideDatasetDocument, options);
      }
export type HideDatasetMutationHookResult = ReturnType<typeof useHideDatasetMutation>;
export type HideDatasetMutationResult = Apollo.MutationResult<HideDatasetMutation>;
export type HideDatasetMutationOptions = Apollo.BaseMutationOptions<HideDatasetMutation, HideDatasetMutationVariables>;
export const GetRecentJobsDocument = gql`
    query GetRecentJobs($workspaceId: String!) {
  getDatasetJobs(workspaceId: $workspaceId, limit: 15) {
    createdAt
    datasetId
    estimatedEndAt
    name
    status
  }
}
    `;

/**
 * __useGetRecentJobsQuery__
 *
 * To run a query within a React component, call `useGetRecentJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentJobsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetRecentJobsQuery(baseOptions: Apollo.QueryHookOptions<GetRecentJobsQuery, GetRecentJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecentJobsQuery, GetRecentJobsQueryVariables>(GetRecentJobsDocument, options);
      }
export function useGetRecentJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentJobsQuery, GetRecentJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecentJobsQuery, GetRecentJobsQueryVariables>(GetRecentJobsDocument, options);
        }
export type GetRecentJobsQueryHookResult = ReturnType<typeof useGetRecentJobsQuery>;
export type GetRecentJobsLazyQueryHookResult = ReturnType<typeof useGetRecentJobsLazyQuery>;
export type GetRecentJobsQueryResult = Apollo.QueryResult<GetRecentJobsQuery, GetRecentJobsQueryVariables>;
export const GetRecentGraphsDocument = gql`
    query getRecentGraphs($workspaceId: String!, $staged: Boolean) {
  getGraphs(workspaceId: $workspaceId, staged: $staged, limit: 15) {
    channel
    channelId
    createdAt
    createdBy
    description
    graphId
    name
    thumbnail
    updatedAt
    workspaceId
  }
}
    `;

/**
 * __useGetRecentGraphsQuery__
 *
 * To run a query within a React component, call `useGetRecentGraphsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentGraphsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentGraphsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      staged: // value for 'staged'
 *   },
 * });
 */
export function useGetRecentGraphsQuery(baseOptions: Apollo.QueryHookOptions<GetRecentGraphsQuery, GetRecentGraphsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecentGraphsQuery, GetRecentGraphsQueryVariables>(GetRecentGraphsDocument, options);
      }
export function useGetRecentGraphsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentGraphsQuery, GetRecentGraphsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecentGraphsQuery, GetRecentGraphsQueryVariables>(GetRecentGraphsDocument, options);
        }
export type GetRecentGraphsQueryHookResult = ReturnType<typeof useGetRecentGraphsQuery>;
export type GetRecentGraphsLazyQueryHookResult = ReturnType<typeof useGetRecentGraphsLazyQuery>;
export type GetRecentGraphsQueryResult = Apollo.QueryResult<GetRecentGraphsQuery, GetRecentGraphsQueryVariables>;
export const GetRecentDatasetsDocument = gql`
    query getRecentDatasets($workspaceId: String!) {
  getDatasets(workspaceId: $workspaceId, limit: 30) {
    createdAt
    datasetId
    name
    runs
  }
}
    `;

/**
 * __useGetRecentDatasetsQuery__
 *
 * To run a query within a React component, call `useGetRecentDatasetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentDatasetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentDatasetsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetRecentDatasetsQuery(baseOptions: Apollo.QueryHookOptions<GetRecentDatasetsQuery, GetRecentDatasetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecentDatasetsQuery, GetRecentDatasetsQueryVariables>(GetRecentDatasetsDocument, options);
      }
export function useGetRecentDatasetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentDatasetsQuery, GetRecentDatasetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecentDatasetsQuery, GetRecentDatasetsQueryVariables>(GetRecentDatasetsDocument, options);
        }
export type GetRecentDatasetsQueryHookResult = ReturnType<typeof useGetRecentDatasetsQuery>;
export type GetRecentDatasetsLazyQueryHookResult = ReturnType<typeof useGetRecentDatasetsLazyQuery>;
export type GetRecentDatasetsQueryResult = Apollo.QueryResult<GetRecentDatasetsQuery, GetRecentDatasetsQueryVariables>;
export const EditGanDatasetDocument = gql`
    mutation EditGanDataset($datasetId: String!, $workspaceId: String!, $description: String, $name: String) {
  editDataset(
    datasetId: $datasetId
    workspaceId: $workspaceId
    description: $description
    name: $name
  )
}
    `;
export type EditGanDatasetMutationFn = Apollo.MutationFunction<EditGanDatasetMutation, EditGanDatasetMutationVariables>;

/**
 * __useEditGanDatasetMutation__
 *
 * To run a mutation, you first call `useEditGanDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGanDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGanDatasetMutation, { data, loading, error }] = useEditGanDatasetMutation({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      workspaceId: // value for 'workspaceId'
 *      description: // value for 'description'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditGanDatasetMutation(baseOptions?: Apollo.MutationHookOptions<EditGanDatasetMutation, EditGanDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditGanDatasetMutation, EditGanDatasetMutationVariables>(EditGanDatasetDocument, options);
      }
export type EditGanDatasetMutationHookResult = ReturnType<typeof useEditGanDatasetMutation>;
export type EditGanDatasetMutationResult = Apollo.MutationResult<EditGanDatasetMutation>;
export type EditGanDatasetMutationOptions = Apollo.BaseMutationOptions<EditGanDatasetMutation, EditGanDatasetMutationVariables>;
export const EditDatasetDocument = gql`
    mutation EditDataset($datasetId: String!, $workspaceId: String!, $description: String, $name: String) {
  editDataset(
    datasetId: $datasetId
    workspaceId: $workspaceId
    description: $description
    name: $name
  )
}
    `;
export type EditDatasetMutationFn = Apollo.MutationFunction<EditDatasetMutation, EditDatasetMutationVariables>;

/**
 * __useEditDatasetMutation__
 *
 * To run a mutation, you first call `useEditDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDatasetMutation, { data, loading, error }] = useEditDatasetMutation({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      workspaceId: // value for 'workspaceId'
 *      description: // value for 'description'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditDatasetMutation(baseOptions?: Apollo.MutationHookOptions<EditDatasetMutation, EditDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDatasetMutation, EditDatasetMutationVariables>(EditDatasetDocument, options);
      }
export type EditDatasetMutationHookResult = ReturnType<typeof useEditDatasetMutation>;
export type EditDatasetMutationResult = Apollo.MutationResult<EditDatasetMutation>;
export type EditDatasetMutationOptions = Apollo.BaseMutationOptions<EditDatasetMutation, EditDatasetMutationVariables>;
export const GetDatasetDetailsAnalyticsDocument = gql`
    query GetDatasetDetailsAnalytics($workspaceId: String!, $datasetId: String) {
  getAnalytics(workspaceId: $workspaceId, datasetId: $datasetId) {
    analyticsId
    datasetId
    status
    type
    workspaceId
  }
}
    `;

/**
 * __useGetDatasetDetailsAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetDatasetDetailsAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasetDetailsAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasetDetailsAnalyticsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetDatasetDetailsAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GetDatasetDetailsAnalyticsQuery, GetDatasetDetailsAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatasetDetailsAnalyticsQuery, GetDatasetDetailsAnalyticsQueryVariables>(GetDatasetDetailsAnalyticsDocument, options);
      }
export function useGetDatasetDetailsAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatasetDetailsAnalyticsQuery, GetDatasetDetailsAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatasetDetailsAnalyticsQuery, GetDatasetDetailsAnalyticsQueryVariables>(GetDatasetDetailsAnalyticsDocument, options);
        }
export type GetDatasetDetailsAnalyticsQueryHookResult = ReturnType<typeof useGetDatasetDetailsAnalyticsQuery>;
export type GetDatasetDetailsAnalyticsLazyQueryHookResult = ReturnType<typeof useGetDatasetDetailsAnalyticsLazyQuery>;
export type GetDatasetDetailsAnalyticsQueryResult = Apollo.QueryResult<GetDatasetDetailsAnalyticsQuery, GetDatasetDetailsAnalyticsQueryVariables>;
export const GetAnnotationsDocument = gql`
    query GetAnnotations($workspaceId: String!, $datasetId: String) {
  getAnnotations(workspaceId: $workspaceId, datasetId: $datasetId) {
    annotationId
    datasetId
    format
    map
    status
    updatedAt
    workspaceId
  }
}
    `;

/**
 * __useGetAnnotationsQuery__
 *
 * To run a query within a React component, call `useGetAnnotationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnotationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnotationsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetAnnotationsQuery(baseOptions: Apollo.QueryHookOptions<GetAnnotationsQuery, GetAnnotationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnnotationsQuery, GetAnnotationsQueryVariables>(GetAnnotationsDocument, options);
      }
export function useGetAnnotationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnnotationsQuery, GetAnnotationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnnotationsQuery, GetAnnotationsQueryVariables>(GetAnnotationsDocument, options);
        }
export type GetAnnotationsQueryHookResult = ReturnType<typeof useGetAnnotationsQuery>;
export type GetAnnotationsLazyQueryHookResult = ReturnType<typeof useGetAnnotationsLazyQuery>;
export type GetAnnotationsQueryResult = Apollo.QueryResult<GetAnnotationsQuery, GetAnnotationsQueryVariables>;
export const GetDatasetDetailsGanDatasetsDocument = gql`
    query GetDatasetDetailsGANDatasets($workspaceId: String!, $datasetId: String) {
  getGANDatasets(workspaceId: $workspaceId, datasetId: $datasetId) {
    datasetId
    description
    model
    name
    status
    updatedAt
    workspaceId
  }
}
    `;

/**
 * __useGetDatasetDetailsGanDatasetsQuery__
 *
 * To run a query within a React component, call `useGetDatasetDetailsGanDatasetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasetDetailsGanDatasetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasetDetailsGanDatasetsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetDatasetDetailsGanDatasetsQuery(baseOptions: Apollo.QueryHookOptions<GetDatasetDetailsGanDatasetsQuery, GetDatasetDetailsGanDatasetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatasetDetailsGanDatasetsQuery, GetDatasetDetailsGanDatasetsQueryVariables>(GetDatasetDetailsGanDatasetsDocument, options);
      }
export function useGetDatasetDetailsGanDatasetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatasetDetailsGanDatasetsQuery, GetDatasetDetailsGanDatasetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatasetDetailsGanDatasetsQuery, GetDatasetDetailsGanDatasetsQueryVariables>(GetDatasetDetailsGanDatasetsDocument, options);
        }
export type GetDatasetDetailsGanDatasetsQueryHookResult = ReturnType<typeof useGetDatasetDetailsGanDatasetsQuery>;
export type GetDatasetDetailsGanDatasetsLazyQueryHookResult = ReturnType<typeof useGetDatasetDetailsGanDatasetsLazyQuery>;
export type GetDatasetDetailsGanDatasetsQueryResult = Apollo.QueryResult<GetDatasetDetailsGanDatasetsQuery, GetDatasetDetailsGanDatasetsQueryVariables>;
export const GetDatasetDetailsUmapsDocument = gql`
    query GetDatasetDetailsUmaps($workspaceId: String!, $datasetId: String) {
  getUMAPs(workspaceId: $workspaceId, datasetId: $datasetId) {
    name
    status
    umapId
  }
}
    `;

/**
 * __useGetDatasetDetailsUmapsQuery__
 *
 * To run a query within a React component, call `useGetDatasetDetailsUmapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasetDetailsUmapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasetDetailsUmapsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetDatasetDetailsUmapsQuery(baseOptions: Apollo.QueryHookOptions<GetDatasetDetailsUmapsQuery, GetDatasetDetailsUmapsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatasetDetailsUmapsQuery, GetDatasetDetailsUmapsQueryVariables>(GetDatasetDetailsUmapsDocument, options);
      }
export function useGetDatasetDetailsUmapsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatasetDetailsUmapsQuery, GetDatasetDetailsUmapsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatasetDetailsUmapsQuery, GetDatasetDetailsUmapsQueryVariables>(GetDatasetDetailsUmapsDocument, options);
        }
export type GetDatasetDetailsUmapsQueryHookResult = ReturnType<typeof useGetDatasetDetailsUmapsQuery>;
export type GetDatasetDetailsUmapsLazyQueryHookResult = ReturnType<typeof useGetDatasetDetailsUmapsLazyQuery>;
export type GetDatasetDetailsUmapsQueryResult = Apollo.QueryResult<GetDatasetDetailsUmapsQuery, GetDatasetDetailsUmapsQueryVariables>;
export const GetDatasetsDocument = gql`
    query GetDatasets($workspaceId: String!, $cursor: String, $limit: Int) {
  getDatasets(workspaceId: $workspaceId, cursor: $cursor, limit: $limit) {
    channel
    createdAt
    createdBy
    datasetId
    description
    files
    graphId
    name
    runs
    seed
    size
    status
  }
}
    `;

/**
 * __useGetDatasetsQuery__
 *
 * To run a query within a React component, call `useGetDatasetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasetsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDatasetsQuery(baseOptions: Apollo.QueryHookOptions<GetDatasetsQuery, GetDatasetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatasetsQuery, GetDatasetsQueryVariables>(GetDatasetsDocument, options);
      }
export function useGetDatasetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatasetsQuery, GetDatasetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatasetsQuery, GetDatasetsQueryVariables>(GetDatasetsDocument, options);
        }
export type GetDatasetsQueryHookResult = ReturnType<typeof useGetDatasetsQuery>;
export type GetDatasetsLazyQueryHookResult = ReturnType<typeof useGetDatasetsLazyQuery>;
export type GetDatasetsQueryResult = Apollo.QueryResult<GetDatasetsQuery, GetDatasetsQueryVariables>;